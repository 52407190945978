import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Breadcrumb from "../../components/Breadcrumb";
import { FaIndianRupeeSign } from "react-icons/fa6";
import { CartContext } from "../../contexts/CartContext";

const CheckOut = () => {
  const { cartItems, getTotalPrice } = useContext(CartContext);
  const navigate = useNavigate();
  const location = useLocation();

  const [clientName, setClientName] = useState("");
  const [clientEmail, setClientEmail] = useState("");
  const [clientPhNo, setClientPhNo] = useState("");
  const [clientAdd, setClientAdd] = useState("");
  const [clientAdd2, setClientAdd2] = useState("");
  const [clientCity, setClientCity] = useState("");
  const [clientState, setClientState] = useState("");
  const [clientPincode, setClientPincode] = useState("");
  const [clientAlterNateContact, setClientAlterNateContact] = useState("");
  const [formErrors, setFormErrors] = useState({});
  const [buyNowDetails, setBuyNowDetails] = useState(null);
  const [orderDetails, setOrderDetails] = useState({
    userEmail: "",
    userName: "",
    orderId: "",
  });
  const [paymentMethod, setPaymentMethod] = useState("Online/Prepaid");
  const [isCod, setIsCod] = useState(0);
  const [isPrepaid, setIsPrepaid] = useState(1);

  // useEffect to handle data from "Buy Now" action or localStorage
  useEffect(() => {
    if (
      location.state &&
      location.state.productDetails &&
      location.state.amount
    ) {
      setBuyNowDetails({
        productDetails: location.state.productDetails,
        amount: location.state.amount,
      });
    }

    // Retrieve cartItems from localStorage if they exist
    const storedCartItems = JSON.parse(localStorage.getItem("cartItems"));
    // if (storedCartItems && storedCartItems.length > 0) {}
  }, [location.state]);

  const handleClientNameChange = (e) => setClientName(e.target.value);
  const handleClientEmailChange = (e) => setClientEmail(e.target.value);
  const handleClientPhNoChange = (e) => setClientPhNo(e.target.value);
  const handleClientAddChange = (e) => setClientAdd(e.target.value);
  const handleClientAdd2Change = (e) => setClientAdd2(e.target.value);
  const handleClientCityChange = (e) => setClientCity(e.target.value);
  const handleClientStateChange = (e) => setClientState(e.target.value);
  const handleClientPincodeChange = (e) => setClientPincode(e.target.value);
  const handleClientAlterNateContact = (e) =>
    setClientAlterNateContact(e.target.value);

  const validateForm = () => {
    let errors = {};
    let isValid = true;

    if (!clientName.trim()) {
      errors.clientName = "Name is required";
      isValid = false;
    }

    if (!clientEmail.trim()) {
      errors.clientEmail = "Email is required";
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(clientEmail)) {
      errors.clientEmail = "Email is invalid";
      isValid = false;
    }

    if (!clientPhNo.trim()) {
      errors.clientPhNo = "Phone number is required";
      isValid = false;
    } else if (!/^\d{10}$/.test(clientPhNo)) {
      errors.clientPhNo = "Phone number must be 10 digits";
      isValid = false;
    }

    if (!clientAdd.trim()) {
      errors.clientAdd = "Address is required";
      isValid = false;
    }

    if (!clientCity.trim()) {
      errors.clientCity = "City is required";
      isValid = false;
    }

    if (!clientState.trim()) {
      errors.clientState = "State is required";
      isValid = false;
    }

    if (!clientPincode.trim()) {
      errors.clientPincode = "Pincode is required";
      isValid = false;
    } else if (!/^\d{6}$/.test(clientPincode)) {
      errors.clientPincode = "Pincode must be 6 digits";
      isValid = false;
    }

    setFormErrors(errors);
    return isValid;
  };

  const handlePaymentMethodChange = (e) => {
    setPaymentMethod(e.target.value);
    setIsCod(e.target.value === "Cash on Delivery" ? 1 : 0);
    setIsPrepaid(e.target.value === "Online/Prepaid" ? 1 : 0);
  };

  const handleRazorpaySuccess = async (response) => {
    try {
      const { razorpay_payment_id, razorpay_order_id, razorpay_signature } =
        response;

      if (!razorpay_payment_id || !razorpay_order_id || !razorpay_signature) {
        throw new Error("Missing payment details");
      }

      // Prepare data to send to your backend
      const requestData = {
        razorpay_payment_id,
        razorpay_order_id,
        razorpay_signature,
      };

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      };

      const backendResponse = await fetch(
        `${process.env.REACT_APP_API_URL}/order/paymentCallBack`,
        requestOptions
      );

      const responseText = await backendResponse.text();

      if (backendResponse.ok) {
        // Split response text to extract payment status and amount
        const [paymentStatus, amount] = responseText.split(",");
        const parsedAmount = parseFloat(amount.trim());

        // Check if the status is "Payment Status Updated" and amount is valid
        if (
          paymentStatus.trim() === "Payment Status Updated" &&
          !isNaN(parsedAmount)
        ) {
          toast.success("Payment successfully!", {
            autoClose: 1000,
            position: "bottom-right",
          });

          // Store order details
          setOrderDetails({
            userEmail: clientEmail,
            userName: clientName,
            orderId: razorpay_order_id, // Assuming order_id is the correct field here
          });

          // Call email confirmation API
          await sendConfirmationEmail(
            clientEmail,
            clientName,
            razorpay_order_id
          );

          await sendOrderNotifyEmail(
            buyNowDetails?.productDetails[0]?.productName || "",
            buyNowDetails ? 1 : cartItems.length,
            parsedAmount.toString(),
            clientName,
            clientEmail,
            clientPhNo,
            clientAdd,
            razorpay_order_id
          );

          // navigate(`/thank-you?amount=${parsedAmount}`);
          navigate(`/`);
        } else {
          toast.error("Payment failed.", {
            autoClose: 1000,
            position: "bottom-right",
          });
        }
      } else {
        console.error("Failed to place order, status:", backendResponse.status);
        toast.error(
          "Failed to place order, status: " + backendResponse.status,
          {
            autoClose: 1000,
            position: "bottom-right",
          }
        );
      }
    } catch (error) {
      console.error("Error while handling payment success:", error);
      toast.error("Error while handling payment success: " + error.message, {
        autoClose: 1000,
        position: "bottom-right",
      });
    }
  };

  const handleRazorpayCancel = async (order_id) => {
    console.log("order cancel :", order_id);
    try {
      console.log("Payment cancelled, notifying backend...");
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          // message: "Payment cancelled by user",
          razorpay_order_id: order_id,
        }),
      };

      const backendResponse = await fetch(
        `${process.env.REACT_APP_API_URL}/order/paymentCancel`,
        requestOptions
      );

      if (!backendResponse.ok) {
        throw new Error("Failed to notify backend about payment cancellation");
      }

      const responseData = await backendResponse.json();
      console.log(
        "Payment cancellation notification sent to backend:",
        responseData
      );
    } catch (error) {
      console.error("Error while handling payment cancellation:", error);
    }
  };

  const sendConfirmationEmail = async (userEmail, userName, orderId) => {
    try {
      const requestData = {
        userEmail,
        userName,
        orderId,
      };

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      };

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/order/confirmOrderEmail`,
        requestOptions
      );

      if (!response.ok) {
        throw new Error("Failed to send confirmation email");
      }

      const responseData = await response.json();
      console.log("Order confirmation email sent:", responseData);
    } catch (error) {
      console.error("Error while sending confirmation email:", error);
    }
  };

  const sendOrderNotifyEmail = async (
    prodName,
    quantity,
    amount,
    clientName,
    clientEmail,
    clientPhNo,
    clientAdd,
    orderId
  ) => {
    try {
      const requestData = {
        prodName,
        quantity,
        amount,
        clientName,
        "mailto:clientemail": clientEmail,
        clientPhNo,
        clientAdd,
        orderId,
      };

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      };

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/order/orderNotifyEmail`,
        requestOptions
      );

      if (!response.ok) {
        throw new Error("Failed to send order notification email");
      }

      const responseData = await response.json();
      console.log("Order notification email sent:", responseData);
    } catch (error) {
      console.error("Error while sending order notification email:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          // clientName,
          // clientEmail,
          // clientPhNo,
          // clientAdd,
          clientName,
          clientEmail,
          clientPhNo1: clientPhNo,
          clientPhNo2: clientAlterNateContact,
          clientAdd1: clientAdd,
          clientAdd2: clientAdd2,
          city: clientCity,
          state: clientState,
          pincode: clientPincode,
          country: "India", // Assuming country is India, adjust as needed
          isCod,
          isPrepaid,
          amount: buyNowDetails
            ? buyNowDetails.amount.toString()
            : getTotalPrice().toString(),
          cartItems: buyNowDetails
            ? buyNowDetails.productDetails.map((item) => ({
                fkImgId: item.selectedColor.pkImgId ?? null,
                customMessage: item.customization.inputValue ?? "",
                zodiacSign: item.customization.selectedZodiacSign ?? "",
                quantity: item.quantity,
              }))
            : cartItems.map((item) => ({
                fkImgId: item.selectedColor.pkImgId ?? null,
                customMessage: item.customization.inputValue ?? "",
                zodiacSign: item.customization.selectedZodiacSign ?? "",
                quantity: item.quantity,
              })),
        }),
      };

      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/order/add`,
          requestOptions
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        // setClientName("");
        // setClientEmail("");
        // setClientPhNo("");
        // setClientAdd("");
        setClientName("");
        setClientEmail("");
        setClientPhNo("");
        setClientAlterNateContact("");
        setClientAdd("");
        setClientAdd2("");
        setClientCity("");
        setClientState("");
        setClientPincode("");

        const data = await response.json();
        const orderData = JSON.parse(data.order);
        const order_id = orderData.id;

        if (paymentMethod === "Online/Prepaid") {
          // Initiate Razorpay payment
          const options = {
            key: "rzp_live_tzzZtL3Wmelx8H",
            amount: orderData.amount,
            currency: "INR",
            name: "Karv Accessories",
            description: "Test Transaction",
            image: "/images/karv-logo.png",
            order_id: order_id,
            handler: handleRazorpaySuccess,
            prefill: {
              name: clientName,
              email: clientEmail,
              contact: clientPhNo,
            },
            notes: {
              address: clientAdd,
            },
            theme: {
              color: "#3395ff",
            },
          };

          const rzp1 = new window.Razorpay(options);
          rzp1.on("payment.failed", () => handleRazorpayCancel(order_id));
          rzp1.open();
        } else {
          toast.success("Order placed successfully!", {
            autoClose: 1000,
            position: "bottom-right",
          });

          // Call email confirmation API only if isCod is 1
          if (isCod === 1) {
            await sendConfirmationEmail(clientEmail, clientName, order_id);

            await sendOrderNotifyEmail(
              buyNowDetails?.productDetails[0]?.productName || "",
              buyNowDetails ? 1 : cartItems.length,
              orderData.amount.toString(),
              clientName,
              clientEmail,
              clientPhNo,
              clientAdd,
              order_id
            );
          }

          // Navigate to thank you or confirmation page
          // navigate(`/thank-you`);
        }

        // const options = {
        //   key: "rzp_live_tzzZtL3Wmelx8H",
        //   amount: orderData.amount,
        //   currency: "INR",
        //   name: "Karv Accessories",
        //   description: "Test Transaction",
        //   image: "/images/karv-logo.png",
        //   order_id: order_id,
        //   handler: handleRazorpaySuccess,
        //   prefill: {
        //     name: clientName,
        //     email: clientEmail,
        //     contact: clientPhNo,
        //   },
        //   notes: {
        //     address: clientAdd,
        //   },
        //   theme: {
        //     color: "#3395ff",
        //   },
        // };

        // // toast.success("Order successfully!")

        // const rzp1 = new window.Razorpay(options);
        // rzp1.on("payment.failed", () => handleRazorpayCancel(order_id));
        // rzp1.open();
      } catch (error) {
        console.error("Error during checkout:", error.message);
        toast.error("Failed to place order", {
          autoClose: 1000,
          position: "bottom-right",
        });
      }
    }
  };

  const getInputClass = (fieldName) => {
    if (formErrors[fieldName]) {
      return "form-control is-invalid";
    } else if (
      (fieldName === "clientPhNo" && clientPhNo.trim().length === 10) ||
      (fieldName === "clientEmail" && /\S+@\S+\.\S+/.test(clientEmail))
    ) {
      return "form-control is-valid";
    } else {
      return "form-control";
    }
  };

  return (
    <>
      <section className="checkout-page">
        {/* <Breadcrumb title="Checkout" /> */}
        <div className="py-5 checkout-page-block">
          <div className="our-container">
            <div className="inner-container mx-auto">
              <div className="checkout-page-heading mb-4">
                <h1 className="fs-2 fw-medium text-capitalize">
                  Your Checkout
                </h1>
              </div>
              <div className="checkout-page-row-block">
                <div className="col-md-8 checkout-page-row-left-block">
                  <div className="row">
                    <div className="checkout-page-body">
                      <form className="row g-3" onSubmit={handleSubmit}>
                        <div className="col-12">
                          <label
                            htmlFor="clientPhNo"
                            className="form-label fw-semibold fs-5"
                          >
                            Contact
                          </label>
                          <input
                            type="number"
                            className={getInputClass("clientPhNo")}
                            id="clientPhNo"
                            placeholder="Your Contact Number"
                            autoComplete="off"
                            value={clientPhNo}
                            onChange={handleClientPhNoChange}
                          />
                          <div className="invalid-feedback">
                            {formErrors.clientPhNo}
                          </div>
                        </div>
                        <div className="col-12">
                          <input
                            type="email"
                            className={getInputClass("clientEmail")}
                            id="clientEmail"
                            placeholder="Your Email"
                            autoComplete="off"
                            value={clientEmail}
                            onChange={handleClientEmailChange}
                          />
                          <div className="invalid-feedback">
                            {formErrors.clientEmail}
                          </div>
                        </div>
                        <div className="col-12">
                          <label
                            htmlFor="clientName"
                            className="form-label fw-semibold fs-5"
                          >
                            Delivery
                          </label>
                          <input
                            type="text"
                            className={getInputClass("clientName")}
                            id="clientName"
                            placeholder="Your Name"
                            autoComplete="off"
                            value={clientName}
                            onChange={handleClientNameChange}
                          />
                          <div className="invalid-feedback">
                            {formErrors.clientName}
                          </div>
                        </div>
                        <div className="col-12">
                          <textarea
                            className={getInputClass("clientAdd")}
                            id="clientAdd"
                            rows="2"
                            placeholder="Address Line 1"
                            autoComplete="off"
                            value={clientAdd}
                            onChange={handleClientAddChange}
                          ></textarea>
                          <div className="invalid-feedback">
                            {formErrors.clientAdd}
                          </div>
                        </div>
                        <div className="col-12">
                          <textarea
                            type="text"
                            className={getInputClass("clientAdd2")}
                            id="clientAdd2"
                            rows="2"
                            placeholder="Address Line 2 (optional)"
                            autoComplete="off"
                            value={clientAdd2}
                            onChange={handleClientAdd2Change}
                          ></textarea>
                        </div>
                        <div className="col-4">
                          <input
                            type="text"
                            className={getInputClass("clientCity")}
                            id="clientCity"
                            placeholder="City"
                            autoComplete="off"
                            value={clientCity}
                            onChange={handleClientCityChange}
                          />
                          <div className="invalid-feedback">
                            {formErrors.clientCity}
                          </div>
                        </div>
                        <div className="col-4">
                          <input
                            type="text"
                            className={getInputClass("clientState")}
                            id="clientState"
                            placeholder="State"
                            autoComplete="off"
                            value={clientState}
                            onChange={handleClientStateChange}
                          />
                          <div className="invalid-feedback">
                            {formErrors.clientState}
                          </div>
                        </div>
                        <div className="col-4">
                          <input
                            type="number"
                            className={getInputClass("clientPincode")}
                            id="clientPincode"
                            placeholder="Pincode"
                            autoComplete="off"
                            value={clientPincode}
                            onChange={handleClientPincodeChange}
                          />
                          <div className="invalid-feedback">
                            {formErrors.clientPincode}
                          </div>
                        </div>
                        <div className="col-12">
                          <input
                            type="number"
                            className={getInputClass("clientAlterNateContact")}
                            id="clientAlterNateContact"
                            placeholder="AlterNate Contact No"
                            autoComplete="off"
                            value={clientAlterNateContact}
                            onChange={handleClientAlterNateContact}
                          />
                        </div>
                        <div className="col-12">
                          <label
                            htmlFor="paymentMethod"
                            className="form-label fw-semibold fs-5"
                          >
                            Payment Method
                          </label>
                          <select
                            id="paymentMethod"
                            value={paymentMethod}
                            onChange={handlePaymentMethodChange}
                            class="form-select"
                          >
                            <option value="Online/Prepaid">
                              Online/Prepaid
                            </option>
                            <option value="Cash on Delivery">
                              Cash on Delivery
                            </option>
                          </select>
                        </div>
                        <div className="p-0 d-flex align-items-center justify-content-center">
                          <button type="submit" className="pay-now-btn mx-2">
                            {paymentMethod === "Cash on Delivery"
                              ? "Submit"
                              : "Pay Now"}
                            {/* Pay Now */}
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 checkout-page-row-right-block">
                  <div className="subtotal-checkout-block rounded-1">
                    <div className="subtotal-block mb-2">
                      <div className="h5 mb-0">Subtotal</div>
                      <div className="h3 mb-0">
                        <FaIndianRupeeSign className="checkout-rupees-sign" />
                        {buyNowDetails ? buyNowDetails.amount : getTotalPrice()}
                      </div>
                    </div>
                    <div className="shipping-block mb-2">
                      <div className="h5 mb-0">Shipping</div>
                      <div className="h3 mb-0">Free</div>
                    </div>
                    <div className="total-amount-block">
                      <div className="h5 mb-0">Total</div>
                      <div className="h3 mb-0">
                        <FaIndianRupeeSign className="checkout-rupees-sign" />
                        {buyNowDetails ? buyNowDetails.amount : getTotalPrice()}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CheckOut;

// import React, { useContext, useEffect, useState } from "react";
// import { useLocation, useNavigate } from "react-router-dom";
// import { toast } from "react-toastify";
// import Breadcrumb from "../../components/Breadcrumb";
// import { FaIndianRupeeSign } from "react-icons/fa6";
// import { CartContext } from "../../contexts/CartContext";

// const CheckOut = () => {
//   const { cartItems, getTotalPrice } = useContext(CartContext);
//   const navigate = useNavigate();
//   const location = useLocation();

//   const [clientName, setClientName] = useState("");
//   const [clientEmail, setClientEmail] = useState("");
//   const [clientPhNo, setClientPhNo] = useState("");
//   const [clientAdd, setClientAdd] = useState("");
//   const [clientAdd2, setClientAdd2] = useState("");
//   const [clientCity, setClientCity] = useState("");
//   const [clientState, setClientState] = useState("");
//   const [clientPincode, setClientPincode] = useState("");
//   const [clientAlterNateContact, setClientAlterNateContact] = useState("");
//   const [formErrors, setFormErrors] = useState({});
//   const [buyNowDetails, setBuyNowDetails] = useState(null);
//   const [orderDetails, setOrderDetails] = useState({
//     userEmail: "",
//     userName: "",
//     orderId: "",
//   });
//   const [paymentMethod, setPaymentMethod] = useState("Online/Prepaid");

//   // useEffect to handle data from "Buy Now" action or localStorage
//   useEffect(() => {
//     if (
//       location.state &&
//       location.state.productDetails &&
//       location.state.amount
//     ) {
//       setBuyNowDetails({
//         productDetails: location.state.productDetails,
//         amount: location.state.amount,
//       });
//     }

//     // Retrieve cartItems from localStorage if they exist
//     const storedCartItems = JSON.parse(localStorage.getItem("cartItems"));
//     // if (storedCartItems && storedCartItems.length > 0) {}
//   }, [location.state]);

//   const handleClientNameChange = (e) => setClientName(e.target.value);
//   const handleClientEmailChange = (e) => setClientEmail(e.target.value);
//   const handleClientPhNoChange = (e) => setClientPhNo(e.target.value);
//   const handleClientAddChange = (e) => setClientAdd(e.target.value);
//   const handleClientAdd2Change = (e) => setClientAdd2(e.target.value);
//   const handleClientCityChange = (e) => setClientCity(e.target.value);
//   const handleClientStateChange = (e) => setClientState(e.target.value);
//   const handleClientPincodeChange = (e) => setClientPincode(e.target.value);
//   const handleClientAlterNateContact = (e) =>
//     setClientAlterNateContact(e.target.value);

//   const validateForm = () => {
//     let errors = {};
//     let isValid = true;

//     if (!clientName.trim()) {
//       errors.clientName = "Name is required";
//       isValid = false;
//     }

//     if (!clientEmail.trim()) {
//       errors.clientEmail = "Email is required";
//       isValid = false;
//     } else if (!/\S+@\S+\.\S+/.test(clientEmail)) {
//       errors.clientEmail = "Email is invalid";
//       isValid = false;
//     }

//     if (!clientPhNo.trim()) {
//       errors.clientPhNo = "Phone number is required";
//       isValid = false;
//     } else if (!/^\d{10}$/.test(clientPhNo)) {
//       errors.clientPhNo = "Phone number must be 10 digits";
//       isValid = false;
//     }

//     if (!clientAdd.trim()) {
//       errors.clientAdd = "Address is required";
//       isValid = false;
//     }

//     if (!clientCity.trim()) {
//       errors.clientCity = "City is required";
//       isValid = false;
//     }

//     if (!clientState.trim()) {
//       errors.clientState = "State is required";
//       isValid = false;
//     }

//     if (!clientPincode.trim()) {
//       errors.clientPincode = "Pincode is required";
//       isValid = false;
//     } else if (!/^\d{6}$/.test(clientPincode)) {
//       errors.clientPincode = "Pincode must be 6 digits";
//       isValid = false;
//     }

//     setFormErrors(errors);
//     return isValid;
//   };

//   const handleRazorpaySuccess = async (response) => {
//     try {
//       const { razorpay_payment_id, razorpay_order_id, razorpay_signature } =
//         response;

//       if (!razorpay_payment_id || !razorpay_order_id || !razorpay_signature) {
//         throw new Error("Missing payment details");
//       }

//       // Prepare data to send to your backend
//       const requestData = {
//         razorpay_payment_id,
//         razorpay_order_id,
//         razorpay_signature,
//       };

//       const requestOptions = {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify(requestData),
//       };

//       const backendResponse = await fetch(
//         `${process.env.REACT_APP_API_URL}/order/paymentCallBack`,
//         requestOptions
//       );

//       const responseText = await backendResponse.text();

//       if (backendResponse.ok) {
//         // Split response text to extract payment status and amount
//         const [paymentStatus, amount] = responseText.split(",");
//         const parsedAmount = parseFloat(amount.trim());

//         // Check if the status is "Payment Status Updated" and amount is valid
//         if (
//           paymentStatus.trim() === "Payment Status Updated" &&
//           !isNaN(parsedAmount)
//         ) {
//           toast.success("Payment successfully!", {
//             autoClose: 1000,
//             position: "bottom-right",
//           });

//           // Store order details
//           setOrderDetails({
//             userEmail: clientEmail,
//             userName: clientName,
//             orderId: razorpay_order_id, // Assuming order_id is the correct field here
//           });

//           // Call email confirmation API
//           await sendConfirmationEmail(
//             clientEmail,
//             clientName,
//             razorpay_order_id
//           );

//           await sendOrderNotifyEmail(
//             buyNowDetails?.productDetails[0]?.productName || "",
//             buyNowDetails ? 1 : cartItems.length,
//             parsedAmount.toString(),
//             clientName,
//             clientEmail,
//             clientPhNo,
//             clientAdd,
//             razorpay_order_id
//           );

//           // navigate(`/thank-you?amount=${parsedAmount}`);
//           navigate(`/`);
//         } else {
//           toast.error("Payment failed.", {
//             autoClose: 1000,
//             position: "bottom-right",
//           });
//         }
//       } else {
//         console.error("Failed to place order, status:", backendResponse.status);
//         toast.error(
//           "Failed to place order, status: " + backendResponse.status,
//           {
//             autoClose: 1000,
//             position: "bottom-right",
//           }
//         );
//       }
//     } catch (error) {
//       console.error("Error while handling payment success:", error);
//       toast.error("Error while handling payment success: " + error.message, {
//         autoClose: 1000,
//         position: "bottom-right",
//       });
//     }
//   };

//   const handleRazorpayCancel = async (order_id) => {
//     console.log("order cancel :", order_id);
//     try {
//       console.log("Payment cancelled, notifying backend...");
//       const requestOptions = {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify({
//           // message: "Payment cancelled by user",
//           razorpay_order_id: order_id,
//         }),
//       };

//       const backendResponse = await fetch(
//         `${process.env.REACT_APP_API_URL}/order/paymentCancel`,
//         requestOptions
//       );

//       if (!backendResponse.ok) {
//         throw new Error("Failed to notify backend about payment cancellation");
//       }

//       const responseData = await backendResponse.json();
//       console.log(
//         "Payment cancellation notification sent to backend:",
//         responseData
//       );
//     } catch (error) {
//       console.error("Error while handling payment cancellation:", error);
//     }
//   };

//   const sendConfirmationEmail = async (userEmail, userName, orderId) => {
//     try {
//       const requestData = {
//         userEmail,
//         userName,
//         orderId,
//       };

//       const requestOptions = {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify(requestData),
//       };

//       const response = await fetch(
//         `${process.env.REACT_APP_API_URL}/order/confirmOrderEmail`,
//         requestOptions
//       );

//       if (!response.ok) {
//         throw new Error("Failed to send confirmation email");
//       }

//       const responseData = await response.json();
//       console.log("Order confirmation email sent:", responseData);
//     } catch (error) {
//       console.error("Error while sending confirmation email:", error);
//     }
//   };

//   const sendOrderNotifyEmail = async (
//     prodName,
//     quantity,
//     amount,
//     clientName,
//     clientEmail,
//     clientPhNo,
//     clientAdd,
//     orderId
//   ) => {
//     try {
//       const requestData = {
//         prodName,
//         quantity,
//         amount,
//         clientName,
//         "mailto:clientemail": clientEmail,
//         clientPhNo,
//         clientAdd,
//         orderId,
//       };

//       const requestOptions = {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify(requestData),
//       };

//       const response = await fetch(
//         `${process.env.REACT_APP_API_URL}/order/orderNotifyEmail`,
//         requestOptions
//       );

//       if (!response.ok) {
//         throw new Error("Failed to send order notification email");
//       }

//       const responseData = await response.json();
//       console.log("Order notification email sent:", responseData);
//     } catch (error) {
//       console.error("Error while sending order notification email:", error);
//     }
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     if (validateForm()) {
//       const requestOptions = {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify({
//           // clientName,
//           // clientEmail,
//           // clientPhNo,
//           // clientAdd,
//           clientName,
//           clientEmail,
//           clientPhNo1: clientPhNo,
//           clientPhNo2: clientAlterNateContact,
//           clientAdd1: clientAdd,
//           clientAdd2: clientAdd2,
//           city: clientCity,
//           state: clientState,
//           pincode: clientPincode,
//           country: "India", // Assuming country is India, adjust as needed
//           amount: buyNowDetails
//             ? buyNowDetails.amount.toString()
//             : getTotalPrice().toString(),
//           cartItems: buyNowDetails
//             ? buyNowDetails.productDetails.map((item) => ({
//                 fkImgId: item.selectedColor.pkImgId ?? null,
//                 customMessage: item.customization.inputValue ?? "",
//                 zodiacSign: item.customization.selectedZodiacSign ?? "",
//                 quantity: item.quantity,
//               }))
//             : cartItems.map((item) => ({
//                 fkImgId: item.selectedColor.pkImgId ?? null,
//                 customMessage: item.customization.inputValue ?? "",
//                 zodiacSign: item.customization.selectedZodiacSign ?? "",
//                 quantity: item.quantity,
//               })),
//         }),
//       };

//       try {
//         const response = await fetch(
//           `${process.env.REACT_APP_API_URL}/order/add`,
//           requestOptions
//         );

//         if (!response.ok) {
//           throw new Error("Network response was not ok");
//         }

//         // setClientName("");
//         // setClientEmail("");
//         // setClientPhNo("");
//         // setClientAdd("");
//         setClientName("");
//         setClientEmail("");
//         setClientPhNo("");
//         setClientAlterNateContact("");
//         setClientAdd("");
//         setClientAdd2("");
//         setClientCity("");
//         setClientState("");
//         setClientPincode("");

//         const data = await response.json();
//         const orderData = JSON.parse(data.order);
//         const order_id = orderData.id;

//         // if (paymentMethod === "Online/Prepaid") {
//         //   // Initiate Razorpay payment
//         //   const options = {
//         //     key: "rzp_live_tzzZtL3Wmelx8H",
//         //     amount: orderData.amount,
//         //     currency: "INR",
//         //     name: "Karv Accessories",
//         //     description: "Test Transaction",
//         //     image: "/images/karv-logo.png",
//         //     order_id: order_id,
//         //     handler: handleRazorpaySuccess,
//         //     prefill: {
//         //       name: clientName,
//         //       email: clientEmail,
//         //       contact: clientPhNo,
//         //     },
//         //     notes: {
//         //       address: clientAdd,
//         //     },
//         //     theme: {
//         //       color: "#3395ff",
//         //     },
//         //   };

//         //   const rzp1 = new window.Razorpay(options);
//         //   rzp1.on("payment.failed", () => handleRazorpayCancel(order_id));
//         //   rzp1.open();
//         // } else {
//         //   toast.success("Order placed successfully!", {
//         //     autoClose: 1000,
//         //     position: "bottom-right",
//         //   });

//         //   // Navigate to thank you or confirmation page
//         //   // navigate(`/thank-you`);
//         // }

//         const options = {
//           key: "rzp_live_tzzZtL3Wmelx8H",
//           amount: orderData.amount,
//           currency: "INR",
//           name: "Karv Accessories",
//           description: "Test Transaction",
//           image: "/images/karv-logo.png",
//           order_id: order_id,
//           handler: handleRazorpaySuccess,
//           prefill: {
//             name: clientName,
//             email: clientEmail,
//             contact: clientPhNo,
//           },
//           notes: {
//             address: clientAdd,
//           },
//           theme: {
//             color: "#3395ff",
//           },
//         };

//         // toast.success("Order successfully!")

//         const rzp1 = new window.Razorpay(options);
//         rzp1.on("payment.failed", () => handleRazorpayCancel(order_id));
//         rzp1.open();
//       } catch (error) {
//         console.error("Error during checkout:", error.message);
//         toast.error("Failed to place order", {
//           autoClose: 1000,
//           position: "bottom-right",
//         });
//       }
//     }
//   };

//   const getInputClass = (fieldName) => {
//     if (formErrors[fieldName]) {
//       return "form-control is-invalid";
//     } else if (
//       (fieldName === "clientPhNo" && clientPhNo.trim().length === 10) ||
//       (fieldName === "clientEmail" && /\S+@\S+\.\S+/.test(clientEmail))
//     ) {
//       return "form-control is-valid";
//     } else {
//       return "form-control";
//     }
//   };

//   return (
//     <>
//       <section className="checkout-page">
//         {/* <Breadcrumb title="Checkout" /> */}
//         <div className="py-5 checkout-page-block">
//           <div className="our-container">
//             <div className="inner-container mx-auto">
//               <div className="checkout-page-heading mb-4">
//                 <h1 className="fs-2 fw-medium text-capitalize">
//                   Your Checkout
//                 </h1>
//               </div>
//               <div className="checkout-page-row-block">
//                 <div className="col-md-8 checkout-page-row-left-block">
//                   <div className="row">
//                     <div className="checkout-page-body">
//                       <form className="row g-3" onSubmit={handleSubmit}>
//                         <div className="col-12">
//                           <label
//                             htmlFor="clientPhNo"
//                             className="form-label fw-semibold fs-5"
//                           >
//                             Contact
//                           </label>
//                           <input
//                             type="number"
//                             className={getInputClass("clientPhNo")}
//                             id="clientPhNo"
//                             placeholder="Your Contact Number"
//                             autoComplete="off"
//                             value={clientPhNo}
//                             onChange={handleClientPhNoChange}
//                           />
//                           <div className="invalid-feedback">
//                             {formErrors.clientPhNo}
//                           </div>
//                         </div>
//                         <div className="col-12">
//                           <input
//                             type="email"
//                             className={getInputClass("clientEmail")}
//                             id="clientEmail"
//                             placeholder="Your Email"
//                             autoComplete="off"
//                             value={clientEmail}
//                             onChange={handleClientEmailChange}
//                           />
//                           <div className="invalid-feedback">
//                             {formErrors.clientEmail}
//                           </div>
//                         </div>
//                         <div className="col-12">
//                           <label
//                             htmlFor="clientName"
//                             className="form-label fw-semibold fs-5"
//                           >
//                             Delivery
//                           </label>
//                           <input
//                             type="text"
//                             className={getInputClass("clientName")}
//                             id="clientName"
//                             placeholder="Your Name"
//                             autoComplete="off"
//                             value={clientName}
//                             onChange={handleClientNameChange}
//                           />
//                           <div className="invalid-feedback">
//                             {formErrors.clientName}
//                           </div>
//                         </div>
//                         <div className="col-12">
//                           <textarea
//                             className={getInputClass("clientAdd")}
//                             id="clientAdd"
//                             rows="2"
//                             placeholder="Address Line 1"
//                             autoComplete="off"
//                             value={clientAdd}
//                             onChange={handleClientAddChange}
//                           ></textarea>
//                           <div className="invalid-feedback">
//                             {formErrors.clientAdd}
//                           </div>
//                         </div>
//                         <div className="col-12">
//                           <textarea
//                             type="text"
//                             className={getInputClass("clientAdd2")}
//                             id="clientAdd2"
//                             rows="2"
//                             placeholder="Address Line 2 (optional)"
//                             autoComplete="off"
//                             value={clientAdd2}
//                             onChange={handleClientAdd2Change}
//                           ></textarea>
//                         </div>
//                         <div className="col-4">
//                           <input
//                             type="text"
//                             className={getInputClass("clientCity")}
//                             id="clientCity"
//                             placeholder="City"
//                             autoComplete="off"
//                             value={clientCity}
//                             onChange={handleClientCityChange}
//                           />
//                           <div className="invalid-feedback">
//                             {formErrors.clientCity}
//                           </div>
//                         </div>
//                         <div className="col-4">
//                           <input
//                             type="text"
//                             className={getInputClass("clientState")}
//                             id="clientState"
//                             placeholder="State"
//                             autoComplete="off"
//                             value={clientState}
//                             onChange={handleClientStateChange}
//                           />
//                           <div className="invalid-feedback">
//                             {formErrors.clientState}
//                           </div>
//                         </div>
//                         <div className="col-4">
//                           <input
//                             type="number"
//                             className={getInputClass("clientPincode")}
//                             id="clientPincode"
//                             placeholder="Pincode"
//                             autoComplete="off"
//                             value={clientPincode}
//                             onChange={handleClientPincodeChange}
//                           />
//                           <div className="invalid-feedback">
//                             {formErrors.clientPincode}
//                           </div>
//                         </div>
//                         <div className="col-12">
//                           <input
//                             type="number"
//                             className={getInputClass("clientAlterNateContact")}
//                             id="clientAlterNateContact"
//                             placeholder="AlterNate Contact No"
//                             autoComplete="off"
//                             value={clientAlterNateContact}
//                             onChange={handleClientAlterNateContact}
//                           />
//                         </div>
//                         {/* <div className="col-12">
//                           <label
//                             htmlFor="clientPaymentMathod"
//                             className="form-label fw-semibold fs-5"
//                           >
//                             Payment Method
//                           </label>
//                           <div class="form-check">
//                             <input
//                               class="form-check-input"
//                               type="radio"
//                               name="flexRadioDefault"
//                               id="flexRadioDefault2"
//                               value="Online/Prepaid"
//                               checked={paymentMethod === "Online/Prepaid"}
//                               onChange={(e) => setPaymentMethod(e.target.value)}
//                             />
//                             <label
//                               class="form-check-label"
//                               for="flexRadioDefault2"
//                             >
//                               Online/Prepaid
//                             </label>
//                           </div>
//                           <div class="form-check">
//                             <input
//                               class="form-check-input"
//                               type="radio"
//                               name="flexRadioDefault"
//                               id="flexRadioDefault1"
//                               value="COD"
//                               checked={paymentMethod === "COD"}
//                               onChange={(e) => setPaymentMethod(e.target.value)}
//                             />
//                             <label
//                               class="form-check-label"
//                               for="flexRadioDefault1"
//                             >
//                               COD (Cash on Delivery)
//                             </label>
//                           </div>
//                         </div> */}
//                         <div className="p-0 d-flex align-items-center justify-content-center">
//                           <button type="submit" className="pay-now-btn mx-2">
//                             {/* {paymentMethod === "COD" ? "Submit" : "Pay Now"} */}
//                             Pay Now
//                           </button>
//                         </div>
//                       </form>
//                     </div>
//                   </div>
//                 </div>
//                 <div className="col-md-3 checkout-page-row-right-block">
//                   <div className="subtotal-checkout-block rounded-1">
//                     <div className="subtotal-block mb-2">
//                       <div className="h5 mb-0">Subtotal</div>
//                       <div className="h3 mb-0">
//                         <FaIndianRupeeSign className="checkout-rupees-sign" />
//                         {buyNowDetails ? buyNowDetails.amount : getTotalPrice()}
//                       </div>
//                     </div>
//                     <div className="shipping-block mb-2">
//                       <div className="h5 mb-0">Shipping</div>
//                       <div className="h3 mb-0">Free</div>
//                     </div>
//                     <div className="total-amount-block">
//                       <div className="h5 mb-0">Total</div>
//                       <div className="h3 mb-0">
//                         <FaIndianRupeeSign className="checkout-rupees-sign" />
//                         {buyNowDetails ? buyNowDetails.amount : getTotalPrice()}
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>
//     </>
//   );
// };

// export default CheckOut;
