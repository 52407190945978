import React, { useContext, useState, useRef, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import {
  FaFacebookF,
  FaInstagram,
  FaShoppingCart,
  FaChevronDown,
  FaChevronUp,
  FaEdit,
} from "react-icons/fa";
import { FaXTwitter, FaYoutube } from "react-icons/fa6";
import { GiHamburgerMenu } from "react-icons/gi";
import { RxCross2 } from "react-icons/rx";
import { CartContext } from "../../contexts/CartContext";
import { ProductContext } from "../../contexts/ProductContext";

const Header = () => {
  const { cartItems } = useContext(CartContext);
  const { productType, setSelectedType } = useContext(ProductContext);

  const [sidebarToggle, setSidebarToggle] = useState(false);
  const [isFixed, setIsFixed] = useState(false);
  const [activeMenu, setActiveMenu] = useState(null);
  const [dropdownActive, setDropdownActive] = useState(false);

  const sidebarRef = useRef(null);

  const totalCartItems = cartItems.length;

  const handleTypeClick = (type) => {
    setSelectedType(type);
  };

  const handleSidebarToggle = () => {
    setSidebarToggle(!sidebarToggle);
  };

  const handleClickOutside = (event) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      setSidebarToggle(false);
    }
  };

  const handleMouseEnter = (menu) => {
    if (window.innerWidth > 768) {
      setActiveMenu(menu);
      setDropdownActive(true);
    }
  };

  const handleMouseLeave = () => {
    if (window.innerWidth > 768) {
      setActiveMenu(null);
      setDropdownActive(false);
    }
  };

  const handleDropdownClick = () => {
    if (window.innerWidth <= 768) {
      setDropdownActive(!dropdownActive);
    }
  };

  const handleDropdownItemClick = (type) => {
    handleTypeClick(type);
    setDropdownActive(false);
    setSidebarToggle(false);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setIsFixed(true);
      } else {
        setIsFixed(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      {/* <header className={isFixed ? "fixed-header" : ""}> */}
      <header>
        <div className="py-1 header-top"></div>
        <div className="upper-menu-block">
          <div className="our-container">
            <div className="inner-container d-flex align-items-center justify-content-between mx-auto px-5">
              {/* <div className="social-icons">
                <FaFacebookF className="social-items" />
                <FaXTwitter className="social-items" />
                <FaInstagram className="social-items" />
                <FaYoutube className="social-items" />
              </div> */}
              <div className="logo-block my-2">
                <Link to={"/"} className="d-block">
                  <img src="/images/karv-logo.png" alt="" />
                </Link>
              </div>
              <div className="mobile-icon-responsive">
                <GiHamburgerMenu
                  className="hamburgerMenu"
                  onClick={handleSidebarToggle}
                />
                <div className="">
                  <NavLink
                    to={"/cart"}
                    className="position-relative mobile-cart-block"
                    onClick={() => setSidebarToggle(false)}
                  >
                    <FaShoppingCart className="mobile-shop-cart" />
                    <span
                      className={`${totalCartItems > 0 && "c-value-mobile"}`}
                    >
                      {totalCartItems > 0 && `${totalCartItems}`}
                    </span>
                  </NavLink>
                </div>
              </div>
              <div
                className={`main-menu ${sidebarToggle ? "sidebar-active" : ""}`}
                ref={sidebarRef}
              >
                <div className="sidebar-top-navbar">
                  <div className="logo-block my-2">
                    <Link to={"/"} className="d-block">
                      <img src="/images/karv-logo.png" alt="" />
                    </Link>
                  </div>
                  <RxCross2
                    className="sidebar-close-cross"
                    onClick={() => setSidebarToggle(false)}
                  />
                </div>
                <nav>
                  <ul className="p-0 d-flex align-items-center justify-content-center mb-0 nav-links">
                    {/* <li>
                      <NavLink
                        to={"/personalized"}
                        className="fs-6 fw-semibold nav-items personalized"
                        onClick={() => setSidebarToggle(false)}
                      >
                        <div className="d-flex align-items-center gap-2 p-1 animated-text"><span>Personalized</span> <FaEdit /></div>
                      </NavLink>
                    </li> */}
                    <li>
                      <NavLink
                        to={"/"}
                        className="fs-6 fw-semibold nav-items"
                        onClick={() => setSidebarToggle(false)}
                      >
                        Home
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to={"/about-us"}
                        className="fs-6 fw-semibold nav-items"
                        onClick={() => setSidebarToggle(false)}
                      >
                        About Us
                      </NavLink>
                    </li>
                    {/* <li>
                      <NavLink
                        to={"/product"}
                        className="fs-6 fw-semibold nav-items"
                        onClick={() => setSidebarToggle(false)}
                      >
                        Products
                      </NavLink>
                    </li> */}
                    <li
                      onMouseEnter={() => handleMouseEnter("product")}
                      onMouseLeave={handleMouseLeave}
                      onClick={handleDropdownClick}
                      className="nav-items-dropdown"
                    >
                      <span className="fs-6 fw-semibold nav-items">
                        Products
                        {dropdownActive ? (
                          <FaChevronUp className="ml-2 ms-1" />
                        ) : (
                          <FaChevronDown className="ml-2 ms-1" />
                        )}
                      </span>
                      {dropdownActive && (
                        <ul className="header-dropdown-menu px-3">
                          {productType.map((type, index) => (
                            <li className="mb-1" key={index}>
                              <NavLink
                                to={"/product"}
                                onClick={() =>
                                  handleDropdownItemClick(type)
                                }
                                className="fs-6 fw-semibold nav-items"
                              >
                                {type}
                              </NavLink>
                            </li>
                          ))}
                        </ul>
                      )}
                    </li>
                    <li>
                      <NavLink
                        to={"/b2b"}
                        className="fs-6 fw-semibold nav-items"
                        onClick={() => setSidebarToggle(false)}
                      >
                        B2B
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to={"/contact-us"}
                        className="fs-6 fw-semibold nav-items"
                        onClick={() => setSidebarToggle(false)}
                      >
                        Contact Us
                      </NavLink>
                    </li>
                    <li className="mobile-pc-cart">
                      <NavLink
                        to={"/cart"}
                        className="fs-6 fw-semibold nav-items"
                        onClick={() => setSidebarToggle(false)}
                      >
                        Shopping {totalCartItems > 0 && `(${totalCartItems})`}
                      </NavLink>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;












// import React, { useContext, useState, useRef, useEffect } from "react";
// import { Link, NavLink, useNavigate } from "react-router-dom";
// import { FaShoppingCart, FaChevronDown, FaChevronUp } from "react-icons/fa";
// import { GiHamburgerMenu } from "react-icons/gi";
// import { RxCross2 } from "react-icons/rx";
// import { CartContext } from "../../contexts/CartContext";
// import { ProductContext } from "../../contexts/ProductContext";

// const Header = () => {
//   const { cartItems } = useContext(CartContext);
//   const { productType, setSelectedType } = useContext(ProductContext);

//   const [sidebarToggle, setSidebarToggle] = useState(false);
//   const [isFixed, setIsFixed] = useState(false);
//   const [activeMenu, setActiveMenu] = useState(null);
//   const [dropdownActive, setDropdownActive] = useState(false);

//   const sidebarRef = useRef(null);
//   const navigate = useNavigate();

//   const totalCartItems = cartItems.length;

//   const handleTypeClick = (type) => {
//     setSelectedType(type);
//     navigate(`/product?type=${type}`);
//   };

//   const handleSidebarToggle = () => {
//     setSidebarToggle(!sidebarToggle);
//   };

//   const handleClickOutside = (event) => {
//     if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
//       setSidebarToggle(false);
//     }
//   };

//   const handleMouseEnter = (menu) => {
//     if (window.innerWidth > 768) {
//       setActiveMenu(menu);
//       setDropdownActive(true);
//     }
//   };

//   const handleMouseLeave = () => {
//     if (window.innerWidth > 768) {
//       setActiveMenu(null);
//       setDropdownActive(false);
//     }
//   };

//   const handleDropdownClick = () => {
//     if (window.innerWidth <= 768) {
//       setDropdownActive(!dropdownActive);
//     }
//   };

//   const handleDropdownItemClick = (type) => {
//     handleTypeClick(type);
//     setDropdownActive(false);
//     setSidebarToggle(false);
//   };

//   useEffect(() => {
//     document.addEventListener("mousedown", handleClickOutside);
//     return () => {
//       document.removeEventListener("mousedown", handleClickOutside);
//     };
//   }, []);

//   useEffect(() => {
//     const handleScroll = () => {
//       if (window.scrollY > 100) {
//         setIsFixed(true);
//       } else {
//         setIsFixed(false);
//       }
//     };

//     window.addEventListener("scroll", handleScroll);
//     return () => {
//       window.removeEventListener("scroll", handleScroll);
//     };
//   }, []);

//   return (
//     <header className={isFixed ? "fixed-header" : ""}>
//       <div className="py-1 header-top"></div>
//       <div className="upper-menu-block">
//         <div className="our-container">
//           <div className="inner-container d-flex align-items-center justify-content-between mx-auto px-5">
//             <div className="logo-block my-2">
//               <Link to={"/"} className="d-block">
//                 <img src="/images/karv-logo.png" alt="" />
//               </Link>
//             </div>
//             <div className="mobile-icon-responsive">
//               <GiHamburgerMenu
//                 className="hamburgerMenu"
//                 onClick={handleSidebarToggle}
//               />
//               <div className="">
//                 <NavLink
//                   to={"/cart"}
//                   className="position-relative mobile-cart-block"
//                   onClick={() => setSidebarToggle(false)}
//                 >
//                   <FaShoppingCart className="mobile-shop-cart" />
//                   <span
//                     className={`${totalCartItems > 0 && "c-value-mobile"}`}
//                   >
//                     {totalCartItems > 0 && `${totalCartItems}`}
//                   </span>
//                 </NavLink>
//               </div>
//             </div>
//             <div
//               className={`main-menu ${sidebarToggle ? "sidebar-active" : ""}`}
//               ref={sidebarRef}
//             >
//               <div className="sidebar-top-navbar">
//                 <div className="logo-block my-2">
//                   <Link to={"/"} className="d-block">
//                     <img src="/images/karv-logo.png" alt="" />
//                   </Link>
//                 </div>
//                 <RxCross2
//                   className="sidebar-close-cross"
//                   onClick={() => setSidebarToggle(false)}
//                 />
//               </div>
//               <nav>
//                 <ul className="p-0 d-flex align-items-center justify-content-center mb-0 nav-links">
//                   <li>
//                     <NavLink
//                       to={"/"}
//                       className="fs-6 fw-semibold nav-items"
//                       onClick={() => setSidebarToggle(false)}
//                     >
//                       Home
//                     </NavLink>
//                   </li>
//                   <li>
//                     <NavLink
//                       to={"/about-us"}
//                       className="fs-6 fw-semibold nav-items"
//                       onClick={() => setSidebarToggle(false)}
//                     >
//                       About Us
//                     </NavLink>
//                   </li>
//                   <li
//                     onMouseEnter={() => handleMouseEnter("product")}
//                     onMouseLeave={handleMouseLeave}
//                     onClick={handleDropdownClick}
//                     className="nav-items-dropdown"
//                   >
//                     <span className="fs-6 fw-semibold nav-items">
//                       Products
//                       {dropdownActive ? (
//                         <FaChevronUp className="ml-2 ms-1" />
//                       ) : (
//                         <FaChevronDown className="ml-2 ms-1" />
//                       )}
//                     </span>
//                     {dropdownActive && (
//                       <ul className="header-dropdown-menu px-3">
//                         {productType.map((type, index) => (
//                           <li className="mb-1" key={index}>
//                             <NavLink
//                               to={"/product"}
//                               onClick={() => handleDropdownItemClick(type)}
//                               className="fs-6 fw-semibold nav-items"
//                             >
//                               {type}
//                             </NavLink>
//                           </li>
//                         ))}
//                       </ul>
//                     )}
//                   </li>
//                   <li>
//                     <NavLink
//                       to={"/b2b"}
//                       className="fs-6 fw-semibold nav-items"
//                       onClick={() => setSidebarToggle(false)}
//                     >
//                       B2B
//                     </NavLink>
//                   </li>
//                   <li>
//                     <NavLink
//                       to={"/contact-us"}
//                       className="fs-6 fw-semibold nav-items"
//                       onClick={() => setSidebarToggle(false)}
//                     >
//                       Contact Us
//                     </NavLink>
//                   </li>
//                   <li className="mobile-pc-cart">
//                     <NavLink
//                       to={"/cart"}
//                       className="fs-6 fw-semibold nav-items"
//                       onClick={() => setSidebarToggle(false)}
//                     >
//                       Shopping {totalCartItems > 0 && `(${totalCartItems})`}
//                     </NavLink>
//                   </li>
//                 </ul>
//               </nav>
//             </div>
//           </div>
//         </div>
//       </div>
//     </header>
//   );
// };

// export default Header;
