import React from "react";
import { Link } from "react-router-dom";
import { FaIndianRupeeSign } from "react-icons/fa6";

const Product = ({ product }) => {
  const firstVariant = product?.info[0];

  return (
    <>
      <Link
        to={`/product/${product?.pkProdId}`}
        className="text-decoration-none product-link d-flex justify-content-between flex-column p-2 product-row-block"
      >
        <img src={firstVariant?.imagePath[0]} alt={firstVariant?.productName} />
        <div className="mt-4 product-raw-block-details">
          <div className="h5 product-color">{product?.prodName}</div>
          <div className="d-flex align-items-center mb-3 gap-2 product-raw-block-details-price">
            <FaIndianRupeeSign className="fs-6 fw-medium mb-0" />
            <div className="h3 fs-6 fw-medium mb-0">{product?.prodPrice}</div>
          </div>
        </div>
      </Link>
    </>
  );
};

export default Product;
