// import React, { createContext, useEffect, useState } from "react";

// export const ProductContext = createContext();

// const ProductProvider = ({ children }) => {
//   const [allProducts, setAllProducts] = useState([]);
//   const [currentPage, setCurrentPage] = useState(
//     Number(localStorage.getItem("currentPage")) || 1
//   );
//   const [postsPerPage, setPostsPerPage] = useState(12);
//   const [totalPages, setTotalPages] = useState(0);
//   const [loading, setLoading] = useState(true);
//   const [selectedFilters, setSelectedFilters] = useState(
//     JSON.parse(localStorage.getItem("selectedFilters")) || []
//   );

//   // Fetch products based on page and filters
//   const fetchProducts = async (pageNo, pageSize, filters) => {
//     try {
//       const response = await fetch(
//         `${process.env.REACT_APP_API_URL}/product/search`,
//         {
//           method: "POST",
//           headers: {
//             "Content-Type": "application/json",
//           },
//           body: JSON.stringify({
//             pkProdId: "",
//             prodName: "",
//             prodCategory: filters.join(",") || "",
//             prodType: "", // No product type is passed now
//             price: "",
//             pageNo: pageNo,
//             pageSize: pageSize,
//           }),
//         }
//       );
//       if (!response.ok) {
//         throw new Error("Failed to fetch products");
//       }
//       const data = await response.json();
//       const totalResults = data.totalResult;
//       const totalPages = Math.ceil(totalResults / pageSize);

//       const productsWithSizes = data?.masterData?.map((product) => ({
//         ...product,
//         info: product?.info?.map((item) => ({
//           ...item,
//         })),
//       }));
//       setTotalPages(totalPages);

//       return productsWithSizes;
//     } catch (error) {
//       console.error("Error fetching products:", error);
//       return [];
//     }
//   };

//   // Fetch all products when page or filters change
//   useEffect(() => {
//     const fetchAllProducts = async () => {
//       setLoading(true);
//       try {
//         const products = await fetchProducts(currentPage, postsPerPage, selectedFilters);

//         if (products.length === 0 && currentPage > 1) {
//           setCurrentPage(1); // Reset to the first page if no products found
//         } else {
//           setAllProducts(products);
//         }
//       } catch (error) {
//         console.error("Error fetching products:", error);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchAllProducts();
//   }, [currentPage, postsPerPage, selectedFilters]);

//   // Persist state changes to localStorage
//   useEffect(() => {
//     localStorage.setItem("currentPage", currentPage);
//   }, [currentPage]);

//   useEffect(() => {
//     localStorage.setItem("selectedFilters", JSON.stringify(selectedFilters));
//   }, [selectedFilters]);

//   const handleFilterChange = (filters) => {
//     setSelectedFilters(filters);
//   };

//   const getProductDetails = (productId) => {
//     return allProducts?.find((product) => product?.pkProdId === productId);
//   };

//   const getSuggestedProducts = (productId) => {
//     const product = getProductDetails(productId);
//     return product ? product.suggestedProducts || [] : [];
//   };

//   return (
//     <ProductContext.Provider
//       value={{
//         allProducts,
//         getProductDetails,
//         getSuggestedProducts,
//         currentPage,
//         setCurrentPage,
//         postsPerPage,
//         totalPages,
//         loading,
//         setLoading,
//         setPostsPerPage,
//         selectedFilters,
//         setSelectedFilters: handleFilterChange,
//       }}
//     >
//       {children}
//     </ProductContext.Provider>
//   );
// };

// export default ProductProvider;











import React, { createContext, useEffect, useState } from "react";

export const ProductContext = createContext();

const ProductProvider = ({ children }) => {
  const [productType, setProductType] = useState([]);
  const [allProducts, setAllProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(
    Number(localStorage.getItem("currentPage")) || 1
  );
  const [postsPerPage, setPostsPerPage] = useState(12);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(true);
  const [selectedType, setSelectedType] = useState(
    localStorage.getItem("selectedType") || "personalized"
  );
  const [selectedFilters, setSelectedFilters] = useState(
    JSON.parse(localStorage.getItem("selectedFilters")) || []
  );

  // Fetch product types on initial load
  useEffect(() => {
    const fetchProductTypes = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/home/productTypeList`
        );
        const data = await response.json();
        if (data.status === "Successful") {
          const types = data.option
            .map((type) => type.split(",").map((subType) => subType.trim()))
            .flat();
          setProductType(types);
        }
      } catch (error) {
        console.error("Error fetching product types:", error);
      }
    };

    fetchProductTypes();
  }, []);

  // Fetch products based on page, type, and filters
  const fetchProducts = async (pageNo, pageSize, prodType, filters) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/product/search`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            pkProdId: "",
            prodName: "",
            prodCategory: filters.join(",") || "",
            prodType: prodType,
            price: "",
            pageNo: pageNo,
            pageSize: pageSize,
          }),
        }
      );
      if (!response.ok) {
        throw new Error("Failed to fetch products");
      }
      const data = await response.json();
      const totalResults = data.totalResult;
      const totalPages = Math.ceil(totalResults / pageSize);

      const productsWithSizes = data?.masterData?.map((product) => ({
        ...product,
        info: product?.info?.map((item) => ({
          ...item,
        })),
      }));
      setTotalPages(totalPages);

      return productsWithSizes;
    } catch (error) {
      console.error("Error fetching products:", error);
      return [];
    }
  };

  // Fetch all products when page, type, or filters change
  useEffect(() => {
    const fetchAllProducts = async () => {
      setLoading(true);
      try {
        const products = await fetchProducts(
          currentPage,
          postsPerPage,
          selectedType,
          selectedFilters
        );

        if (products.length === 0 && currentPage > 1) {
          setCurrentPage(1); // Reset to the first page if no products found
        } else {
          setAllProducts(products);
        }
      } catch (error) {
        console.error("Error fetching products:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchAllProducts();
  }, [currentPage, postsPerPage, selectedType, selectedFilters]);

  // Persist state changes to localStorage
  useEffect(() => {
    localStorage.setItem("currentPage", currentPage);
  }, [currentPage]);

  useEffect(() => {
    localStorage.setItem("selectedType", selectedType);
  }, [selectedType]);

  useEffect(() => {
    localStorage.setItem("selectedFilters", JSON.stringify(selectedFilters));
  }, [selectedFilters]);

  const handleTypeChange = (type) => {
    setSelectedType(type);
  };

  const handleFilterChange = (filters) => {
    setSelectedFilters(filters);
  };

  const getProductDetails = (productId) => {
    return allProducts?.find((product) => product?.pkProdId === productId);
  };

  const getSuggestedProducts = (productId) => {
    const product = getProductDetails(productId);
    return product ? product.suggestedProducts || [] : [];
  };

  return (
    <ProductContext.Provider
      value={{
        productType,
        allProducts,
        getProductDetails,
        getSuggestedProducts,
        currentPage,
        setCurrentPage,
        postsPerPage,
        totalPages,
        loading,
        setLoading,
        setPostsPerPage,
        selectedType,
        setSelectedType: handleTypeChange,
        selectedFilters,
        setSelectedFilters: handleFilterChange,
      }}
    >
      {children}
    </ProductContext.Provider>
  );
};

export default ProductProvider;

// // import React, { createContext, useEffect, useState } from "react";

// // export const ProductContext = createContext();

// // const ProductProvider = ({ children }) => {
// //   const [productType, setProductType] = useState([]);
// //   const [allProducts, setAllProducts] = useState([]);
// //   const [currentPage, setCurrentPage] = useState(1);
// //   const [postsPerPage, setPostsPerPage] = useState(12);
// //   const [totalPages, setTotalPages] = useState(0);
// //   const [loading, setLoading] = useState(true);
// //   const [selectedType, setSelectedType] = useState(
// //     localStorage.getItem("selectedType") || "personalized"
// //   );
// //   const [selectedFilters, setSelectedFilters] = useState([]);

// //   useEffect(() => {
// //     const fetchProductTypes = async () => {
// //       try {
// //         const response = await fetch(
// //           `${process.env.REACT_APP_API_URL}/home/productTypeList`
// //         );
// //         const data = await response.json();
// //         if (data.status === "Successful") {
// //           const types = data.option
// //             .map((type) =>
// //               type.split(",").map((subType) => subType.trim())
// //             )
// //             .flat();
// //           setProductType(types);
// //         }
// //       } catch (error) {
// //         console.error("Error fetching product types:", error);
// //       }
// //     };

// //     fetchProductTypes();
// //   }, []);

// //   const fetchProducts = async (pageNo, pageSize, prodType, filters) => {
// //     try {
// //       const response = await fetch(
// //         `${process.env.REACT_APP_API_URL}/product/search`,
// //         {
// //           method: "POST",
// //           headers: {
// //             "Content-Type": "application/json",
// //           },
// //           body: JSON.stringify({
// //             pkProdId: "",
// //             prodName: "",
// //             prodCategory: filters.join(",") || "",
// //             prodType: prodType,
// //             price: "",
// //             pageNo: pageNo,
// //             pageSize: pageSize,
// //           }),
// //         }
// //       );
// //       if (!response.ok) {
// //         throw new Error("Failed to fetch products");
// //       }
// //       const data = await response.json();
// //       const totalResults = data.totalResult;
// //       const totalPages = Math.ceil(totalResults / pageSize);

// //       const productsWithSizes = data?.masterData?.map((product) => ({
// //         ...product,
// //         info: product?.info?.map((item) => ({
// //           ...item,
// //         })),
// //       }));
// //       setTotalPages(totalPages);

// //       return productsWithSizes;
// //     } catch (error) {
// //       console.error("Error fetching products:", error);
// //       return [];
// //     }
// //   };

// //   useEffect(() => {
// //     const fetchAllProducts = async () => {
// //       setLoading(true);
// //       try {
// //         const products = await fetchProducts(
// //           currentPage,
// //           postsPerPage,
// //           selectedType,
// //           selectedFilters
// //         );

// //         setAllProducts(products);
// //       } catch (error) {
// //         console.error("Error fetching products:", error);
// //       } finally {
// //         setLoading(false);
// //       }
// //     };

// //     fetchAllProducts();
// //   }, [currentPage, postsPerPage, selectedType, selectedFilters]);

// //   const handleTypeChange = (type) => {
// //     setSelectedType(type);
// //     localStorage.setItem("selectedType", type);
// //   };

// //   const handleFilterChange = (filters) => {
// //     setSelectedFilters(filters);
// //   };

// //   const getProductDetails = (productId) => {
// //     return allProducts?.find((product) => product?.pkProdId === productId);
// //   };

// //   const getSuggestedProducts = (productId) => {
// //     const product = getProductDetails(productId);
// //     return product ? product.suggestedProducts || [] : [];
// //   };

// //   return (
// //     <ProductContext.Provider
// //       value={{
// //         productType,
// //         allProducts,
// //         getProductDetails,
// //         getSuggestedProducts,
// //         currentPage,
// //         setCurrentPage,
// //         postsPerPage,
// //         totalPages,
// //         loading,
// //         setLoading,
// //         setPostsPerPage,
// //         selectedType,
// //         setSelectedType: handleTypeChange,
// //         selectedFilters,
// //         setSelectedFilters: handleFilterChange,
// //       }}
// //     >
// //       {children}
// //     </ProductContext.Provider>
// //   );
// // };

// // export default ProductProvider;

// // // import React, { createContext, useEffect, useState } from "react";
// // // import { useLocation } from "react-router-dom";

// // // export const ProductContext = createContext();

// // // const ProductProvider = ({ children }) => {
// // //   const [productType, setProductType] = useState([]);
// // //   const [allProducts, setAllProducts] = useState([]);
// // //   const [currentPage, setCurrentPage] = useState(1);
// // //   const [postsPerPage, setPostsPerPage] = useState(8);
// // //   const [totalPages, setTotalPages] = useState(0);
// // //   const [loading, setLoading] = useState(true);
// // //   const [selectedType, setSelectedType] = useState("");
// // //   const [selectedFilters, setSelectedFilters] = useState([]);

// // //   const location = useLocation();

// // //   useEffect(() => {
// // //     const fetchProductTypes = async () => {
// // //       try {
// // //         const response = await fetch(`${process.env.REACT_APP_API_URL}/home/productTypeList`);
// // //         const data = await response.json();
// // //         if (data.status === "Successful") {
// // //           const types = data.option
// // //             .map((type) => type.split(",").map((subType) => subType.trim()))
// // //             .flat();
// // //           setProductType(types);
// // //         }
// // //       } catch (error) {
// // //         console.error("Error fetching product types:", error);
// // //       }
// // //     };

// // //     fetchProductTypes();
// // //   }, []);

// // //   useEffect(() => {
// // //     const fetchProducts = async (pageNo, pageSize, prodType, filters) => {
// // //       try {
// // //         const response = await fetch(`${process.env.REACT_APP_API_URL}/product/search`, {
// // //           method: "POST",
// // //           headers: {
// // //             "Content-Type": "application/json",
// // //           },
// // //           body: JSON.stringify({
// // //             pkProdId: "",
// // //             prodName: "",
// // //             prodCategory: filters.join(",") || "",
// // //             prodType: prodType,
// // //             price: "",
// // //             pageNo: pageNo,
// // //             pageSize: pageSize,
// // //           }),
// // //         });
// // //         if (!response.ok) {
// // //           throw new Error("Failed to fetch products");
// // //         }
// // //         const data = await response.json();
// // //         const totalResults = data.totalResult;
// // //         const totalPages = Math.ceil(totalResults / pageSize);

// // //         const productsWithSizes = data?.masterData?.map((product) => ({
// // //           ...product,
// // //           info: product?.info?.map((item) => ({ ...item })),
// // //         }));
// // //         setTotalPages(totalPages);
// // //         setAllProducts(productsWithSizes);
// // //       } catch (error) {
// // //         console.error("Error fetching products:", error);
// // //         setAllProducts([]);
// // //       } finally {
// // //         setLoading(false);
// // //       }
// // //     };

// // //     fetchProducts(currentPage, postsPerPage, selectedType, selectedFilters);
// // //   }, [currentPage, postsPerPage, selectedType, selectedFilters]);

// // //   useEffect(() => {
// // //     const query = new URLSearchParams(location.search);
// // //     const type = query.get("type") || "";
// // //     const filters = query.get("filters") ? query.get("filters").split(",") : [];
// // //     setSelectedType(type);
// // //     setSelectedFilters(filters);
// // //   }, [location.search]);

// // //   const handleTypeChange = (type) => {
// // //     setSelectedType(type);
// // //   };

// // //   const handleFilterChange = (filters) => {
// // //     setSelectedFilters(filters);
// // //   };

// // //   const getProductDetails = (productId) => {
// // //     return allProducts?.find((product) => product?.pkProdId === productId);
// // //   };

// // //   const getSuggestedProducts = (productId) => {
// // //     const product = getProductDetails(productId);
// // //     return product ? product.suggestedProducts || [] : [];
// // //   };

// // //   return (
// // //     <ProductContext.Provider
// // //       value={{
// // //         productType,
// // //         allProducts,
// // //         getProductDetails,
// // //         getSuggestedProducts,
// // //         currentPage,
// // //         setCurrentPage,
// // //         postsPerPage,
// // //         totalPages,
// // //         loading,
// // //         setLoading,
// // //         setPostsPerPage,
// // //         selectedType,
// // //         setSelectedType: handleTypeChange,
// // //         selectedFilters,
// // //         setSelectedFilters: handleFilterChange,
// // //       }}
// // //     >
// // //       {children}
// // //     </ProductContext.Provider>
// // //   );
// // // };

// // // export default ProductProvider;

// // // // import React, { createContext, useEffect, useState } from "react";

// // // // export const ProductContext = createContext();

// // // // const ProductProvider = ({ children }) => {
// // // //   const [productType, setProductType] = useState([]);
// // // //   const [allProducts, setAllProducts] = useState([]);
// // // //   const [currentPage, setCurrentPage] = useState(1);
// // // //   const [postsPerPage, setPostsPerPage] = useState(2);
// // // //   const [totalPages, setTotalPages] = useState(0); // Total pages state
// // // //   const [loading, setLoading] = useState(true);
// // // //   const [selectedType, setSelectedType] = useState(
// // // //     localStorage.getItem("selectedType") || ""
// // // //   );

// // // //   useEffect(() => {
// // // //     const fetchProductTypes = async () => {
// // // //       try {
// // // //         const response = await fetch(
// // // //           `${process.env.REACT_APP_API_URL}/home/productTypeList`
// // // //         );
// // // //         const data = await response.json();
// // // //         if (data.status === "Successful") {
// // // //           const types = data.option
// // // //             .map((type) =>
// // // //               type.split(",").map((subType) => subType.trim())
// // // //             )
// // // //             .flat();
// // // //           setProductType(types);
// // // //         }
// // // //       } catch (error) {
// // // //         console.error("Error fetching product types:", error);
// // // //       }
// // // //     };

// // // //     fetchProductTypes();
// // // //   }, []);

// // // //   const fetchProducts = async (pageNo, pageSize, prodType, filters = []) => {
// // // //     try {
// // // //       const response = await fetch(
// // // //         `${process.env.REACT_APP_API_URL}/product/search`,
// // // //         {
// // // //           method: "POST",
// // // //           headers: {
// // // //             "Content-Type": "application/json",
// // // //           },
// // // //           body: JSON.stringify({
// // // //             pkProdId: "",
// // // //             prodName: "",
// // // //             prodCategory: filters.join(",") || "",
// // // //             prodType: prodType,
// // // //             price: "",
// // // //             pageNo: pageNo,
// // // //             pageSize: pageSize,
// // // //           }),
// // // //         }
// // // //       );
// // // //       if (!response.ok) {
// // // //         throw new Error("Failed to fetch products");
// // // //       }
// // // //       const data = await response.json();
// // // //       const totalResults = data.totalResult;
// // // //       const totalPages = Math.ceil(totalResults / pageSize);

// // // //       const productsWithSizes = data?.masterData?.map((product) => ({
// // // //         ...product,
// // // //         info: product?.info?.map((item) => ({
// // // //           ...item,
// // // //         })),
// // // //       }));
// // // //       setTotalPages(totalPages);

// // // //       return productsWithSizes;
// // // //     } catch (error) {
// // // //       console.error("Error fetching products:", error);
// // // //       return [];
// // // //     }
// // // //   };

// // // //   useEffect(() => {
// // // //     const fetchAllProducts = async () => {
// // // //       setLoading(true);
// // // //       try {
// // // //         const products = await fetchProducts(
// // // //           currentPage,
// // // //           postsPerPage,
// // // //           selectedType
// // // //         );

// // // //         setAllProducts(products);
// // // //       } catch (error) {
// // // //         console.error("Error fetching products:", error);
// // // //       } finally {
// // // //         setLoading(false);
// // // //       }
// // // //     };

// // // //     fetchAllProducts();
// // // //   }, [currentPage, postsPerPage, selectedType]);

// // // //   const handleTypeChange = (type) => {
// // // //     setSelectedType(type);
// // // //     localStorage.setItem("selectedType", type);
// // // //   };

// // // //   const getProductDetails = (productId) => {
// // // //     return allProducts?.find((product) => product?.pkProdId === productId);
// // // //   };

// // // //   const getSuggestedProducts = (productId) => {
// // // //     const product = getProductDetails(productId);
// // // //     return product ? product.suggestedProducts || [] : [];
// // // //   };

// // // //   return (
// // // //     <ProductContext.Provider
// // // //       value={{
// // // //         productType,
// // // //         allProducts,
// // // //         getProductDetails,
// // // //         getSuggestedProducts,
// // // //         currentPage,
// // // //         setCurrentPage,
// // // //         postsPerPage,
// // // //         totalPages,
// // // //         loading,
// // // //         setLoading,
// // // //         setPostsPerPage,
// // // //         setAllProducts,
// // // //         setTotalPages,
// // // //         selectedType,
// // // //         setSelectedType: handleTypeChange,
// // // //       }}
// // // //     >
// // // //       {children}
// // // //     </ProductContext.Provider>
// // // //   );
// // // // };

// // // // export default ProductProvider;

// // // // // import React, { createContext, useEffect, useState } from "react";

// // // // // export const ProductContext = createContext();

// // // // // const ProductProvider = ({ children }) => {
// // // // //   const [productType, setProductType] = useState([]);
// // // // //   const [allProducts, setAllProducts] = useState([]);
// // // // //   const [currentPage, setCurrentPage] = useState(1);
// // // // //   const [postsPerPage, setPostsPerPage] = useState(9);
// // // // //   const [totalPages, setTotalPages] = useState(0); // Total pages state
// // // // //   const [loading, setLoading] = useState(true);
// // // // //   const [selectedType, setSelectedType] = useState(
// // // // //     localStorage.getItem("selectedType") || ""
// // // // //   );

// // // // //   useEffect(() => {
// // // // //     const fetchProductTypes = async () => {
// // // // //       try {
// // // // //         const response = await fetch(
// // // // //           `${process.env.REACT_APP_API_URL}/home/productTypeList`
// // // // //         );
// // // // //         const data = await response.json();
// // // // //         if (data.status === "Successful") {
// // // // //           const types = data.option
// // // // //             .map((type) =>
// // // // //               type.split(",").map((subType) => subType.trim())
// // // // //             )
// // // // //             .flat();
// // // // //           setProductType(types);
// // // // //         }
// // // // //       } catch (error) {
// // // // //         console.error("Error fetching product types:", error);
// // // // //       }
// // // // //     };

// // // // //     fetchProductTypes();
// // // // //   }, []);

// // // // //   const fetchProducts = async (pageNo, pageSize, prodType) => {
// // // // //     try {
// // // // //       const response = await fetch(
// // // // //         `${process.env.REACT_APP_API_URL}/product/search`,
// // // // //         {
// // // // //           method: "POST",
// // // // //           headers: {
// // // // //             "Content-Type": "application/json",
// // // // //           },
// // // // //           body: JSON.stringify({
// // // // //             pkProdId: "",
// // // // //             prodName: "",
// // // // //             prodCategory: "",
// // // // //             prodType: prodType,
// // // // //             price: "",
// // // // //             pageNo: pageNo,
// // // // //             pageSize: pageSize,
// // // // //           }),
// // // // //         }
// // // // //       );
// // // // //       if (!response.ok) {
// // // // //         throw new Error("Failed to fetch products");
// // // // //       }
// // // // //       const data = await response.json();
// // // // //       const totalResults = data.totalResult;
// // // // //       const totalPages = Math.ceil(totalResults / pageSize);

// // // // //       const productsWithSizes = data?.masterData?.map((product) => ({
// // // // //         ...product,
// // // // //         info: product?.info?.map((item) => ({
// // // // //           ...item,
// // // // //         })),
// // // // //       }));
// // // // //       setTotalPages(totalPages);

// // // // //       return productsWithSizes;
// // // // //     } catch (error) {
// // // // //       console.error("Error fetching products:", error);
// // // // //       return [];
// // // // //     }
// // // // //   };

// // // // //   useEffect(() => {
// // // // //     const fetchAllProducts = async () => {
// // // // //       setLoading(true);
// // // // //       try {
// // // // //         const products = await fetchProducts(
// // // // //           currentPage,
// // // // //           postsPerPage,
// // // // //           selectedType
// // // // //         );

// // // // //         setAllProducts(products);
// // // // //       } catch (error) {
// // // // //         console.error("Error fetching products:", error);
// // // // //       } finally {
// // // // //         setLoading(false);
// // // // //       }
// // // // //     };

// // // // //     fetchAllProducts();
// // // // //   }, [currentPage, postsPerPage, selectedType]);

// // // // //   const handleTypeChange = (type) => {
// // // // //     setSelectedType(type);
// // // // //     localStorage.setItem("selectedType", type);
// // // // //   };

// // // // //   const getProductDetails = (productId) => {
// // // // //     return allProducts?.find((product) => product?.pkProdId === productId);
// // // // //   };

// // // // //   const getSuggestedProducts = (productId) => {
// // // // //     const product = getProductDetails(productId);
// // // // //     return product ? product.suggestedProducts || [] : [];
// // // // //   };

// // // // //   return (
// // // // //     <ProductContext.Provider
// // // // //       value={{
// // // // //         productType,
// // // // //         allProducts,
// // // // //         getProductDetails,
// // // // //         getSuggestedProducts,
// // // // //         currentPage,
// // // // //         setCurrentPage,
// // // // //         postsPerPage,
// // // // //         totalPages,
// // // // //         loading,
// // // // //         setLoading,
// // // // //         setPostsPerPage,
// // // // //         selectedType,
// // // // //         setSelectedType: handleTypeChange,
// // // // //       }}
// // // // //     >
// // // // //       {children}
// // // // //     </ProductContext.Provider>
// // // // //   );
// // // // // };

// // // // // export default ProductProvider;

// // // // // import React, { createContext, useEffect, useState } from "react";

// // // // // export const ProductContext = createContext();

// // // // // const ProductProvider = ({ children }) => {
// // // // //   const [productCategories, setProductCategories] = useState([]);
// // // // //   const [allProducts, setAllProducts] = useState([]);
// // // // //   const [currentPage, setCurrentPage] = useState(1);
// // // // //   const [postsPerPage, setPostsPerPage] = useState(9);
// // // // //   const [totalPages, setTotalPages] = useState(0); // Total pages state
// // // // //   const [loading, setLoading] = useState(true);
// // // // //   const [selectedCategory, setSelectedCategory] = useState("Customized");

// // // // //   useEffect(() => {
// // // // //     const fetchProductCategories = async () => {
// // // // //       try {
// // // // //         const response = await fetch(`${process.env.REACT_APP_API_URL}/home/productCategoryList`);
// // // // //         const data = await response.json();
// // // // //         if (data.status === "Successful") {
// // // // //           const categories = data.option
// // // // //             .map((category) =>
// // // // //               category.split(",").map((subCategory) => subCategory.trim())
// // // // //             )
// // // // //             .flat();
// // // // //           setProductCategories(categories);
// // // // //         }
// // // // //       } catch (error) {
// // // // //         console.error("Error fetching product categories:", error);
// // // // //       }
// // // // //     };

// // // // //     fetchProductCategories();
// // // // //   }, []);

// // // // //   const fetchProducts = async (pageNo, pageSize, prodCategory) => {
// // // // //     try {
// // // // //       const response = await fetch(`${process.env.REACT_APP_API_URL}/product/search`, {
// // // // //         method: "POST",
// // // // //         headers: {
// // // // //           "Content-Type": "application/json",
// // // // //         },
// // // // //         body: JSON.stringify({
// // // // //           pkProdId: "",
// // // // //           prodName: "",
// // // // //           prodCategory: prodCategory,
// // // // //           price: "",
// // // // //           pageNo: pageNo,
// // // // //           pageSize: pageSize,
// // // // //         }),
// // // // //       });
// // // // //       if (!response.ok) {
// // // // //         throw new Error("Failed to fetch products");
// // // // //       }
// // // // //       const data = await response.json();
// // // // //       const totalResults = data.totalResult;
// // // // //       const totalPages = Math.ceil(totalResults / pageSize);

// // // // //       // Process product data
// // // // //       const productsWithSizes = data?.masterData?.map((product) => ({
// // // // //         ...product,
// // // // //         info: product?.info?.map((item) => ({
// // // // //           ...item,
// // // // //         })),
// // // // //       }));
// // // // //       setTotalPages(totalPages);

// // // // //       return productsWithSizes;
// // // // //     } catch (error) {
// // // // //       console.error("Error fetching products:", error);
// // // // //       return [];
// // // // //     }
// // // // //   };

// // // // //   useEffect(() => {
// // // // //     const fetchAllProducts = async () => {
// // // // //       setLoading(true);
// // // // //       try {
// // // // //         const products = await fetchProducts(
// // // // //           currentPage,
// // // // //           postsPerPage,
// // // // //           selectedCategory
// // // // //         );

// // // // //         setAllProducts(products);
// // // // //       } catch (error) {
// // // // //         console.error("Error fetching products:", error);
// // // // //       } finally {
// // // // //         setLoading(false);
// // // // //       }
// // // // //     };

// // // // //     fetchAllProducts();
// // // // //   }, [currentPage, postsPerPage, selectedCategory]);

// // // // //   const handleCategoryChange = (category) => {
// // // // //     setSelectedCategory(category);
// // // // //   };

// // // // //   const getProductDetails = (productId) => {
// // // // //     return allProducts?.find((product) => product?.pkProdId === productId);
// // // // //   };

// // // // //   const getSuggestedProducts = (productId) => {
// // // // //     const product = getProductDetails(productId);
// // // // //     return product ? product.suggestedProducts || [] : [];
// // // // //   };

// // // // //   return (
// // // // //     <ProductContext.Provider
// // // // //       value={{
// // // // //         productCategories,
// // // // //         allProducts,
// // // // //         getProductDetails,
// // // // //         getSuggestedProducts,
// // // // //         currentPage,
// // // // //         setCurrentPage,
// // // // //         postsPerPage,
// // // // //         totalPages,
// // // // //         loading,
// // // // //         setPostsPerPage,
// // // // //         selectedCategory,
// // // // //         setSelectedCategory: handleCategoryChange,
// // // // //       }}
// // // // //     >
// // // // //       {children}
// // // // //     </ProductContext.Provider>
// // // // //   );
// // // // // };

// // // // // export default ProductProvider;
