import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const BtoBProduct = ({ product }) => {
  const firstVariant = product?.info[0];
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    userName: "",
    userEmail: "",
    phoneNo: "",
    quantity: "",
    message: "",
  });
  const [errors, setErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    validateField(name, value);
  };

  const handleQuantityChange = (e) => {
    const { value } = e.target;
    setFormData({ ...formData, quantity: value });
    validateField("quantity", value);
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    validateField(name, value);
  };

  const validateField = (name, value) => {
    let errorMsg = "";
    if (name === "userName" && !value.trim()) {
      errorMsg = "Name is required.";
    } else if (
      name === "userEmail" &&
      !/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(value)
    ) {
      errorMsg = "Valid email is required.";
    } else if (name === "phoneNo" && !value.trim()) {
      errorMsg = "Phone number is required.";
    } else if (name === "quantity") {
      if (!value.trim() || value < 50) {
        errorMsg = "Quantity must be 50 or higher.";
      }
    }

    setErrors((prevErrors) => ({ ...prevErrors, [name]: errorMsg }));
  };

  const validateForm = () => {
    const formErrors = {};
    Object.keys(formData).forEach((name) => {
      validateField(name, formData[name]);
      if (formData[name].trim() === "" || errors[name]) {
        formErrors[name] = errors[name] || "This field is required.";
      }
    });

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    const requestBody = {
      ...formData,
      prodCode: product?.prodCode || "",
    };

    try {
      const responses = await Promise.all([
        fetch(`${process.env.REACT_APP_API_URL}/bulkEnq/add`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(requestBody),
        }),
        fetch(`${process.env.REACT_APP_API_URL}/bulkEnq/userEmail`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(requestBody),
        }),
        fetch(`${process.env.REACT_APP_API_URL}/bulkEnq/adminEmail`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(requestBody),
        }),
      ]);

      const results = await Promise.all(
        responses.map((response) => response.text())
      );

      // Reset form on successful submission
      setFormData({
        userName: "",
        userEmail: "",
        phoneNo: "",
        quantity: "",
        message: "",
      });

      toast.success("Form submitted successfully!",{
        autoClose: 1000,
        position: 'bottom-right'
      });

      // Close modal and navigate
      const modal = document.querySelector(
        `#staticBackdrop-${product?.pkProdId}`
      );
      if (modal) {
        const modalInstance = window.bootstrap.Modal.getInstance(modal);
        if (modalInstance) modalInstance.hide();
      }
      navigate("/b2b");
    } catch (error) {
      setErrors({ form: `Error: ${error.message}` });
    }
  };

  // Generate a unique id for the modal
  const modalId = `staticBackdrop-${product?.pkProdId}`;

  return (
    <>
      <div
        className="b2b-modal-btn"
        data-bs-toggle="modal"
        data-bs-target={`#${modalId}`}
      >
        <div className="text-decoration-none d-flex justify-content-between flex-column p-2 b2b-row-block">
          <img
            src={firstVariant?.imagePath[0]}
            alt={firstVariant?.productName}
          />
          <div className="mt-4 b2b-raw-block-details">
            <div className="h5 product-color">{product?.prodName}</div>
            <div className="fw-medium b2b-raw-block-details-price">
              {/* Product Code : */}
              <div className="h5 fs-6 fw-medium mb-0">{product?.prodCode}</div>
              {/* <div className="h5 fs-6 fw-medium mb-0"><span class="badge text-bg-secondary" style={{fontSize: "11px", fontWeight: "400"}}>{product?.prodType}</span></div> */}
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade b2b-page-user-details-modal-popup"
        id={modalId}
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby={`${modalId}Label`}
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id={`${modalId}Label`}>
                Fill the Form
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body pt-0 px-5 padding-mobile-20">
              <form className="pt-2 pb-3" onSubmit={handleSubmit}>
                <div className="d-flex flex-column mb-4 b2b-form-group">
                  <label
                    htmlFor={`productCode-${product?.pkProdId}`}
                    className="text-capitalize mb-1"
                  >
                    Your Product Code
                  </label>
                  <input
                    type="text"
                    id={`productCode-${product?.pkProdId}`}
                    name="productCode"
                    value={product?.prodCode || ""}
                    readOnly
                    className="px-2 rounded-0"
                  />
                </div>
                <div className="d-flex flex-column mb-4 b2b-form-group">
                  <label
                    htmlFor={`name-${product?.pkProdId}`}
                    className="text-capitalize mb-1"
                  >
                    Your Name <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    id={`name-${product?.pkProdId}`}
                    name="userName"
                    value={formData.userName}
                    onChange={handleInputChange}
                    onBlur={handleBlur}
                    autoComplete="off"
                    className="px-2 rounded-0"
                  />
                  {errors.userName && (
                    <div className="text-danger">{errors.userName}</div>
                  )}
                </div>
                <div className="d-flex flex-column mb-4 b2b-form-group">
                  <label
                    htmlFor={`number-${product?.pkProdId}`}
                    className="text-capitalize mb-1"
                  >
                    Your Number <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    id={`number-${product?.pkProdId}`}
                    name="phoneNo"
                    value={formData.phoneNo}
                    onChange={handleInputChange}
                    onBlur={handleBlur}
                    autoComplete="off"
                    className="px-2 rounded-0"
                  />
                  {errors.phoneNo && (
                    <div className="text-danger">{errors.phoneNo}</div>
                  )}
                </div>
                <div className="d-flex flex-column mb-4 b2b-form-group">
                  <label
                    htmlFor={`email-${product?.pkProdId}`}
                    className="text-capitalize mb-1"
                  >
                    Your Email <span className="text-danger">*</span>
                  </label>
                  <input
                    type="email"
                    id={`email-${product?.pkProdId}`}
                    name="userEmail"
                    value={formData.userEmail}
                    onChange={handleInputChange}
                    onBlur={handleBlur}
                    autoComplete="off"
                    className="px-2 rounded-0"
                  />
                  {errors.userEmail && (
                    <div className="text-danger">{errors.userEmail}</div>
                  )}
                </div>
                <div className="d-flex flex-column mb-4 b2b-form-group">
                  <label
                    htmlFor={`quantity-${product?.pkProdId}`}
                    className="text-capitalize mb-1"
                  >
                    Your Quantity <span className="text-danger">*</span>
                  </label>
                  <input
                    type="number"
                    id={`quantity-${product?.pkProdId}`}
                    name="quantity"
                    value={formData.quantity}
                    onChange={handleQuantityChange}
                    onBlur={handleBlur}
                    autoComplete="off"
                    min="50"
                    className="px-2 rounded-0"
                  />
                  {errors.quantity && (
                    <div className="text-danger">{errors.quantity}</div>
                  )}
                </div>
                <div className="d-flex flex-column mb-4 b2b-form-group">
                  <label
                    htmlFor={`message-${product?.pkProdId}`}
                    className="text-capitalize mb-1"
                  >
                    Your Message
                  </label>
                  <textarea
                    name="message"
                    id={`message-${product?.pkProdId}`}
                    value={formData.message}
                    onChange={handleInputChange}
                    autoComplete="off"
                    className="p-2 rounded-0"
                    rows="7"
                  ></textarea>
                </div>
                {errors.form && (
                  <div className="text-danger mb-3">{errors.form}</div>
                )}
                <button
                  type="submit"
                  className="text-capitalize b2b-form-button"
                >
                  Send
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BtoBProduct;
