import React, { useState, useContext, useEffect } from "react";
import Breadcrumb from "../../components/Breadcrumb";
import { BtoBContext } from "../../contexts/BtoBContext";
import BtoBProduct from "../../components/BtoBProduct";
import axios from "axios";
import Loading from "../../components/Loading";
import MetaDescTag from "../../components/MetaDescTag";

const BusinessToBusiness = () => {
  const {
    allProducts,
    loading,
    currentPage,
    setCurrentPage,
    postsPerPage,
    totalPages,
    selectedFilters,
    setSelectedFilters,
  } = useContext(BtoBContext);

  const [filterType, setFilterType] = useState([]);
  const [loadingFilters, setLoadingFilters] = useState(true);

  const fetchFilterType = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/product/productCategoryList`,
        { prodType: null }
      );
      if (response.data && response.data.option) {
        setFilterType(response.data.option);
      }
      setLoadingFilters(false);
    } catch (error) {
      console.error("Error fetching filter categories:", error);
      setLoadingFilters(false);
    }
  };

  useEffect(() => {
    setCurrentPage(1);
    fetchFilterType();
  }, [setCurrentPage]);

  const handleFilterChange = (e) => {
    const { value, checked } = e.target;
    setSelectedFilters((prev) =>
      checked ? [...prev, value] : prev.filter((filter) => filter !== value)
    );
  };

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  return (
    <>
      <MetaDescTag
        title={"B2B | Jewelry Boxes | KARV Accessories"}
        description={
          "Welcome to Karv Accessories, where passion, craftsmanship, and style unite to create exquisite leather products. Founded by the dynamic duo, Karishma and Vikram, our journey began with a shared vision to redefine leather goods and accessories."
        }
      />
      <section className="b2b-page">
        {/* <Breadcrumb title="B2B" /> */}
        <div className="py-5 b2b-page-block">
          <div className="our-container">
            <div className="inner-container px-5 mx-auto">
              <div className="pb-5 b2b-page-header">
                <h1 className="fs-1 fw-medium text-capitalize mb-2">
                  Products
                </h1>
              </div>
              <div className="b2b-page-body">
                <div className="filter-section">
                  <p className="mb-2">Filters</p>
                  {loadingFilters ? (
                    <Loading />
                  ) : (
                    <div className="filter-checkboxes">
                      {filterType.map((item, index) => (
                        <div className="form-check" key={index}>
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value={item}
                            id={`flexCheckDefault-${index}`}
                            checked={selectedFilters.includes(item)}
                            onChange={handleFilterChange}
                          />
                          <label
                            className="form-check-label"
                            htmlFor={`flexCheckDefault-${index}`}
                          >
                            {item}
                          </label>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
                <div>
                  <div className="d-grid gap-3 b2b-page-row">
                    {loading ? (
                      <Loading />
                    ) : (
                      allProducts.map((product) => (
                        <BtoBProduct
                          key={product?.pkProdId}
                          product={product}
                        />
                      ))
                    )}
                  </div>
                  <div className="mt-5 b2b-page-pagination">
                    <button
                      onClick={() => handlePageChange(currentPage - 1)}
                      disabled={currentPage === 1}
                      className={`pagination-disable-btn ${
                        currentPage === 1 ? "prev-button-disabled" : ""
                      }`}
                    >
                      Previous
                    </button>
                    <button
                      onClick={() => handlePageChange(currentPage + 1)}
                      disabled={currentPage === totalPages}
                      className={`pagination-next-btn ${
                        currentPage === totalPages ? "next-button-disabled" : ""
                      }`}
                    >
                      Next
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BusinessToBusiness;

// import React, { useState, useContext, useEffect } from "react";
// import Breadcrumb from "../../components/Breadcrumb";
// import { BtoBContext } from "../../contexts/BtoBContext";
// import BtoBProduct from "../../components/BtoBProduct";
// import axios from "axios";
// import Loading from "../../components/Loading";

// const BusinessToBusiness = () => {
//   const {
//     allProducts,
//     loading,
//     currentPage,
//     setCurrentPage,
//     postsPerPage,
//     totalPages,
//     selectedFilters,
//     setSelectedFilters,
//   } = useContext(BtoBContext);

//   const [filterType, setFilterType] = useState([]);
//   const [loadingFilters, setLoadingFilters] = useState(true);

//   // Fetch filter categories
//   const fetchFilterType = async () => {
//     try {
//       const response = await axios.post(
//         `${process.env.REACT_APP_API_URL}/product/productCategoryList`,
//         { prodType: null }
//       );
//       if (response.data && response.data.option) {
//         setFilterType(response.data.option);
//       }
//       setLoadingFilters(false);
//     } catch (error) {
//       console.error("Error fetching filter categories:", error);
//       setLoadingFilters(false);
//     }
//   };

//   // Fetch products based on filters
//   const fetchProducts = async () => {
//     try {
//       const response = await axios.post(
//         `${process.env.REACT_APP_API_URL}/product/fetchProducts`,
//         {
//           filters: selectedFilters,
//           page: currentPage,
//           limit: postsPerPage,
//         }
//       );
//       if (response.data) {
//         // Handle products and total pages
//         // Assuming your API returns products and totalPages
//         // Example:
//         // setAllProducts(response.data.products);
//         // setTotalPages(response.data.totalPages);
//       }
//     } catch (error) {
//       console.error("Error fetching products:", error);
//     }
//   };

//   useEffect(() => {
//     fetchFilterType();
//     fetchProducts(); // Fetch products when filters or page change
//   }, [selectedFilters, currentPage]);

//   useEffect(() => {
//     // Persist selected filters in localStorage
//     localStorage.setItem("selectedFilters", JSON.stringify(selectedFilters));
//   }, [selectedFilters]);

//   useEffect(() => {
//     // Retrieve selected filters from localStorage
//     const savedFilters = JSON.parse(localStorage.getItem("selectedFilters"));
//     if (savedFilters) {
//       setSelectedFilters(savedFilters);
//     }
//   }, [setSelectedFilters]);

//   const handleFilterChange = (e) => {
//     const { value, checked } = e.target;
//     setSelectedFilters((prev) =>
//       checked ? [...prev, value] : prev.filter((filter) => filter !== value)
//     );
//   };

//   const handlePageChange = (newPage) => {
//     if (newPage > 0 && newPage <= totalPages) {
//       setCurrentPage(newPage);
//     }
//   };

//   return (
//     <>
//       <section className="b2b-page">
//         <Breadcrumb title="B2B" />
//         <div className="py-5 b2b-page-block">
//           <div className="our-container">
//             <div className="inner-container px-5 mx-auto">
//               <div className="pb-5 b2b-page-header">
//                 <h1 className="fs-1 fw-medium text-capitalize mb-2">
//                   Products
//                 </h1>
//               </div>
//               <div className="b2b-page-body">
//                 <div className="filter-section">
//                   <p className="mb-2">Filters</p>
//                   {loadingFilters ? (
//                     <Loading />
//                   ) : (
//                     <div className="filter-checkboxes">
//                       {filterType.map((item, index) => (
//                         <div className="form-check" key={index}>
//                           <input
//                             className="form-check-input"
//                             type="checkbox"
//                             value={item}
//                             id={`flexCheckDefault-${index}`}
//                             checked={selectedFilters.includes(item)}
//                             onChange={handleFilterChange}
//                           />
//                           <label
//                             className="form-check-label"
//                             htmlFor={`flexCheckDefault-${index}`}
//                           >
//                             {item}
//                           </label>
//                         </div>
//                       ))}
//                     </div>
//                   )}
//                 </div>
//                 <div>
//                   <div className="d-grid gap-3 b2b-page-row">
//                     {loading ? (
//                       <Loading />
//                     ) : (
//                       allProducts.map((product) => (
//                         <BtoBProduct key={product?.pkProdId} product={product} />
//                       ))
//                     )}
//                   </div>
//                   <div className="mt-5 b2b-page-pagination">
//                     <button
//                       onClick={() => handlePageChange(currentPage - 1)}
//                       disabled={currentPage === 1}
//                       className={`pagination-disable-btn ${
//                         currentPage === 1 ? "prev-button-disabled" : ""
//                       }`}
//                     >
//                       Previous
//                     </button>
//                     <button
//                       onClick={() => handlePageChange(currentPage + 1)}
//                       disabled={currentPage === totalPages}
//                       className={`pagination-next-btn ${
//                         currentPage === totalPages ? "next-button-disabled" : ""
//                       }`}
//                     >
//                       Next
//                     </button>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>
//     </>
//   );
// };

// export default BusinessToBusiness;
