import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { FaIndianRupeeSign, FaMinus, FaPlus } from "react-icons/fa6";
import { MdKeyboardArrowLeft } from "react-icons/md";
import Breadcrumb from "../../components/Breadcrumb";
import { CartContext } from "../../contexts/CartContext";
import { GetColorName } from "hex-color-to-color-name";

const Cart = () => {
  const { cartItems, setCartItems, removeFromCart, clearCart, getTotalPrice } =
    useContext(CartContext);

  // Function to extract color name from product name
  const getColorFromProductName = (productName) => {
    const match = productName.match(/\(([^)]+)\)/);
    return match ? match[1] : "Unknown Color";
  };

  // =========================== first image show =========================
  const getDefaultImage = (product) => {
    if (product.selectedColor && product.selectedColor.imagePath) {
      return product.selectedColor.imagePath[0];
    }
    if (product.info && product.info.length > 0) {
      return product.info[0].imagePath[0];
    }
  };
  // ========================== last image show ===========================
  // const getDefaultImage = (product) => {
  //   if (product.selectedColor && product.selectedColor.imagePath) {
  //     return product.selectedColor.imagePath[
  //       product.selectedColor.imagePath.length - 1
  //     ];
  //   }
  //   if (product.info && product.info.length > 0) {
  //     return product.info[0].imagePath[0];
  //   }
  // };

  const getColorName = (hexColor) => {
    console.log("Hex Color Received:", hexColor); // Add this line for debugging
    if (!hexColor || typeof hexColor !== "string") {
      return "Unknown Color";
    }

    const isValidHex = /^#([0-9A-Fa-f]{6}|[0-9A-Fa-f]{3})$/.test(hexColor);
    if (!isValidHex) {
      return "Invalid Color Code";
    }

    return GetColorName(hexColor) || "Unknown Color";
  };

  const increaseQuantity = (productId, selectedColor) => {
    setCartItems((prevItems) => {
      const updatedCart = prevItems.map((item) =>
        item.pkProdId === productId &&
        item.selectedColor.imageColor === selectedColor.imageColor
          ? { ...item, quantity: item.quantity + 1 }
          : item
      );
      localStorage.setItem("cartItems", JSON.stringify(updatedCart));
      return updatedCart;
    });
  };

  const decreaseQuantity = (productId, selectedColor) => {
    setCartItems((prevItems) => {
      const updatedCart = prevItems.map((item) =>
        item.pkProdId === productId &&
        item.selectedColor.imageColor === selectedColor.imageColor &&
        item.quantity > 1
          ? { ...item, quantity: item.quantity - 1 }
          : item
      );
      localStorage.setItem("cartItems", JSON.stringify(updatedCart));
      return updatedCart;
    });
  };

  const handleEditCustomization = (productId, selectedColor) => {
    const itemToUpdate = cartItems.find(
      (item) =>
        item.pkProdId === productId &&
        item.selectedColor.imageColor === selectedColor.imageColor
    );
    setEditModalItem(itemToUpdate);
    setShowEditModal(true);
  };

  const updateCustomization = (updatedCustomization) => {
    setCartItems((prevItems) =>
      prevItems.map((item) =>
        item.pkProdId === updatedCustomization.pkProdId &&
        item.selectedColor.imageColor ===
          updatedCustomization.selectedColor.imageColor
          ? { ...item, customization: updatedCustomization.customization }
          : item
      )
    );
    setShowEditModal(false);
    toast.success("Update Successfully!", {
      autoClose: 1000,
      position: "bottom-right",
    });
  };

  const [showEditModal, setShowEditModal] = useState(false);
  const [editModalItem, setEditModalItem] = useState(null);

  return (
    <section className="cart-page">
      {/* <Breadcrumb title="Cart" /> */}
      <div className="our-container">
        <div className="inner-container">
          <div className="cart-page-block py-5">
            <div className="cart-page-heading">
              <h1>Your Shopping Cart</h1>
            </div>
            <div className="cart-page-body">
              {cartItems.length === 0 ? (
                <div className="alert alert-info cart-alert-box" role="alert">
                  Your cart is empty.{" "}
                  <Link to="/product" className="cart-alert-box-link">
                    Shop now!
                  </Link>
                </div>
              ) : (
                <>
                  <ul className="list-group">
                    {cartItems.map((item, index) => (
                      <li
                        key={index}
                        className="list-group-item d-flex justify-content-between"
                      >
                        <div className="cart-detail-list-item d-flex w-100">
                          <div className="left-side-cart-img">
                            <Link
                              to={`/product/${item.pkProdId}`}
                              className="cart-detail-list-item-link"
                            >
                              <img src={getDefaultImage(item)} alt="" />
                            </Link>
                          </div>
                          <div className="right-side-cart-content ms-3 w-100">
                            <div className="right-cart-heading">
                              <Link
                                to={`/product/${item.pkProdId}`}
                                className="cart-detail-list-item-link"
                              >
                                <div className="h3 fw-medium">
                                  {item.prodName}
                                </div>
                              </Link>
                              <div className="h5 mb-0 fw-bold">
                                <FaIndianRupeeSign className="cart-rupees-sign" />
                                {item.prodPrice}
                              </div>
                            </div>
                            {item?.isCustomized === "1" && (
                              <p className="mb-1 fw-medium">
                                Color :{" "}
                                <span className="fw-normal">
                                  {/* {getColorName(item.selectedColor?.imageColor)} */}
                                  {getColorFromProductName(item.prodName)}
                                </span>
                              </p>
                            )}
                            {item.customization && (
                              <>
                                {item.customization.inputValue && (
                                  <p className="mb-0 fw-medium">
                                    Custom Text :{" "}
                                    <span className="fw-normal text-capitalize">
                                      {item.customization.inputValue}
                                    </span>
                                  </p>
                                )}
                                {item.customization.selectedZodiacSign && (
                                  <p className="mb-1 fw-medium">
                                    Zodiac Sign :{" "}
                                    <span className="fw-normal">
                                      {item.customization.selectedZodiacSign}
                                    </span>
                                  </p>
                                )}
                              </>
                            )}
                            <div className="right-cart-footer">
                              <div className="row justify-content-start gap-3 m-0 cart-page-quantity">
                                <div className="quantity-block d-flex align-items-center">
                                  <button
                                    className="quantity-btn"
                                    onClick={() =>
                                      decreaseQuantity(
                                        item.pkProdId,
                                        item.selectedColor
                                      )
                                    }
                                  >
                                    <FaMinus />
                                  </button>
                                  <span className="quantity-value mx-2">
                                    {item.quantity}
                                  </span>
                                  <button
                                    className="quantity-btn"
                                    onClick={() =>
                                      increaseQuantity(
                                        item.pkProdId,
                                        item.selectedColor
                                      )
                                    }
                                  >
                                    <FaPlus />
                                  </button>
                                </div>
                              </div>
                              {item?.isCustomized === "1" && (
                                <div className="cart-trash-btn">
                                  <button
                                    onClick={() =>
                                      handleEditCustomization(
                                        item.pkProdId,
                                        item.selectedColor
                                      )
                                    }
                                    className="faTrash-btn"
                                  >
                                    Edit
                                  </button>
                                </div>
                              )}
                              <div
                                className="cart-trash-btn ms-0"
                                style={{ border: 0 }}
                              >
                                <button
                                  onClick={() =>
                                    removeFromCart(
                                      item.pkProdId,
                                      item.selectedColor,
                                      item.customization
                                    )
                                  }
                                  className="faTrash-btn"
                                >
                                  Delete
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                  <div className="d-flex mt-5 mb-3 cart-sub-total">
                    <div className="h4 mb-0 fw-bold">
                      <span className="fw-normal">
                        Subtotal ({cartItems.length} items) :{" "}
                      </span>
                      <FaIndianRupeeSign className="subtotal-rupees-sign mb-2" />
                      {getTotalPrice()}
                    </div>
                    <div className="cart-footer-btn-group">
                      {cartItems.length > 1 && (
                        <button
                          onClick={clearCart}
                          className="cart-sub-total-btn"
                        >
                          Clear Cart
                        </button>
                      )}
                      <Link
                        to={{
                          pathname: "/checkout",
                          state: { cartItems },
                        }}
                        className="cart-sub-total-btn"
                      >
                        Proceed to Buy
                      </Link>
                    </div>
                  </div>
                  <div className="d-flex align-items-center justify-content-end">
                    <span>
                      <Link
                        to={"/product"}
                        className="cart-continue-shopping-link"
                      >
                        <MdKeyboardArrowLeft className="left-arrow-cart-cs" />
                        <p className="mb-0">Continue shopping</p>
                      </Link>
                    </span>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      {showEditModal && (
        <div
          className="modal cart-modal"
          tabIndex="-1"
          role="dialog"
          style={{ display: "block", backgroundColor: "rgba(0,0,0,0.5)" }}
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <div className="h5 modal-title">Edit Customization</div>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => setShowEditModal(false)}
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <label htmlFor="inputCustomText" className="form-label">
                  Custom Text:
                </label>
                <input
                  type="text"
                  className="form-control mb-3 text-capitalize form-fixed-height"
                  id="inputCustomText"
                  defaultValue={editModalItem?.customization?.inputValue || ""}
                  onChange={(e) => {
                    const updatedCustomization = {
                      ...editModalItem.customization,
                      inputValue: e.target.value,
                    };
                    setEditModalItem({
                      ...editModalItem,
                      customization: updatedCustomization,
                    });
                  }}
                  autoComplete="off"
                />
                <label htmlFor="selectZodiacSign" className="form-label">
                  Select Zodiac Sign:
                </label>
                <select
                  className="form-select mb-3 form-fixed-height"
                  id="selectZodiacSign"
                  defaultValue={
                    editModalItem?.customization?.selectedZodiacSign || ""
                  }
                  onChange={(e) => {
                    const updatedCustomization = {
                      ...editModalItem.customization,
                      selectedZodiacSign: e.target.value,
                    };
                    setEditModalItem({
                      ...editModalItem,
                      customization: updatedCustomization,
                    });
                  }}
                >
                  <option value="">Select a Zodiac Sign</option>
                  <option value="Aries">Aries</option>
                  <option value="Taurus">Taurus</option>
                  <option value="Gemini">Gemini</option>
                  <option value="Cancer">Cancer</option>
                  <option value="Leo">Leo</option>
                  <option value="Virgo">Virgo</option>
                  <option value="Libra">Libra</option>
                  <option value="Scorpio">Scorpio</option>
                  <option value="Sagittarius">Sagittarius</option>
                  <option value="Capricorn">Capricorn</option>
                  <option value="Aquarius">Aquarius</option>
                  <option value="Pisces">Pisces</option>
                </select>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary cart-modal-cancel-btn"
                  onClick={() => setShowEditModal(false)}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-primary cart-modal-btn"
                  onClick={() => {
                    updateCustomization(editModalItem);
                  }}
                >
                  Save Changes
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default Cart;
