import React, { useState } from "react";
import { Link } from "react-router-dom";
import ImageGallery from "../../components/ImageGallery";
import ClientLogoSlider from "../../components/ClientLogoSlider";
import { homeClients, sliderData } from "../../data";
import MetaDescTag from "../../components/MetaDescTag";
import SlickCarousel from "../../components/SlickCarousel";

const Home = () => {
  // website design and developed info
  const websiteDevelopedBy = localStorage.setItem(
    "websiteDevelopedBy",
    process.env.REACT_APP_OWNER_FOR_DEVELOPMENT
  );

  const [hoveredBgColor, setHoveredBgColor] = useState("");

  return (
    <>
      <MetaDescTag
        title={"KARV Accessories"}
        description={
          "Unveil the allure of KARV's latest collection, featuring an exquisite range of jewelry boxes, premium leather goods, and refined accessories. Our new line captures the essence of contemporary luxury with a perfect blend of classic design and innovative craftsmanship."
        }
      />
      <section className="home-page">
        <div className="main-banner-block overflow-hidden">
          <div className="inner-container p-0">
            <SlickCarousel sliderData={sliderData} />
          </div>
        </div>
        <div
          className="pt-4 pb-5 shop-your-product"
          style={{ backgroundColor: hoveredBgColor }}
        >
          <div className="our-container">
            <div className="inner-container mx-auto px-5">
              <div className="row shop-product-body mx-0">
                <div className="col spb-right-block">
                  <div className="row gap-4 justify-content-between h-100 spb-row">
                    <Link
                      to={"/personalized"}
                      className="col position-relative p-0 spb-left-block"
                      onMouseEnter={() => setHoveredBgColor("#E7E1D1")}
                      onMouseLeave={() => setHoveredBgColor("")}
                    >
                      <img
                        src={
                          "./images/web-images/Karv-Customize-Sticker-Banner-left.jpg"
                        }
                        alt=""
                      />
                      {/* <div className="position-absolute d-flex justify-content-center flex-column spb-left-img-content">
                        <div className="h3 fw-bold text-center text-uppercase mb-2">
                          Create Memories with Karv Accessories
                        </div>
                        <p className="text-center mb-3">
                          Exceptional Craftsmanship, Enduring Quality
                        </p>
                        <Link
                          to={"/product"}
                          className="fw-medium spb-left-row-link"
                        >
                          Shop Now
                        </Link>
                      </div> */}
                    </Link>
                    <Link
                      to={"/product"}
                      className="col position-relative p-0 spb-right-block"
                      onMouseEnter={() => setHoveredBgColor("#D8F0F0")}
                      onMouseLeave={() => setHoveredBgColor("")}
                    >
                      <img src={"./images/web-images/Karv-Customize-Sticker-Banner-Rightleft.jpg"} alt="" />
                      {/* <div className="position-absolute d-flex justify-content-center flex-column spb-right-img-content">
                        <div className="h3 fw-bold text-center text-uppercase mb-2">
                          Crafted with Precision
                        </div>
                        <p className="text-center mb-3">
                          Discover KARV, your premier destination for exquisite
                          jewelry boxes and stylish accessories
                        </p>
                        <Link
                          to={"/b2b"}
                          className="fw-medium spb-right-row-link"
                        >
                          Shop Now
                        </Link>
                      </div> */}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="sub-banner-block">
          <div className="inner-container">
            <div className="main-banner-img-block">
              <span></span>
              <div className="main-banner-img-content">
                <div className="h3">OUR NEW COLLECTIONS</div>
                <p>
                  Unveil the allure of KARV's latest collection, featuring an
                  exquisite range of <h1>jewelry boxes</h1>, premium leather
                  goods, and refined accessories. <br /> Our new line captures
                  the essence of contemporary luxury with a perfect blend of
                  classic design and innovative craftsmanship
                </p>
                <Link to={"/product"} className="main-banner-link">
                  Shop Now
                </Link>
              </div>
            </div>
          </div>
        </div>
        <ImageGallery />
        <div className="pb-5 client-logo">
          <div className="inner-container">
            <div className="client-logo-header">
              <div className="h3">Our Clients</div>
            </div>
            <div className="client-logo-body">
              <div className="client-logo-row">
                {homeClients.map((item, index) => {
                  return (
                    <div key={index} className="client-logo-block">
                      <img src={item} alt="" />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;
