import React, { createContext, useEffect, useState } from "react";

export const BtoBContext = createContext();

const BtoBProvider = ({ children }) => {
  const [allProducts, setAllProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(12);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(true);
  const [selectedFilters, setSelectedFilters] = useState([]);

  const fetchProducts = async (pageNo, pageSize, filters) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/product/search`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            pkProdId: "",
            prodName: "",
            prodCategory: filters.join(",") || "",
            prodType: "",
            price: "",
            pageNo: pageNo,
            pageSize: pageSize,
          }),
        }
      );
      if (!response.ok) {
        throw new Error("Failed to fetch products");
      }
      const data = await response.json();
      const totalResults = data.totalResult;
      const totalPages = Math.ceil(totalResults / pageSize);

      const productsWithSizes = data?.masterData?.map((product) => ({
        ...product,
        info: product?.info?.map((item) => ({
          ...item,
        })),
      }));
      setTotalPages(totalPages);
      return productsWithSizes;
    } catch (error) {
      console.error("Error fetching products:", error);
      return [];
    }
  };

  useEffect(() => {
    const fetchAllProducts = async () => {
      setLoading(true);
      try {
        const products = await fetchProducts(
          currentPage,
          postsPerPage,
          selectedFilters
        );
        setAllProducts(products);
      } catch (error) {
        console.error("Error fetching products:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchAllProducts();
  }, [currentPage, postsPerPage, selectedFilters]);

  const handleFilterChange = (filters) => {
    setSelectedFilters(filters);
  };

  return (
    <BtoBContext.Provider
      value={{
        allProducts,
        currentPage,
        setCurrentPage,
        postsPerPage,
        totalPages,
        loading,
        setLoading,
        setPostsPerPage,
        selectedFilters,
        setSelectedFilters: handleFilterChange,
      }}
    >
      {children}
    </BtoBContext.Provider>
  );
};

export default BtoBProvider;










// import React, { createContext, useEffect, useState } from "react";

// export const BtoBContext = createContext();

// const BtoBProvider = ({ children }) => {
//   const [allProducts, setAllProducts] = useState([]);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [postsPerPage, setPostsPerPage] = useState(9);
//   const [totalPages, setTotalPages] = useState(0);
//   const [loading, setLoading] = useState(true);
//   const [selectedFilters, setSelectedFilters] = useState([]);

//   const fetchProducts = async (pageNo, pageSize, filters) => {
//     try {
//       const response = await fetch(
//         `${process.env.REACT_APP_API_URL}/product/search`,
//         {
//           method: "POST",
//           headers: {
//             "Content-Type": "application/json",
//           },
//           body: JSON.stringify({
//             pkProdId: "",
//             prodName: "",
//             prodCategory: filters.join(",") || "",
//             prodType: "",
//             price: "",
//             pageNo: pageNo,
//             pageSize: pageSize,
//           }),
//         }
//       );
//       if (!response.ok) {
//         throw new Error("Failed to fetch products");
//       }
//       const data = await response.json();
//       const totalResults = data.totalResult;
//       const totalPages = Math.ceil(totalResults / pageSize);

//       const productsWithSizes = data?.masterData?.map((product) => ({
//         ...product,
//         info: product?.info?.map((item) => ({
//           ...item,
//         })),
//       }));
//       setTotalPages(totalPages);

//       return productsWithSizes;
//     } catch (error) {
//       console.error("Error fetching products:", error);
//       return [];
//     }
//   };

//   useEffect(() => {
//     const fetchAllProducts = async () => {
//       setLoading(true);
//       try {
//         const products = await fetchProducts(
//           currentPage,
//           postsPerPage,
//           selectedFilters
//         );
//         setAllProducts(products);
//       } catch (error) {
//         console.error("Error fetching products:", error);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchAllProducts();
//   }, [currentPage, postsPerPage, selectedFilters]);

//   const handleFilterChange = (filters) => {
//     setSelectedFilters(filters);
//   };

//   return (
//     <BtoBContext.Provider
//       value={{
//         allProducts,
//         currentPage,
//         setCurrentPage,
//         postsPerPage,
//         totalPages,
//         loading,
//         setLoading,
//         setPostsPerPage,
//         selectedFilters,
//         setSelectedFilters: handleFilterChange,
//       }}
//     >
//       {children}
//     </BtoBContext.Provider>
//   );
// };

// export default BtoBProvider;
