import React, { useState } from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

const images = [
  {
    default: "./images/img-gallery/img-gallery-1.jpg",
    hover: "./images/img-gallery/img-gallery-1-hover.jpg",
    bgColor: "#A65A38",
  },
  {
    default: "./images/img-gallery/img-gallery-2.jpg",
    hover: "./images/img-gallery/img-gallery-2-hover.jpg",
    bgColor: "#9194A7",
  },
  {
    default: "./images/img-gallery/img-gallery-3.jpg",
    hover: "./images/img-gallery/img-gallery-3-hover.jpg",
    bgColor: "#E8597C",
  },
  {
    default: "./images/img-gallery/img-gallery-4.jpg",
    hover: "./images/img-gallery/img-gallery-4-hover.jpg",
    bgColor: "#CA675B",
  },
  {
    default: "./images/img-gallery/img-gallery-5.jpg",
    hover: "./images/img-gallery/img-gallery-5-hover.jpg",
    bgColor: "#C2718A",
  },
  {
    default: "./images/img-gallery/img-gallery-6.jpg",
    hover: "./images/img-gallery/img-gallery-6-hover.jpg",
    bgColor: "#EEE3D0",
  },
];

const ImageGallery = () => {
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [hoveredBgColor, setHoveredBgColor] = useState("");

  return (
    <div
      className="py-5 customize-your-product"
      style={{ backgroundColor: hoveredBgColor }}
    >
      <div className="inner-container px-5">
        <div className="image-grid">
          {images.map((image, index) => (
            <div
              className="img-g-block"
              key={index}
              onMouseEnter={() => setHoveredBgColor(image.bgColor)}
              onMouseLeave={() => setHoveredBgColor("")}
            >
              <img
                src={image.default}
                alt={`Gallery Image ${index + 1}`}
                className="default"
                onClick={() => {
                  setPhotoIndex(index);
                  setIsOpen(true);
                }}
              />
              <img
                src={image.hover}
                alt={`Gallery Image ${index + 1} Hover`}
                className="hover"
                onClick={() => {
                  setPhotoIndex(index);
                  setIsOpen(true);
                }}
              />
            </div>
          ))}
        </div>
        {isOpen && (
          <Lightbox
            mainSrc={images[photoIndex].default}
            nextSrc={images[(photoIndex + 1) % images.length].default}
            prevSrc={
              images[(photoIndex + images.length - 1) % images.length].default
            }
            onCloseRequest={() => setIsOpen(false)}
            onMovePrevRequest={() =>
              setPhotoIndex((photoIndex + images.length - 1) % images.length)
            }
            onMoveNextRequest={() =>
              setPhotoIndex((photoIndex + 1) % images.length)
            }
          />
        )}
      </div>
    </div>
  );
};

export default ImageGallery;
