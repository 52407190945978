import React, { useContext, useEffect, useState } from "react";
import Breadcrumb from "../../components/Breadcrumb";
import Product from "../../components/Product";
import Loading from "../../components/Loading";
import { ProductContext } from "../../contexts/ProductContext";
import axios from "axios";
import MetaDescTag from "../../components/MetaDescTag";

const Products = () => {
  const {
    allProducts,
    loading,
    currentPage,
    setCurrentPage,
    postsPerPage,
    totalPages,
    selectedType,
    selectedFilters,
    setSelectedFilters,
  } = useContext(ProductContext);

  const [filterType, setFilterType] = useState([]);
  const [loadingFilters, setLoadingFilters] = useState(true);

  // Fetch filter categories from the API
  const fetchFilterType = async (type) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/product/productCategoryList`,
        { prodType: type }
      );
      if (response.data && response.data.option) {
        setFilterType(response.data.option);
      }
      setLoadingFilters(false);
    } catch (error) {
      console.error("Error fetching filter categories:", error);
      setLoadingFilters(false);
    }
  };

  // Reset the current page and fetch filter types when the component mounts or selectedType changes
  useEffect(() => {
    setCurrentPage(1);
    fetchFilterType(selectedType);
  }, [setCurrentPage, selectedType]);

  // Handle filter change
  const handleFilterChange = (e) => {
    const { value, checked } = e.target;
    setSelectedFilters((prev) =>
      checked ? [...prev, value] : prev.filter((filter) => filter !== value)
    );
  };

  // Handle page change
  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  return (
    <>
      <MetaDescTag
        title={"Products | Jewelry Boxes | KARV Accessories"}
        description={
          "Welcome to Karv Accessories, where passion, craftsmanship, and style unite to create exquisite leather products. Founded by the dynamic duo, Karishma and Vikram, our journey began with a shared vision to redefine leather goods and accessories."
        }
      />
      <section className="product-page">
        {/* <Breadcrumb title="Product" /> */}
        <div className="py-5 product-page-block">
          <div className="our-container">
            <div className="inner-container px-5 mx-auto">
              <div className="pb-5 product-page-header">
                <h1 className="fs-1 fw-medium text-capitalize mb-2">
                  Products
                </h1>
              </div>
              <div className="product-page-body">
                {loading ? (
                  <Loading />
                ) : (
                  <>
                    <div className="filter-section">
                      <p className="mb-2">Filters</p>
                      {loadingFilters ? (
                        <Loading />
                      ) : (
                        <div className="filter-checkboxes">
                          {filterType.map((item, index) => (
                            <div className="form-check" key={index}>
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value={item}
                                id={`flexCheckDefault-${index}`}
                                checked={selectedFilters.includes(item)}
                                onChange={handleFilterChange}
                              />
                              <label
                                className="form-check-label"
                                htmlFor={`flexCheckDefault-${index}`}
                              >
                                {item}
                              </label>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                    <div>
                      <div className="d-grid gap-3 product-page-row">
                        {allProducts?.map((product) => (
                          <Product key={product?.pkProdId} product={product} />
                        ))}
                      </div>
                      <div className="mt-5 product-page-pagination">
                        <button
                          onClick={() => handlePageChange(currentPage - 1)}
                          disabled={currentPage === 1}
                          className={`pagination-disable-btn ${
                            currentPage === 1 ? "prev-button-disabled" : ""
                          }`}
                        >
                          Previous
                        </button>
                        <button
                          onClick={() => handlePageChange(currentPage + 1)}
                          disabled={currentPage === totalPages}
                          className={`pagination-next-btn ${
                            currentPage === totalPages
                              ? "next-button-disabled"
                              : ""
                          }`}
                        >
                          Next
                        </button>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Products;

// import React, { useContext, useEffect, useState } from "react";
// import Breadcrumb from "../../components/Breadcrumb";
// import Product from "../../components/Product";
// import Loading from "../../components/Loading";
// import { ProductContext } from "../../contexts/ProductContext";
// import axios from "axios";

// const Products = () => {
//   const {
//     allProducts,
//     loading,
//     currentPage,
//     setCurrentPage,
//     postsPerPage,
//     totalPages,
//     selectedType,
//     setAllProducts,
//     setTotalPages,
//     setLoading
//   } = useContext(ProductContext);

//   const [filterType, setFilterType] = useState([]);
//   const [selectedFilters, setSelectedFilters] = useState([]);
//   const [loadingFilters, setLoadingFilters] = useState(true);

//   // Fetch filter categories from the API
//   const fetchFilterType = async (type) => {
//     try {
//       const response = await axios.post(
//         `${process.env.REACT_APP_API_URL}/product/productCategoryList`,
//         { prodType: type }
//       );
//       if (response.data && response.data.option) {
//         setFilterType(response.data.option);
//       }
//       setLoadingFilters(false);
//     } catch (error) {
//       console.error("Error fetching filter categories:", error);
//       setLoadingFilters(false);
//     }
//   };

//   // Fetch filtered products from the API
//   const fetchFilteredProducts = async (pageNo, pageSize, prodType, filters) => {
//     try {
//       setLoading(true);
//       const response = await fetch(
//         `${process.env.REACT_APP_API_URL}/product/search`,
//         {
//           method: "POST",
//           headers: {
//             "Content-Type": "application/json",
//           },
//           body: JSON.stringify({
//             pkProdId: "",
//             prodName: "",
//             prodCategory: filters.join(",") || "",
//             prodType: prodType,
//             price: "",
//             pageNo: pageNo,
//             pageSize: pageSize,
//           }),
//         }
//       );
//       if (!response.ok) {
//         throw new Error("Failed to fetch products");
//       }
//       const data = await response.json();
//       const totalResults = data.totalResult;
//       const totalPages = Math.ceil(totalResults / pageSize);

//       const productsWithSizes = data?.masterData?.map((product) => ({
//         ...product,
//         info: product?.info?.map((item) => ({ ...item })),
//       }));

//       setAllProducts(productsWithSizes);
//       setTotalPages(totalPages);
//     } catch (error) {
//       console.error("Error fetching filtered products:", error);
//     } finally {
//       setLoading(false);
//     }
//   };

//   // Reset the current page and fetch filter types when the component mounts or selectedType changes
//   useEffect(() => {
//     setCurrentPage(1);
//     fetchFilterType(selectedType);
//   }, [setCurrentPage, selectedType]);

//   // Fetch filtered products when filters or pagination change
//   useEffect(() => {
//     fetchFilteredProducts(currentPage, postsPerPage, selectedType, selectedFilters);
//   }, [selectedFilters, currentPage, postsPerPage, selectedType]);

//   // Handle filter change
//   const handleFilterChange = (e) => {
//     const { value, checked } = e.target;
//     setSelectedFilters((prev) =>
//       checked ? [...prev, value] : prev.filter((filter) => filter !== value)
//     );
//   };

//   // Handle page change
//   const handlePageChange = (newPage) => {
//     if (newPage > 0 && newPage <= totalPages) {
//       setCurrentPage(newPage);
//     }
//   };

//   return (
//     <>
//       <section className="product-page">
//         <Breadcrumb title="Product" />
//         <div className="py-5 product-page-block">
//           <div className="our-container">
//             <div className="inner-container px-5 mx-auto">
//               <div className="pb-5 product-page-header">
//                 <h1 className="fs-1 fw-medium text-capitalize mb-2">
//                   Products
//                 </h1>
//               </div>
//               <div className="product-page-body">
//                 {loading ? (
//                   <Loading />
//                 ) : (
//                   <>
//                     <div className="filter-section">
//                       <p className="mb-2">Filters</p>
//                       {loadingFilters ? (
//                         <Loading />
//                       ) : (
//                         <div className="filter-checkboxes">
//                           {filterType.map((item, index) => (
//                             <div className="form-check" key={index}>
//                               <input
//                                 className="form-check-input"
//                                 type="checkbox"
//                                 value={item}
//                                 id={`flexCheckDefault-${index}`}
//                                 checked={selectedFilters.includes(item)}
//                                 onChange={handleFilterChange}
//                               />
//                               <label
//                                 className="form-check-label"
//                                 htmlFor={`flexCheckDefault-${index}`}
//                               >
//                                 {item}
//                               </label>
//                             </div>
//                           ))}
//                         </div>
//                       )}
//                     </div>
//                     <div>
//                       <div className="d-grid gap-3 product-page-row">
//                         {allProducts?.map((product) => (
//                           <Product key={product?.pkProdId} product={product} />
//                         ))}
//                       </div>
//                       <div className="mt-5 product-page-pagination">
//                         <button
//                           onClick={() => handlePageChange(currentPage - 1)}
//                           disabled={currentPage === 1}
//                           className={`pagination-disable-btn ${
//                             currentPage === 1 ? "prev-button-disabled" : ""
//                           }`}
//                         >
//                           Previous
//                         </button>
//                         <button
//                           onClick={() => handlePageChange(currentPage + 1)}
//                           disabled={currentPage === totalPages}
//                           className={`pagination-next-btn ${
//                             currentPage === totalPages
//                               ? "next-button-disabled"
//                               : ""
//                           }`}
//                         >
//                           Next
//                         </button>
//                       </div>
//                     </div>
//                   </>
//                 )}
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>
//     </>
//   );
// };

// export default Products;

// {/* <ul className="p-0 m-0">
//                           {filterType.map((item, index) => (
//                             <li key={index}>
//                               <input
//                                 type="checkbox"
//                                 name="categoryCheckBox"
//                                 value={item}
//                                 checked={selectedFilters.includes(item)}
//                                 onChange={handleFilterChange}
//                                 className="me-1"
//                               />
//                               <label htmlFor="categoryCheckBox">{item}</label>
//                             </li>
//                           ))}
//                         </ul> */}

// // import React, { useContext, useEffect, useState } from "react";
// // import Breadcrumb from "../../components/Breadcrumb";
// // import Product from "../../components/Product";
// // import Loading from "../../components/Loading";
// // import { ProductContext } from "../../contexts/ProductContext";
// // import axios from "axios";

// // const Products = () => {
// //   const {
// //     allProducts,
// //     loading,
// //     currentPage,
// //     setCurrentPage,
// //     postsPerPage,
// //     totalPages,
// //     selectedType,
// //     setAllProducts,
// //     setTotalPages,
// //     setLoading
// //   } = useContext(ProductContext);

// //   const [filterType, setFilterType] = useState([]);
// //   const [selectedFilters, setSelectedFilters] = useState([]);
// //   const [loadingFilters, setLoadingFilters] = useState(true);

// //   // Fetch filter categories from the API
// //   const fetchFilterType = async (type) => {
// //     try {
// //       const response = await axios.post(
// //         `${process.env.REACT_APP_API_URL}/product/productCategoryList`,
// //         { prodType: type }
// //       );
// //       if (response.data && response.data.option) {
// //         setFilterType(response.data.option);
// //       }
// //       setLoadingFilters(false);
// //     } catch (error) {
// //       console.error("Error fetching filter categories:", error);
// //       setLoadingFilters(false);
// //     }
// //   };

// //   // Fetch filtered products from the API
// //   const fetchFilteredProducts = async (pageNo, pageSize, prodType, filters) => {
// //     try {
// //       setLoading(true);
// //       const response = await fetch(
// //         `${process.env.REACT_APP_API_URL}/product/search`,
// //         {
// //           method: "POST",
// //           headers: {
// //             "Content-Type": "application/json",
// //           },
// //           body: JSON.stringify({
// //             pkProdId: "",
// //             prodName: "",
// //             prodCategory: filters.join(",") || "",
// //             prodType: prodType,
// //             price: "",
// //             pageNo: pageNo,
// //             pageSize: pageSize,
// //           }),
// //         }
// //       );
// //       if (!response.ok) {
// //         throw new Error("Failed to fetch products");
// //       }
// //       const data = await response.json();
// //       const totalResults = data.totalResult;
// //       const totalPages = Math.ceil(totalResults / pageSize);

// //       const productsWithSizes = data?.masterData?.map((product) => ({
// //         ...product,
// //         info: product?.info?.map((item) => ({ ...item })),
// //       }));

// //       setAllProducts(productsWithSizes);
// //       setTotalPages(totalPages);
// //     } catch (error) {
// //       console.error("Error fetching filtered products:", error);
// //     } finally {
// //       setLoading(false);
// //     }
// //   };

// //   // Reset the current page and fetch filter types when the component mounts or selectedType changes
// //   useEffect(() => {
// //     setCurrentPage(1);
// //     fetchFilterType(selectedType);
// //   }, [setCurrentPage, selectedType]);

// //   // Fetch filtered products when filters or pagination change
// //   useEffect(() => {
// //     fetchFilteredProducts(currentPage, postsPerPage, selectedType, selectedFilters);
// //   }, [selectedFilters, currentPage, postsPerPage, selectedType]);

// //   // Handle filter change
// //   const handleFilterChange = (e) => {
// //     const { value, checked } = e.target;
// //     setSelectedFilters((prev) =>
// //       checked ? [...prev, value] : prev.filter((filter) => filter !== value)
// //     );
// //   };

// //   // Handle page change
// //   const handlePageChange = (newPage) => {
// //     if (newPage > 0 && newPage <= totalPages) {
// //       setCurrentPage(newPage);
// //     }
// //   };

// //   return (
// //     <>
// //       <section className="product-page">
// //         <Breadcrumb title="Product" />
// //         <div className="py-5 product-page-block">
// //           <div className="our-container">
// //             <div className="inner-container px-5 mx-auto">
// //               <div className="pb-5 product-page-header">
// //                 <h1 className="fs-1 fw-medium text-capitalize mb-2">
// //                   Products
// //                 </h1>
// //               </div>
// //               <div className="product-page-body">
// //                 {loading ? (
// //                   <Loading />
// //                 ) : (
// //                   <>
// //                     <div className="filter-section">
// //                       <p className="mb-2">Filters</p>
// //                       {loadingFilters ? (
// //                         <Loading />
// //                       ) : (
// //                         <ul className="p-0 m-0">
// //                           {filterType.map((item, index) => (
// //                             <li key={index}>
// //                               <input
// //                                 type="checkbox"
// //                                 name="categoryCheckBox"
// //                                 value={item}
// //                                 checked={selectedFilters.includes(item)}
// //                                 onChange={handleFilterChange}
// //                                 className="me-1"
// //                               />
// //                               {item}
// //                             </li>
// //                           ))}
// //                         </ul>
// //                       )}
// //                     </div>
// //                     <div>
// //                       <div className="d-grid gap-3 product-page-row">
// //                         {allProducts?.map((product) => (
// //                           <Product key={product?.pkProdId} product={product} />
// //                         ))}
// //                       </div>
// //                       <div className="mt-5 product-page-pagination">
// //                         <button
// //                           onClick={() => handlePageChange(currentPage - 1)}
// //                           disabled={currentPage === 1}
// //                           className={`pagination-disable-btn ${
// //                             currentPage === 1 ? "prev-button-disabled" : ""
// //                           }`}
// //                         >
// //                           Previous
// //                         </button>
// //                         <button
// //                           onClick={() => handlePageChange(currentPage + 1)}
// //                           disabled={currentPage === totalPages}
// //                           className={`pagination-next-btn ${
// //                             currentPage === totalPages
// //                               ? "next-button-disabled"
// //                               : ""
// //                           }`}
// //                         >
// //                           Next
// //                         </button>
// //                       </div>
// //                     </div>
// //                   </>
// //                 )}
// //               </div>
// //             </div>
// //           </div>
// //         </div>
// //       </section>
// //     </>
// //   );
// // };

// // export default Products;

// // import React, { useContext, useEffect, useState } from "react";
// // import Breadcrumb from "../../components/Breadcrumb";
// // import Product from "../../components/Product";
// // import Loading from "../../components/Loading";
// // import { ProductContext } from "../../contexts/ProductContext";
// // import axios from "axios";

// // const Products = () => {
// //   const {
// //     allProducts,
// //     loading,
// //     currentPage,
// //     setCurrentPage,
// //     postsPerPage,
// //     totalPages,
// //     selectedType,
// //     setSelectedType,
// //   } = useContext(ProductContext);

// //   const [filterType, setFilterType] = useState([]);
// //   const [selectedFilters, setSelectedFilters] = useState([]);
// //   const [loadingFilters, setLoadingFilters] = useState(true);

// //   // Fetch filter categories from the API
// //   const fetchFilterType = async (type) => {
// //     try {
// //       const response = await axios.post(
// //         `${process.env.REACT_APP_API_URL}/product/productCategoryList`,
// //         {
// //           prodType: type,
// //         }
// //       );
// //       if (response.data && response.data.option) {
// //         setFilterType(response.data.option);
// //       }
// //       setLoadingFilters(false);
// //     } catch (error) {
// //       console.error("Error fetching filter categories:", error);
// //       setLoadingFilters(false);
// //     }
// //   };

// //   // Reset the current page to 1 when the component mounts
// //   useEffect(() => {
// //     setCurrentPage(1);
// //     fetchFilterType(selectedType);
// //   }, [setCurrentPage, selectedType]);

// //   useEffect(() => {
// //     const fetchFilteredProducts = async () => {
// //       setLoading(true);
// //       try {
// //         const products = await fetchProducts(
// //           currentPage,
// //           postsPerPage,
// //           selectedType,
// //           selectedFilters
// //         );
// //         setAllProducts(products);
// //       } catch (error) {
// //         console.error("Error fetching filtered products:", error);
// //       } finally {
// //         setLoading(false);
// //       }
// //     };

// //     fetchFilteredProducts();
// //   }, [selectedFilters, currentPage, postsPerPage, selectedType]);

// //   // Handle filter change
// //   const handleFilterChange = (e) => {
// //     const { value, checked } = e.target;
// //     setSelectedFilters((prev) =>
// //       checked ? [...prev, value] : prev.filter((filter) => filter !== value)
// //     );
// //   };

// //   // Handle event change on pagination
// //   const handlePageChange = (newPage) => {
// //     if (newPage > 0 && newPage <= totalPages) {
// //       setCurrentPage(newPage);
// //     }
// //   };

// //   return (
// //     <>
// //       <section className="product-page">
// //         <Breadcrumb title="Product" />
// //         <div className="py-5 product-page-block">
// //           <div className="our-container">
// //             <div className="inner-container px-5 mx-auto">
// //               <div className="pb-5 product-page-header">
// //                 <h1 className="fs-1 fw-medium text-capitalize mb-2">
// //                   Products
// //                 </h1>
// //               </div>
// //               <div className="product-page-body">
// //                 {loading ? (
// //                   <Loading />
// //                 ) : (
// //                   <>
// //                     <div className="filter-section">
// //                       <p className="mb-2">Filters</p>
// //                       {loadingFilters ? (
// //                         <Loading />
// //                       ) : (
// //                         <ul className="p-0 m-0">
// //                           {filterType.map((item, index) => (
// //                             <li key={index}>
// //                               <input
// //                                 type="checkbox"
// //                                 name="categoryCheckBox"
// //                                 value={item}
// //                                 onChange={handleFilterChange}
// //                                 className="me-1"
// //                               />
// //                               {item}
// //                             </li>
// //                           ))}
// //                         </ul>
// //                       )}
// //                     </div>
// //                     <div>
// //                       <div className="d-grid gap-3 product-page-row">
// //                         {allProducts?.map((product) => (
// //                           <Product key={product?.pkProdId} product={product} />
// //                         ))}
// //                       </div>
// //                       <div className="mt-5 product-page-pagination">
// //                         <button
// //                           onClick={() => handlePageChange(currentPage - 1)}
// //                           disabled={currentPage === 1}
// //                           className={`pagination-disable-btn ${
// //                             currentPage === 1 ? "prev-button-disabled" : ""
// //                           }`}
// //                         >
// //                           Previous
// //                         </button>
// //                         <button
// //                           onClick={() => handlePageChange(currentPage + 1)}
// //                           disabled={currentPage === totalPages}
// //                           className={`pagination-next-btn ${
// //                             currentPage === totalPages
// //                               ? "next-button-disabled"
// //                               : ""
// //                           }`}
// //                         >
// //                           Next
// //                         </button>
// //                       </div>
// //                     </div>
// //                   </>
// //                 )}
// //               </div>
// //             </div>
// //           </div>
// //         </div>
// //       </section>
// //     </>
// //   );
// // };

// // export default Products;

// // // import React, { useContext, useEffect, useState } from "react";
// // // import Breadcrumb from "../../components/Breadcrumb";
// // // import Product from "../../components/Product";
// // // import Loading from "../../components/Loading";
// // // import { ProductContext } from "../../contexts/ProductContext";
// // // // import { filterCategory } from "../../data";
// // // import axios from "axios";

// // // const Products = () => {
// // //   const {
// // //     allProducts,
// // //     loading,
// // //     currentPage,
// // //     setCurrentPage,
// // //     postsPerPage,
// // //     totalPages,
// // //     productCategories,
// // //   } = useContext(ProductContext);

// // //   const [filterCategories, setFilterCategories] = useState([]);
// // //   const [loadingFilters, setLoadingFilters] = useState(true);

// // //   // Fetch filter categories from the API
// // //   const fetchFilterCategories = async () => {
// // //     try {
// // //       const response = await axios.post(
// // //         `${process.env.REACT_APP_API_URL}/product/productCategoryList`,
// // //         {
// // //           prodType: "Non-Personalized",
// // //         }
// // //       );
// // //       setFilterCategories(response.data);
// // //       setLoadingFilters(false);
// // //     } catch (error) {
// // //       console.error("Error fetching filter categories:", error);
// // //       setLoadingFilters(false);
// // //     }
// // //   };

// // //   // Reset the current page to 1 when the component mounts
// // //   useEffect(() => {
// // //     setCurrentPage(1);
// // //     fetchFilterCategories();
// // //   }, [setCurrentPage]);

// // //   // Handle event change on pagination
// // //   const handlePageChange = (newPage) => {
// // //     if (newPage > 0 && newPage <= totalPages) {
// // //       setCurrentPage(newPage);
// // //     }
// // //   };

// // //   // // Reset the current page to 1 when the component mounts
// // //   // useEffect(() => {
// // //   //   setCurrentPage(1);
// // //   // }, [setCurrentPage]);

// // //   // // handle event change on pagination
// // //   // const handlePageChange = (newPage) => {
// // //   //   if (newPage > 0 && newPage <= totalPages) {
// // //   //     setCurrentPage(newPage);
// // //   //   }
// // //   // };

// // //   return (
// // //     <>
// // //       <section className="product-page">
// // //         <Breadcrumb title="Product" />
// // //         <div className="py-5 product-page-block">
// // //           <div className="our-container">
// // //             <div className="inner-container px-5 mx-auto">
// // //               <div className="pb-5 product-page-header">
// // //                 <h1 className="fs-1 fw-medium text-capitalize mb-2">
// // //                   Products
// // //                 </h1>
// // //               </div>
// // //               <div className="product-page-body">
// // //                 {loading ? (
// // //                   <Loading />
// // //                 ) : (
// // //                   <>
// // //                     <div className="filter-section">
// // //                       <p className="mb-2">Filters</p>
// // //                       <ul className="p-0 m-0">
// // //                         {filterCategories.map((item, index) => {
// // //                           return <li key={index}>{item.title}</li>;
// // //                         })}
// // //                       </ul>
// // //                     </div>
// // //                     <div>
// // //                       <div className="d-grid gap-3 product-page-row">
// // //                         {allProducts?.map((product) => (
// // //                           <Product key={product?.pkProdId} product={product} />
// // //                         ))}
// // //                       </div>
// // //                       <div className="mt-5 product-page-pagination">
// // //                         <button
// // //                           onClick={() => handlePageChange(currentPage - 1)}
// // //                           disabled={currentPage === 1}
// // //                           className={`pagination-disable-btn ${
// // //                             currentPage === 1 ? "prev-button-disabled" : ""
// // //                           }`}
// // //                         >
// // //                           Previous
// // //                         </button>
// // //                         <button
// // //                           onClick={() => handlePageChange(currentPage + 1)}
// // //                           disabled={currentPage === totalPages}
// // //                           className={`pagination-next-btn ${
// // //                             currentPage === totalPages
// // //                               ? "next-button-disabled"
// // //                               : ""
// // //                           }`}
// // //                         >
// // //                           Next
// // //                         </button>
// // //                       </div>
// // //                     </div>
// // //                   </>
// // //                 )}
// // //               </div>
// // //             </div>
// // //           </div>
// // //         </div>
// // //       </section>
// // //     </>
// // //   );
// // // };

// // // export default Products;
