import React from "react";

const Loading = () => {
  return (
    <>
      <main className="d-flex align-items-center justify-content-center spinner-examples">
        <div className="example">
          <span className="smooth spinner" />
        </div>
      </main>
    </>
  );
};

export default Loading;
