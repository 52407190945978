import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Breadcrumb from "../../components/Breadcrumb";
import {
  FaIndianRupeeSign,
  FaShareFromSquare,
  FaMinus,
  FaPlus,
} from "react-icons/fa6";
import {
  FaFacebookSquare,
  FaInstagram,
  FaWhatsappSquare,
  FaCopy,
} from "react-icons/fa";
import { MdArrowBackIos } from "react-icons/md";
import Loading from "../../components/Loading";
import { suggestedProductList, zodiacData } from "../../data";
import { CartContext } from "../../contexts/CartContext";
import { ProductContext } from "../../contexts/ProductContext";
import { toast } from "react-toastify";

const SingleProduct = () => {
  const { getProductDetails, getSuggestedProducts, selectedCategory } =
    useContext(ProductContext);
  const { addToCart } = useContext(CartContext);

  const { id } = useParams();

  const navigate = useNavigate();

  const [productDetails, setProductDetails] = useState(null);
  const [selectedColor, setSelectedColor] = useState(null);
  const [mainImage, setMainImage] = useState(null);
  const [showShareOptions, setShowShareOptions] = useState(false);
  const [isZoomed, setIsZoomed] = useState(false);
  const [zoomPosition, setZoomPosition] = useState({ x: 0, y: 0 });
  const [suggestedProducts, setSuggestedProducts] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [selectedZodiacSign, setSelectedZodiacSign] = useState("");
  const [customizeBtn, setCustomizeBtn] = useState(false);
  const [currentCustomizingImage, setCurrentCustomizingImage] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [totalPrice, setTotalPrice] = useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 600);

  // Color code to conditionally change text color
  const textColor = selectedColor?.imageColor === "#000000" && "#FFFFFF";

  // Find the Zodiac sign object based on the selected sign
  const zodiacSign = zodiacData.find(
    (zodiac) => zodiac.sign === selectedZodiacSign
  );

  // Function to get the appropriate zodiac image URL
  const zodiacImageUrl =
    selectedColor?.imageColor === "#000000"
      ? zodiacSign?.imageWhiteUrl
      : zodiacSign?.imageUrl;

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 600);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const product = getProductDetails(id);
    setProductDetails(product);
    if (product && product?.info?.length > 0) {
      setSelectedColor(product?.info[0]);
      setMainImage(product?.info[0]?.imagePath[0]);
    }

    const fetchSuggestedProducts = async () => {
      const suggestions = await getSuggestedProducts(id);
      setSuggestedProducts(suggestions);
    };

    fetchSuggestedProducts();
  }, [id, getProductDetails, getSuggestedProducts]);

  useEffect(() => {
    if (productDetails) {
      // Calculate base price
      const basePrice = parseFloat(productDetails.prodPrice);

      // Calculate suggested products total price
      const suggestedTotalPrice = suggestedProducts.reduce(
        (total, product) => total + parseFloat(product.price),
        0
      );

      // Calculate total price including quantity
      const calculatedTotalPrice = (basePrice + suggestedTotalPrice) * quantity;

      // Update state with calculated total price
      setTotalPrice(calculatedTotalPrice);
    }
  }, [productDetails, suggestedProducts, quantity]);

  if (!productDetails) return <Loading />;

  // Current page URL
  const productUrl = window.location.href;

  // Color change onEvent for Image
  const handleColorChange = (color) => {
    setSelectedColor(color);
    setMainImage(color?.imagePath[0]);
    setShowShareOptions(false);
  };

  const handleImageClick = (image) => {
    setMainImage(image);
    setInputValue("");
    setSelectedZodiacSign("");
    setCurrentCustomizingImage(image);
    setCustomizeBtn(false);
    setShowShareOptions(false);
  };

  const handleFacebookShare = () => {
    const facebookUrl = `https://www.facebook.com/people/Karv-Accessories/61556072433937/?u=${encodeURIComponent(
      productUrl
    )}`;
    window.open(facebookUrl, "_blank");
  };

  const handleInstagramShare = () => {
    const instagramUrl = `https://www.instagram.com/karvaccessories.official/?url=${encodeURIComponent(
      productUrl
    )}`;
    window.open(instagramUrl, "_blank");
  };

  const handleWhatsappShare = () => {
    const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
      productUrl
    )}`;
    window.open(whatsappUrl, "_blank");
  };

  const handleCopyLink = () => {
    navigator.clipboard.writeText(productUrl).then(
      () => {
        toast.success("Link copied to clipboard!", {
          autoClose: 1000,
          position: "bottom-right",
        });
      },
      (err) => {
        toast.error(err, {
          autoClose: 1000,
          position: "bottom-right",
        });
      }
    );
  };

  const handleMouseMove = (e) => {
    const { left, top, width, height } = e.target.getBoundingClientRect();
    const x = (e.clientX - left) / width;
    const y = (e.clientY - top) / height;
    setZoomPosition({ x, y });
  };

  const zodiacArray = Object.entries(zodiacData).map(([sign, data]) => ({
    sign,
    ...data,
  }));

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleSelectChange = (sign) => {
    setSelectedZodiacSign(sign);
  };

  const handleDropdownChange = (event) => {
    setSelectedZodiacSign(event.target.value);
  };

  // Event toggle for customize btn
  const handleCustomizeBtn = () => {
    setCustomizeBtn(!customizeBtn);
    if (selectedColor?.imagePath?.length > 0) {
      const lastImage =
        selectedColor.imagePath[selectedColor.imagePath.length - 1];
      setMainImage(lastImage);
      setCurrentCustomizingImage(lastImage);
      setShowShareOptions(false);
    }
  };

  const handleAddToCart = () => {
    addToCart(productDetails, selectedColor, quantity, {
      inputValue,
      selectedZodiacSign,
    });
    toast.success("Added to Bag", {
      autoClose: 1000,
      position: "bottom-right",
    });
  };

  const handleBuyNow = () => {
    const productData = {
      prodName: productDetails.prodName,
      prodBrand: productDetails.prodBrand,
      prodPrice: productDetails.prodPrice,
      prodDesc: productDetails.prodDesc,
      isCustomized: productDetails.isCustomized,
      isActive: productDetails.isActive,
      pkProdId: productDetails.pkProdId,
      selectedColor: {
        imageColor: selectedColor.imageColor,
        imageName: selectedColor.imageName,
        productName: selectedColor.productName,
        isActive: selectedColor.isActive,
        imagePath: selectedColor.imagePath,
        pkImgId: selectedColor.pkImgId,
      },
      quantity: quantity,
      customization: {
        inputValue: inputValue,
        selectedZodiacSign: selectedZodiacSign,
      },
      id: `${productDetails.pkProdId}-${selectedColor.imageColor}-{"inputValue":"${inputValue}","selectedZodiacSign":"${selectedZodiacSign}"}`,
    };

    navigate("/checkout", {
      state: {
        productDetails: [productData],
        amount: totalPrice,
      },
    });
  };

  const handleBackClick = () => {
    navigate(-1);
  };

  return (
    <>
      <section className="product-single-page">
        {/* <Breadcrumb title="Product" productId={`/${id}`} /> */}
        <div className="py-5 product-single-page-block">
          <div className="our-container">
            <div className="inner-container px-5 mx-auto">
              <button
                onClick={handleBackClick}
                className="back-btn-per-page mb-3"
              >
                <MdArrowBackIos className="back-icon-per-page" />
                Back
              </button>
              <div className="row product-single-page-block-row">
                <div className="col-md-6 mb-3">
                  <div className="row gap-3 px-3 main-row-for-product-single-page">
                    <div className="col-md-2 d-flex align-items-start justify-content-center product-single-page-img-grp">
                      <div className="row gap-3 product-single-page-img-group">
                        {selectedColor?.imagePath?.map((image, index) => (
                          <img
                            key={index}
                            src={image}
                            alt={`Option ${index + 1}`}
                            onClick={() => handleImageClick(image)}
                            className={`thumbnail ${
                              mainImage === image ? "active" : ""
                            }`}
                          />
                        ))}
                      </div>
                    </div>
                    <div className="col-md-9 d-flex align-items-center justify-content-center position-relative product-single-page-main-img">
                      <img
                        src={mainImage}
                        onClick={() => setShowShareOptions(false)}
                        alt="Main"
                      />
                      {currentCustomizingImage === mainImage && (
                        <>
                          <div className="product-single-page-main-image-current-details">
                            <span
                              className="text-capitalize"
                              style={{ color: textColor }}
                            >
                              {inputValue}
                            </span>
                          </div>
                          <div className="zodiacs-flower-upper-image">
                            {selectedZodiacSign && (
                              <img
                                // src={
                                //   zodiacArray.find(
                                //     (sign) => sign.sign === selectedZodiacSign
                                //   )?.imageUrl
                                // }
                                // alt={selectedZodiacSign}
                                src={zodiacImageUrl}
                                alt={selectedZodiacSign}
                              />
                            )}
                          </div>
                        </>
                      )}
                      <div className="mt-2 position-absolute share-button-product">
                        <p className="mb-0">
                          <FaShareFromSquare
                            className="fs-4 share-button-icon mb-2"
                            size={25}
                            onClick={() => setShowShareOptions((prev) => !prev)}
                          />
                          {showShareOptions && (
                            <span className="d-flex align-items-center justify-content-between gap-3 flex-column share-button-options">
                              <FaFacebookSquare
                                size={25}
                                onClick={handleFacebookShare}
                                className="product-share-icon-all"
                              />
                              <FaInstagram
                                size={25}
                                onClick={handleInstagramShare}
                                className="product-share-icon-all"
                              />
                              <FaWhatsappSquare
                                size={25}
                                onClick={handleWhatsappShare}
                                className="product-share-icon-all"
                              />
                              <FaCopy
                                size={25}
                                onClick={handleCopyLink}
                                className="product-share-icon-all"
                              />
                            </span>
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="product-single-page-details">
                    <h1 className="fs-4 fw-medium mb-3">
                      {productDetails?.prodName}
                    </h1>
                    <div className="d-flex align-items-center mb-3 gap-2 product-price">
                      <FaIndianRupeeSign className="fs-6 fw-medium mb-0 price-icon" />
                      <div className="h3 fs-5 fw-medium mb-0">
                        {productDetails?.prodPrice}
                      </div>
                    </div>
                    <div className="my-4 product-single-page-details-fields">
                      <div className="mb-3 d-flex justify-content-center flex-column product-single-page-form-group">
                        {productDetails?.info?.some(
                          (color) => color?.imageColor
                        ) && (
                          <>
                            <label
                              htmlFor="size"
                              className="fs-6 text-capitalize mb-1 fw-medium"
                            >
                              Colors
                            </label>
                            <div className="row justify-content-start gap-3 m-0 product-single-page-colors-group">
                              {productDetails?.info?.map((color, index) => (
                                <div
                                  key={index}
                                  onClick={() => handleColorChange(color)}
                                  className={`col fs-6 text-uppercase radio-btn-color ${
                                    selectedColor?.imageColor ===
                                    color?.imageColor
                                      ? "active"
                                      : ""
                                  }`}
                                  style={{
                                    backgroundColor: color.imageColor,
                                    color: "#ffffff",
                                    border:
                                      selectedColor?.imageColor ===
                                      color?.imageColor
                                        ? "2px solid #000"
                                        : "none",
                                  }}
                                ></div>
                              ))}
                            </div>
                          </>
                        )}
                      </div>
                      <div className="mb-3 d-flex justify-content-center flex-column product-single-page-form-group">
                        <label
                          htmlFor="size"
                          className="fs-6 text-capitalize mb-1 fw-medium"
                        >
                          Quantity
                        </label>
                        <div className="row justify-content-start gap-3 m-0 product-single-page-quantity">
                          <div className="quantity-block">
                            <button
                              className="quantity-btn"
                              onClick={() =>
                                setQuantity((prevQuantity) =>
                                  prevQuantity > 1 ? prevQuantity - 1 : 1
                                )
                              }
                            >
                              <FaMinus />
                            </button>
                            <span className="quantity-value">{quantity}</span>
                            <button
                              className="quantity-btn"
                              onClick={() =>
                                setQuantity((prevQuantity) => prevQuantity + 1)
                              }
                            >
                              <FaPlus />
                            </button>
                          </div>
                        </div>
                      </div>
                      {productDetails?.isCustomized === "1" && (
                        <div className="d-flex align-items-center justify-content-end">
                          <span
                            className={`product-single-page-customize-button mt-2 mb-3 btn rounded-0 p-0 ${
                              customizeBtn ? "active" : ""
                            }`}
                            onClick={handleCustomizeBtn}
                          >
                            Customize Your Product
                          </span>
                        </div>
                      )}
                      {customizeBtn &&
                        currentCustomizingImage === mainImage && (
                          <>
                            <div className="mb-3 d-flex justify-content-center flex-column product-single-page-form-group">
                              <label
                                htmlFor="size"
                                className="fs-6 text-capitalize mb-1 fw-medium"
                              >
                                Your First Name
                              </label>
                              <input
                                type="text"
                                id="name"
                                autoComplete="off"
                                onChange={handleInputChange}
                                value={inputValue}
                                className="fw-normal text-capitalize"
                                maxLength={10}
                                placeholder="Enter custom text"
                              />
                            </div>
                            <div className="mb-4 d-flex justify-content-center flex-column product-single-page-form-group">
                              <label
                                htmlFor="size"
                                className="fs-6 text-capitalize mb-1 fw-medium"
                              >
                                Your Zodiac
                              </label>
                              <div className="row m-0 justify-content-center">
                                {/* {zodiacArray.map((signData, index) => (
                                  <div
                                    key={index}
                                    className={`col-auto col-sm-3 col-md-3 zodiac-box ${
                                      selectedZodiacSign === signData.sign
                                        ? "selected"
                                        : ""
                                    }`}
                                    onClick={() =>
                                      handleSelectChange(signData.sign)
                                    }
                                    style={{
                                      border: "1px solid #ccc",
                                      padding: "10px",
                                      textAlign: "center",
                                      cursor: "pointer",
                                      backgroundColor:
                                        selectedZodiacSign === signData.sign
                                          ? "#0e3c52"
                                          : "#ffffff",
                                      color:
                                        selectedZodiacSign === signData.sign
                                          ? "#ffffff"
                                          : "#000000",
                                    }}
                                  >
                                    {signData.sign}
                                  </div>
                                ))} */}
                                {isMobile ? (
                                  <select
                                    value={selectedZodiacSign}
                                    onChange={handleDropdownChange}
                                    className="form-select"
                                  >
                                    <option value="">Select Zodiac Sign</option>
                                    {zodiacArray.map((signData, index) => (
                                      <option key={index} value={signData.sign}>
                                        {signData.sign}
                                      </option>
                                    ))}
                                  </select>
                                ) : (
                                  <div className="row justify-content-center p-0">
                                    {zodiacArray.map((signData, index) => (
                                      <div
                                        key={index}
                                        className={`col-auto col-sm-3 col-md-3 zodiac-box ${
                                          selectedZodiacSign === signData.sign
                                            ? "selected"
                                            : ""
                                        }`}
                                        onClick={() =>
                                          handleSelectChange(signData.sign)
                                        }
                                        style={{
                                          border: "1px solid #ccc",
                                          padding: "10px",
                                          textAlign: "center",
                                          cursor: "pointer",
                                          backgroundColor:
                                            selectedZodiacSign === signData.sign
                                              ? "#0e3c52"
                                              : "#ffffff",
                                          color:
                                            selectedZodiacSign === signData.sign
                                              ? "#ffffff"
                                              : "#000000",
                                        }}
                                      >
                                        {signData.sign}
                                      </div>
                                    ))}
                                  </div>
                                )}
                              </div>
                            </div>
                          </>
                        )}
                      <div className="row gap-2 mx-0">
                        <button
                          className="col fs-6 fw-medium text-uppercase product-single-page-form-button"
                          onClick={handleBuyNow}
                        >
                          Buy Now
                        </button>
                        <button
                          className="col fs-6 fw-medium text-uppercase product-single-page-form-button"
                          onClick={handleAddToCart}
                        >
                          Add To Bag
                        </button>
                      </div>
                    </div>
                    <div className="h4 fs-6 fw-medium productDescAccordion">
                      {/* <div
                        dangerouslySetInnerHTML={{
                          __html: productDetails.prodDesc,
                        }}
                      /> */}
                      <div
                        className="accordion accordion-flush"
                        id="accordionFlushExample"
                      >
                        <div className="accordion-item">
                          <div className="h2 accordion-header">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#flush-collapseOne"
                              aria-expanded="false"
                              aria-controls="flush-collapseOne"
                            >
                              Product Highlights
                            </button>
                          </div>
                          <div
                            id="flush-collapseOne"
                            className="accordion-collapse collapse"
                            data-bs-parent="#accordionFlushExample"
                          >
                            <div
                              className="accordion-body"
                              dangerouslySetInnerHTML={{
                                __html: productDetails.prodDesc
                                  .split(
                                    "<h2><strong>Product Highlights:</strong></h2>"
                                  )[1]
                                  .split("<h2><strong>")[0],
                              }}
                            />
                          </div>
                        </div>
                        <div className="accordion-item">
                          <div className="h2 accordion-header">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#flush-collapseTwo"
                              aria-expanded="false"
                              aria-controls="flush-collapseTwo"
                            >
                              Product Details
                            </button>
                          </div>
                          <div
                            id="flush-collapseTwo"
                            className="accordion-collapse collapse"
                            data-bs-parent="#accordionFlushExample"
                          >
                            <div
                              className="accordion-body"
                              dangerouslySetInnerHTML={{
                                __html: productDetails.prodDesc
                                  .split(
                                    "<h2><strong>Product Details:</strong></h2>"
                                  )[1]
                                  .split("<h2><strong>")[0],
                              }}
                            />
                          </div>
                        </div>
                        <div className="accordion-item">
                          <div className="h2 accordion-header">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#flush-collapseThree"
                              aria-expanded="false"
                              aria-controls="flush-collapseThree"
                            >
                              Disclaimer
                            </button>
                          </div>
                          <div
                            id="flush-collapseThree"
                            className="accordion-collapse collapse"
                            data-bs-parent="#accordionFlushExample"
                          >
                            <div
                              className="accordion-body"
                              dangerouslySetInnerHTML={{
                                __html: productDetails.prodDesc.split(
                                  "<h2><strong>Disclaimer if any:&nbsp;</strong></h2>"
                                )[1],
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SingleProduct;

// import React, { useContext, useEffect, useState } from "react";
// import { useNavigate, useParams } from "react-router-dom";
// import Breadcrumb from "../../components/Breadcrumb";
// import {
//   FaIndianRupeeSign,
//   FaShareFromSquare,
//   FaMinus,
//   FaPlus,
// } from "react-icons/fa6";
// import {
//   FaFacebookSquare,
//   FaInstagram,
//   FaWhatsappSquare,
//   FaCopy,
// } from "react-icons/fa";
// import { MdArrowBackIos } from "react-icons/md";
// import Loading from "../../components/Loading";
// import { suggestedProductList, zodiacData } from "../../data";
// import { CartContext } from "../../contexts/CartContext";
// import { ProductContext } from "../../contexts/ProductContext";
// import { toast } from "react-toastify";

// const SingleProduct = () => {
//   const { getProductDetails, getSuggestedProducts, selectedCategory } =
//     useContext(ProductContext);
//   const { addToCart } = useContext(CartContext);

//   const { id } = useParams();

//   const navigate = useNavigate();

//   const [productDetails, setProductDetails] = useState(null);
//   const [selectedColor, setSelectedColor] = useState(null);
//   const [mainImage, setMainImage] = useState(null);
//   const [showShareOptions, setShowShareOptions] = useState(false);
//   const [isZoomed, setIsZoomed] = useState(false);
//   const [zoomPosition, setZoomPosition] = useState({ x: 0, y: 0 });
//   const [suggestedProducts, setSuggestedProducts] = useState([]);
//   const [inputValue, setInputValue] = useState("");
//   const [selectedZodiacSign, setSelectedZodiacSign] = useState("");
//   const [customizeBtn, setCustomizeBtn] = useState(false);
//   const [currentCustomizingImage, setCurrentCustomizingImage] = useState(null);
//   const [quantity, setQuantity] = useState(1);
//   const [totalPrice, setTotalPrice] = useState(0);

//   useEffect(() => {
//     const product = getProductDetails(id);
//     setProductDetails(product);
//     if (product && product?.info?.length > 0) {
//       setSelectedColor(product?.info[0]);
//       setMainImage(product?.info[0]?.imagePath[0]);
//     }

//     const fetchSuggestedProducts = async () => {
//       const suggestions = await getSuggestedProducts(id);
//       setSuggestedProducts(suggestions);
//     };

//     fetchSuggestedProducts();
//   }, [id, getProductDetails, getSuggestedProducts]);

//   useEffect(() => {
//     if (productDetails) {
//       // Calculate base price
//       const basePrice = parseFloat(productDetails.prodPrice);

//       // Calculate suggested products total price
//       const suggestedTotalPrice = suggestedProducts.reduce(
//         (total, product) => total + parseFloat(product.price),
//         0
//       );

//       // Calculate total price including quantity
//       const calculatedTotalPrice = (basePrice + suggestedTotalPrice) * quantity;

//       // Update state with calculated total price
//       setTotalPrice(calculatedTotalPrice);
//     }
//   }, [productDetails, suggestedProducts, quantity]);

//   if (!productDetails) return <Loading />;

//   // Current page URL
//   const productUrl = window.location.href;

//   // Color change onEvent for Image
//   const handleColorChange = (color) => {
//     setSelectedColor(color);
//     setMainImage(color?.imagePath[0]);
//     setShowShareOptions(false);
//   };

//   const handleImageClick = (image) => {
//     setMainImage(image);
//     setInputValue("");
//     setSelectedZodiacSign("");
//     setCurrentCustomizingImage(image);
//     setCustomizeBtn(false);
//     setShowShareOptions(false);
//   };

//   const handleFacebookShare = () => {
//     const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
//       productUrl
//     )}`;
//     window.open(facebookUrl, "_blank");
//   };

//   const handleInstagramShare = () => {
//     const instagramUrl = `https://www.instagram.com/share?url=${encodeURIComponent(
//       productUrl
//     )}`;
//     window.open(instagramUrl, "_blank");
//   };

//   const handleWhatsappShare = () => {
//     const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
//       productUrl
//     )}`;
//     window.open(whatsappUrl, "_blank");
//   };

//   const handleCopyLink = () => {
//     navigator.clipboard.writeText(productUrl).then(
//       () => {
//         toast.success("Link copied to clipboard!", {
//           autoClose: 1000,
//           position: "bottom-right",
//         });
//       },
//       (err) => {
//         toast.error(err, {
//           autoClose: 1000,
//           position: "bottom-right",
//         });
//       }
//     );
//   };

//   const handleMouseMove = (e) => {
//     const { left, top, width, height } = e.target.getBoundingClientRect();
//     const x = (e.clientX - left) / width;
//     const y = (e.clientY - top) / height;
//     setZoomPosition({ x, y });
//   };

//   const zodiacArray = Object.entries(zodiacData).map(([sign, data]) => ({
//     sign,
//     ...data,
//   }));

//   const handleInputChange = (event) => {
//     setInputValue(event.target.value);
//   };

//   const handleSelectChange = (sign) => {
//     setSelectedZodiacSign(sign);
//   };

//   // Event toggle for customize btn
//   const handleCustomizeBtn = () => {
//     setCustomizeBtn(!customizeBtn);
//     if (selectedColor?.imagePath?.length > 0) {
//       const lastImage =
//         selectedColor.imagePath[selectedColor.imagePath.length - 1];
//       setMainImage(lastImage);
//       setCurrentCustomizingImage(lastImage);
//       setShowShareOptions(false);
//     }
//   };

//   const handleAddToCart = () => {
//     addToCart(productDetails, selectedColor, quantity, {
//       inputValue,
//       selectedZodiacSign,
//     });
//     toast.success("Added to Bag", {
//       autoClose: 1000,
//       position: "bottom-right",
//     });
//   };

//   const handleBuyNow = () => {
//     const productData = {
//       prodName: productDetails.prodName,
//       prodBrand: productDetails.prodBrand,
//       prodPrice: productDetails.prodPrice,
//       prodDesc: productDetails.prodDesc,
//       isCustomized: productDetails.isCustomized,
//       isActive: productDetails.isActive,
//       pkProdId: productDetails.pkProdId,
//       selectedColor: {
//         imageColor: selectedColor.imageColor,
//         imageName: selectedColor.imageName,
//         productName: selectedColor.productName,
//         isActive: selectedColor.isActive,
//         imagePath: selectedColor.imagePath,
//         pkImgId: selectedColor.pkImgId,
//       },
//       quantity: quantity,
//       customization: {
//         inputValue: inputValue,
//         selectedZodiacSign: selectedZodiacSign,
//       },
//       id: `${productDetails.pkProdId}-${selectedColor.imageColor}-{"inputValue":"${inputValue}","selectedZodiacSign":"${selectedZodiacSign}"}`,
//     };

//     navigate("/checkout", {
//       state: {
//         productDetails: [productData],
//         amount: totalPrice,
//       },
//     });
//   };

//   const handleBackClick = () => {
//     navigate(-1);
//   };

//   return (
//     <>
//       <section className="product-single-page">
//         {/* <Breadcrumb title="Product" productId={`/${id}`} /> */}
//         <div className="py-5 product-single-page-block">
//           <div className="our-container">
//             <div className="inner-container px-5 mx-auto">
//               <button
//                 onClick={handleBackClick}
//                 className="back-btn-per-page mb-3"
//               >
//                 <MdArrowBackIos className="back-icon-per-page" />
//                 Back
//               </button>
//               <div className="row product-single-page-block-row">
//                 <div className="col-md-6">
//                   <div className="row gap-3 px-3 main-row-for-product-single-page">
//                     <div className="col-md-2 d-flex align-items-start justify-content-center product-single-page-img-grp">
//                       <div className="row gap-3 product-single-page-img-group">
//                         {selectedColor?.imagePath?.map((image, index) => (
//                           <img
//                             key={index}
//                             src={image}
//                             alt={`Option ${index + 1}`}
//                             onClick={() => handleImageClick(image)}
//                             className={`thumbnail ${
//                               mainImage === image ? "active" : ""
//                             }`}
//                           />
//                         ))}
//                       </div>
//                     </div>
//                     <div className="col-md-9 d-flex align-items-center justify-content-center position-relative product-single-page-main-img">
//                       <img
//                         src={mainImage}
//                         onClick={() => setShowShareOptions(false)}
//                         alt="Main"
//                       />
//                       {currentCustomizingImage === mainImage && (
//                         <>
//                           <div className="product-single-page-main-image-current-details">
//                             <span className="text-capitalize">
//                               {inputValue}
//                             </span>
//                           </div>
//                           <div className="zodiacs-flower-upper-image">
//                             {selectedZodiacSign && (
//                               <img
//                                 src={
//                                   zodiacArray.find(
//                                     (sign) => sign.sign === selectedZodiacSign
//                                   )?.imageUrl
//                                 }
//                                 alt={selectedZodiacSign}
//                               />
//                             )}
//                           </div>
//                         </>
//                       )}
//                       <div className="mt-2 position-absolute share-button-product">
//                         <p className="mb-0">
//                           <FaShareFromSquare
//                             className="fs-4 share-button-icon mb-2"
//                             size={25}
//                             onClick={() => setShowShareOptions((prev) => !prev)}
//                           />
//                           {showShareOptions && (
//                             <span className="d-flex align-items-center justify-content-between gap-3 flex-column share-button-options">
//                               <FaFacebookSquare
//                                 size={25}
//                                 onClick={handleFacebookShare}
//                                 className="product-share-icon-all"
//                               />
//                               <FaInstagram
//                                 size={25}
//                                 onClick={handleInstagramShare}
//                                 className="product-share-icon-all"
//                               />
//                               <FaWhatsappSquare
//                                 size={25}
//                                 onClick={handleWhatsappShare}
//                                 className="product-share-icon-all"
//                               />
//                               <FaCopy
//                                 size={25}
//                                 onClick={handleCopyLink}
//                                 className="product-share-icon-all"
//                               />
//                             </span>
//                           )}
//                         </p>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//                 <div className="col-md-6">
//                   <div className="product-single-page-details">
//                     <h1 className="fs-4 fw-medium mb-3">
//                       {productDetails?.prodName}
//                     </h1>
//                     <div className="d-flex align-items-center mb-3 gap-2 product-price">
//                       <FaIndianRupeeSign className="fs-6 fw-medium mb-0 price-icon" />
//                       <div className="h3 fs-5 fw-medium mb-0">
//                         {productDetails?.prodPrice}
//                       </div>
//                     </div>
//                     <div className="my-4 product-single-page-details-fields">
//                       <div className="mb-3 d-flex justify-content-center flex-column product-single-page-form-group">
//                         {productDetails?.info?.some(
//                           (color) => color?.imageColor
//                         ) && (
//                           <>
//                             <label
//                               htmlFor="size"
//                               className="fs-6 text-capitalize mb-1 fw-medium"
//                             >
//                               Colors
//                             </label>
//                             <div className="row justify-content-start gap-3 m-0 product-single-page-colors-group">
//                               {productDetails?.info?.map((color, index) => (
//                                 <div
//                                   key={index}
//                                   onClick={() => handleColorChange(color)}
//                                   className={`col fs-6 text-uppercase radio-btn-color ${
//                                     selectedColor?.imageColor ===
//                                     color?.imageColor
//                                       ? "active"
//                                       : ""
//                                   }`}
//                                   style={{
//                                     backgroundColor: color.imageColor,
//                                     color: "#ffffff",
//                                     border:
//                                       selectedColor?.imageColor ===
//                                       color?.imageColor
//                                         ? "2px solid #000"
//                                         : "none",
//                                   }}
//                                 ></div>
//                               ))}
//                             </div>
//                           </>
//                         )}
//                       </div>
//                       <div className="mb-3 d-flex justify-content-center flex-column product-single-page-form-group">
//                         <label
//                           htmlFor="size"
//                           className="fs-6 text-capitalize mb-1 fw-medium"
//                         >
//                           Quantity
//                         </label>
//                         <div className="row justify-content-start gap-3 m-0 product-single-page-quantity">
//                           <div className="quantity-block">
//                             <button
//                               className="quantity-btn"
//                               onClick={() =>
//                                 setQuantity((prevQuantity) =>
//                                   prevQuantity > 1 ? prevQuantity - 1 : 1
//                                 )
//                               }
//                             >
//                               <FaMinus />
//                             </button>
//                             <span className="quantity-value">{quantity}</span>
//                             <button
//                               className="quantity-btn"
//                               onClick={() =>
//                                 setQuantity((prevQuantity) => prevQuantity + 1)
//                               }
//                             >
//                               <FaPlus />
//                             </button>
//                           </div>
//                         </div>
//                       </div>
//                       {productDetails?.isCustomized === "1" && (
//                         <div className="d-flex align-items-center justify-content-end">
//                           <span
//                             className={`product-single-page-customize-button mt-2 mb-3 btn rounded-0 p-0 ${
//                               customizeBtn ? "active" : ""
//                             }`}
//                             onClick={handleCustomizeBtn}
//                           >
//                             Customize Your Product
//                           </span>
//                         </div>
//                       )}
//                       {customizeBtn &&
//                         currentCustomizingImage === mainImage && (
//                           <>
//                             <div className="mb-3 d-flex justify-content-center flex-column product-single-page-form-group">
//                               <label
//                                 htmlFor="size"
//                                 className="fs-6 text-capitalize mb-1 fw-medium"
//                               >
//                                 Your First Name
//                               </label>
//                               <input
//                                 type="text"
//                                 id="name"
//                                 autoComplete="off"
//                                 onChange={handleInputChange}
//                                 value={inputValue}
//                                 className="fw-normal text-capitalize"
//                                 maxLength={10}
//                                 placeholder="Enter custom text"
//                               />
//                             </div>
//                             <div className="mb-4 d-flex justify-content-center flex-column product-single-page-form-group">
//                               <label
//                                 htmlFor="size"
//                                 className="fs-6 text-capitalize mb-1 fw-medium"
//                               >
//                                 Your Zodiac
//                               </label>
//                               <div className="row m-0">
//                                 {zodiacArray.map((signData, index) => (
//                                   <div
//                                     key={index}
//                                     className={`col-auto col-md-3 zodiac-box ${
//                                       selectedZodiacSign === signData.sign
//                                         ? "selected"
//                                         : ""
//                                     }`}
//                                     onClick={() =>
//                                       handleSelectChange(signData.sign)
//                                     }
//                                     style={{
//                                       border: "1px solid #ccc",
//                                       padding: "10px",
//                                       textAlign: "center",
//                                       cursor: "pointer",
//                                       backgroundColor:
//                                         selectedZodiacSign === signData.sign
//                                           ? "#0e3c52"
//                                           : "#ffffff",
//                                       color:
//                                         selectedZodiacSign === signData.sign
//                                           ? "#ffffff"
//                                           : "#000000",
//                                     }}
//                                   >
//                                     {signData.sign}
//                                   </div>
//                                 ))}
//                               </div>
//                             </div>
//                           </>
//                         )}
//                       <div className="row gap-2 mx-0">
//                         <button
//                           className="col fs-6 fw-medium text-uppercase product-single-page-form-button"
//                           onClick={handleBuyNow}
//                         >
//                           Buy Now
//                         </button>
//                         <button
//                           className="col fs-6 fw-medium text-uppercase product-single-page-form-button"
//                           onClick={handleAddToCart}
//                         >
//                           Add To Bag
//                         </button>
//                       </div>
//                     </div>
//                     <div className="h4 fs-6 fw-medium productDescAccordion">
//                       {/* <div
//                         dangerouslySetInnerHTML={{
//                           __html: productDetails.prodDesc,
//                         }}
//                       /> */}
//                       <div
//                         className="accordion accordion-flush"
//                         id="accordionFlushExample"
//                       >
//                         <div className="accordion-item">
//                           <div className="h2 accordion-header">
//                             <button
//                               className="accordion-button collapsed"
//                               type="button"
//                               data-bs-toggle="collapse"
//                               data-bs-target="#flush-collapseOne"
//                               aria-expanded="false"
//                               aria-controls="flush-collapseOne"
//                             >
//                               Product Highlights
//                             </button>
//                           </div>
//                           <div
//                             id="flush-collapseOne"
//                             className="accordion-collapse collapse"
//                             data-bs-parent="#accordionFlushExample"
//                           >
//                             <div
//                               className="accordion-body"
//                               dangerouslySetInnerHTML={{
//                                 __html: productDetails.prodDesc
//                                   .split(
//                                     "<h2><strong>Product Highlights:</strong></h2>"
//                                   )[1]
//                                   .split("<h2><strong>")[0],
//                               }}
//                             />
//                           </div>
//                         </div>
//                         <div className="accordion-item">
//                           <div className="h2 accordion-header">
//                             <button
//                               className="accordion-button collapsed"
//                               type="button"
//                               data-bs-toggle="collapse"
//                               data-bs-target="#flush-collapseTwo"
//                               aria-expanded="false"
//                               aria-controls="flush-collapseTwo"
//                             >
//                               Product Details
//                             </button>
//                           </div>
//                           <div
//                             id="flush-collapseTwo"
//                             className="accordion-collapse collapse"
//                             data-bs-parent="#accordionFlushExample"
//                           >
//                             <div
//                               className="accordion-body"
//                               dangerouslySetInnerHTML={{
//                                 __html: productDetails.prodDesc
//                                   .split(
//                                     "<h2><strong>Product Details:</strong></h2>"
//                                   )[1]
//                                   .split("<h2><strong>")[0],
//                               }}
//                             />
//                           </div>
//                         </div>
//                         <div className="accordion-item">
//                           <div className="h2 accordion-header">
//                             <button
//                               className="accordion-button collapsed"
//                               type="button"
//                               data-bs-toggle="collapse"
//                               data-bs-target="#flush-collapseThree"
//                               aria-expanded="false"
//                               aria-controls="flush-collapseThree"
//                             >
//                               Disclaimer
//                             </button>
//                           </div>
//                           <div
//                             id="flush-collapseThree"
//                             className="accordion-collapse collapse"
//                             data-bs-parent="#accordionFlushExample"
//                           >
//                             <div
//                               className="accordion-body"
//                               dangerouslySetInnerHTML={{
//                                 __html: productDetails.prodDesc.split(
//                                   "<h2><strong>Disclaimer if any:&nbsp;</strong></h2>"
//                                 )[1],
//                               }}
//                             />
//                           </div>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>
//     </>
//   );
// };

// export default SingleProduct;

// import React, { useContext, useEffect, useState } from "react";
// import { useNavigate, useParams } from "react-router-dom";
// import Breadcrumb from "../../components/Breadcrumb";
// import {
//   FaIndianRupeeSign,
//   FaShareFromSquare,
//   FaMinus,
//   FaPlus,
// } from "react-icons/fa6";
// import {
//   FaFacebookSquare,
//   FaInstagram,
//   FaWhatsappSquare,
//   FaCopy,
// } from "react-icons/fa";
// import { MdArrowBackIos } from "react-icons/md";
// import Loading from "../../components/Loading";
// import { suggestedProductList, zodiacData } from "../../data";
// import { CartContext } from "../../contexts/CartContext";
// import { ProductContext } from "../../contexts/ProductContext";
// import { toast } from "react-toastify";

// const SingleProduct = () => {
//   const { getProductDetails, getSuggestedProducts, selectedCategory } =
//     useContext(ProductContext);
//   const { addToCart } = useContext(CartContext);

//   const { id } = useParams();

//   const navigate = useNavigate();

//   const [productDetails, setProductDetails] = useState(null);
//   const [selectedColor, setSelectedColor] = useState(null);
//   const [mainImage, setMainImage] = useState(null);
//   const [showShareOptions, setShowShareOptions] = useState(false);
//   const [isZoomed, setIsZoomed] = useState(false);
//   const [zoomPosition, setZoomPosition] = useState({ x: 0, y: 0 });
//   const [suggestedProducts, setSuggestedProducts] = useState([]);
//   const [inputValue, setInputValue] = useState("");
//   const [selectedZodiacSign, setSelectedZodiacSign] = useState("");
//   const [customizeBtn, setCustomizeBtn] = useState(false);
//   const [currentCustomizingImage, setCurrentCustomizingImage] = useState(null);
//   const [quantity, setQuantity] = useState(1);
//   const [totalPrice, setTotalPrice] = useState(0);

//   useEffect(() => {
//     const fetchProductDetails = async () => {
//       const product = await getProductDetails(id);
//       setProductDetails(product);
//       if (product && product?.info?.length > 0) {
//         setSelectedColor(product?.info[0]);
//         setMainImage(product?.info[0]?.imagePath[0]);
//       }

//       const fetchSuggestedProducts = async () => {
//         const suggestions = await getSuggestedProducts(id);
//         setSuggestedProducts(suggestions);
//       };

//       fetchSuggestedProducts();
//     };

//     fetchProductDetails();
//   }, [id, getProductDetails, getSuggestedProducts]);

//   useEffect(() => {
//     if (productDetails) {
//       const basePrice = parseFloat(productDetails.prodPrice);

//       const suggestedTotalPrice = suggestedProducts.reduce(
//         (total, product) => total + parseFloat(product.price),
//         0
//       );

//       const calculatedTotalPrice = (basePrice + suggestedTotalPrice) * quantity;

//       setTotalPrice(calculatedTotalPrice);
//     }
//   }, [productDetails, suggestedProducts, quantity]);

//   if (!productDetails) return <Loading />;

//   const productUrl = window.location.href;

//   const handleColorChange = (color) => {
//     setSelectedColor(color);
//     setMainImage(color?.imagePath[0]);
//     setShowShareOptions(false);
//   };

//   const handleImageClick = (image) => {
//     setMainImage(image);
//     setInputValue("");
//     setSelectedZodiacSign("");
//     setCurrentCustomizingImage(image);
//     setCustomizeBtn(false);
//     setShowShareOptions(false);
//   };

//   const handleFacebookShare = () => {
//     const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
//       productUrl
//     )}`;
//     window.open(facebookUrl, "_blank");
//   };

//   const handleInstagramShare = () => {
//     const instagramUrl = `https://www.instagram.com/share?url=${encodeURIComponent(
//       productUrl
//     )}`;
//     window.open(instagramUrl, "_blank");
//   };

//   const handleWhatsappShare = () => {
//     const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
//       productUrl
//     )}`;
//     window.open(whatsappUrl, "_blank");
//   };

//   const handleCopyLink = () => {
//     navigator.clipboard.writeText(productUrl).then(
//       () => {
//         toast.success("Link copied to clipboard!", {
//           autoClose: 1000,
//           position: 'bottom-right'
//         });
//       },
//       (err) => {
//         toast.error(err, {
//           autoClose: 1000,
//           position: 'bottom-right'
//         });
//       }
//     );
//   };

//   const handleMouseMove = (e) => {
//     const { left, top, width, height } = e.target.getBoundingClientRect();
//     const x = (e.clientX - left) / width;
//     const y = (e.clientY - top) / height;
//     setZoomPosition({ x, y });
//   };

//   const zodiacArray = Object.entries(zodiacData).map(([sign, data]) => ({
//     sign,
//     ...data,
//   }));

//   const handleInputChange = (event) => {
//     setInputValue(event.target.value);
//   };

//   const handleSelectChange = (sign) => {
//     setSelectedZodiacSign(sign);
//   };

//   const handleCustomizeBtn = () => {
//     setCustomizeBtn(!customizeBtn);
//     if (selectedColor?.imagePath?.length > 0) {
//       const lastImage = selectedColor.imagePath[selectedColor.imagePath.length - 1];
//       setMainImage(lastImage);
//       setCurrentCustomizingImage(lastImage);
//       setShowShareOptions(false);
//     }
//   };

//   const handleAddToCart = () => {
//     addToCart(productDetails, selectedColor, quantity, {
//       inputValue,
//       selectedZodiacSign,
//     });
//     toast.success("Added to Bag", {
//       autoClose: 1000,
//       position: 'bottom-right'
//     });
//   };

//   const handleBuyNow = () => {
//     const productData = {
//       prodName: productDetails.prodName,
//       prodBrand: productDetails.prodBrand,
//       prodPrice: productDetails.prodPrice,
//       prodDesc: productDetails.prodDesc,
//       isCustomized: productDetails.isCustomized,
//       isActive: productDetails.isActive,
//       pkProdId: productDetails.pkProdId,
//       selectedColor: {
//         imageColor: selectedColor.imageColor,
//         imageName: selectedColor.imageName,
//         productName: selectedColor.productName,
//         isActive: selectedColor.isActive,
//         imagePath: selectedColor.imagePath,
//         pkImgId: selectedColor.pkImgId,
//       },
//       quantity: quantity,
//       customization: {
//         inputValue: inputValue,
//         selectedZodiacSign: selectedZodiacSign,
//       },
//       id: `${productDetails.pkProdId}-${selectedColor.imageColor}-{"inputValue":"${inputValue}","selectedZodiacSign":"${selectedZodiacSign}"}`,
//     };

//     navigate("/checkout", {
//       state: {
//         productDetails: [productData],
//         amount: totalPrice,
//       },
//     });
//   };

//   const handleBackClick = () => {
//     navigate(-1);
//   };

//   return (
//     <>
//       <section className="product-single-page">
//         <div className="py-5 product-single-page-block">
//           <div className="our-container">
//             <div className="inner-container px-5 mx-auto">
//               <button
//                 onClick={handleBackClick}
//                 className="back-btn-per-page mb-3"
//               >
//                 <MdArrowBackIos className="back-icon-per-page" />
//                 Back
//               </button>
//               <div className="row product-single-page-block-row">
//                 <div className="col-md-6">
//                   <div className="row gap-3 px-3 main-row-for-product-single-page">
//                     <div className="col-md-2 d-flex align-items-start justify-content-center product-single-page-img-grp">
//                       <div className="row gap-3 product-single-page-img-group">
//                         {selectedColor?.imagePath?.map((image, index) => (
//                           <img
//                             key={index}
//                             src={image}
//                             alt={`Option ${index + 1}`}
//                             onClick={() => handleImageClick(image)}
//                             className={`thumbnail ${
//                               mainImage === image ? "active" : ""
//                             }`}
//                           />
//                         ))}
//                       </div>
//                     </div>
//                     <div className="col-md-9 d-flex align-items-center justify-content-center position-relative product-single-page-main-img">
//                       <img
//                         src={mainImage}
//                         onClick={() => setShowShareOptions(false)}
//                         alt="Main"
//                       />
//                       {currentCustomizingImage === mainImage && (
//                         <>
//                           <div className="product-single-page-main-image-current-details">
//                             <span className="text-capitalize">
//                               {inputValue}
//                             </span>
//                           </div>
//                           <div className="zodiacs-flower-upper-image">
//                             {selectedZodiacSign && (
//                               <img
//                                 src={
//                                   zodiacArray.find(
//                                     (sign) => sign.sign === selectedZodiacSign
//                                   )?.imageUrl
//                                 }
//                                 alt={selectedZodiacSign}
//                               />
//                             )}
//                           </div>
//                         </>
//                       )}
//                       <div
//                         className="product-single-page-main-image-zoom"
//                         onMouseMove={handleMouseMove}
//                         onMouseLeave={() => setIsZoomed(false)}
//                       >
//                         <div
//                           className="zoom-icon"
//                           onMouseEnter={() => setIsZoomed(true)}
//                         >
//                           {/* <FaSearchPlus /> */}
//                         </div>
//                         {isZoomed && (
//                           <div
//                             className="zoom-overlay"
//                             style={{
//                               backgroundImage: `url(${mainImage})`,
//                               backgroundPosition: `-${zoomPosition.x * 100}% -${zoomPosition.y * 100}%`,
//                             }}
//                           />
//                         )}
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//                 <div className="col-md-6 product-details-section">
//                   <h1>{productDetails.prodName}</h1>
//                   <p className="product-brand">
//                     Brand: {productDetails.prodBrand}
//                   </p>
//                   <div className="product-price">
//                     <FaIndianRupeeSign />
//                     {productDetails.prodPrice}
//                   </div>
//                   <div className="product-description">
//                     <div
//                       dangerouslySetInnerHTML={{
//                         __html: productDetails.prodDesc,
//                       }}
//                     />
//                   </div>
//                   <div className="product-colors">
//                     <h4>Select Color:</h4>
//                     {productDetails?.info?.map((color) => (
//                       <div
//                         key={color.pkImgId}
//                         onClick={() => handleColorChange(color)}
//                         className={`color-option ${
//                           selectedColor?.imageColor === color.imageColor
//                             ? "selected"
//                             : ""
//                         }`}
//                       >
//                         <img
//                           src={color.imagePath[0]}
//                           alt={color.imageName}
//                           className="color-thumbnail"
//                         />
//                       </div>
//                     ))}
//                   </div>
//                   <div className="quantity-selector">
//                     <button
//                       className="quantity-btn"
//                       onClick={() => setQuantity((prev) => Math.max(prev - 1, 1))}
//                     >
//                       <FaMinus />
//                     </button>
//                     <input
//                       type="text"
//                       value={quantity}
//                       readOnly
//                       className="quantity-input"
//                     />
//                     <button
//                       className="quantity-btn"
//                       onClick={() => setQuantity((prev) => prev + 1)}
//                     >
//                       <FaPlus />
//                     </button>
//                   </div>
//                   <div className="customize-btn-section">
//                     <button
//                       className={`customize-btn ${customizeBtn ? "active" : ""}`}
//                       onClick={handleCustomizeBtn}
//                     >
//                       Customize Your Product
//                     </button>
//                     {customizeBtn && (
//                       <div className="customize-options">
//                         <div className="form-group">
//                           <label htmlFor="inputValue">Custom Text:</label>
//                           <textarea
//                             id="inputValue"
//                             value={inputValue}
//                             onChange={handleInputChange}
//                           />
//                         </div>
//                         <div className="form-group">
//                           <label htmlFor="zodiacSign">Select Zodiac Sign:</label>
//                           <select
//                             id="zodiacSign"
//                             value={selectedZodiacSign}
//                             onChange={(e) => handleSelectChange(e.target.value)}
//                           >
//                             <option value="">Select Zodiac Sign</option>
//                             {zodiacArray.map((zodiac) => (
//                               <option key={zodiac.sign} value={zodiac.sign}>
//                                 {zodiac.sign}
//                               </option>
//                             ))}
//                           </select>
//                         </div>
//                       </div>
//                     )}
//                   </div>
//                   <div className="actions">
//                     <button onClick={handleAddToCart} className="add-to-cart-btn">
//                       Add to Bag
//                     </button>
//                     <button onClick={handleBuyNow} className="buy-now-btn">
//                       Buy Now
//                     </button>
//                   </div>
//                   <div className="share-options">
//                     <button onClick={() => setShowShareOptions(!showShareOptions)}>
//                       <FaShareFromSquare />
//                     </button>
//                     {showShareOptions && (
//                       <div className="share-buttons">
//                         <button onClick={handleFacebookShare}>
//                           <FaFacebookSquare />
//                         </button>
//                         <button onClick={handleInstagramShare}>
//                           <FaInstagram />
//                         </button>
//                         <button onClick={handleWhatsappShare}>
//                           <FaWhatsappSquare />
//                         </button>
//                         <button onClick={handleCopyLink}>
//                           <FaCopy />
//                         </button>
//                       </div>
//                     )}
//                   </div>
//                 </div>
//               </div>
//               <div className="suggested-products">
//                 <h2>Suggested Products</h2>
//                 <div className="product-list">
//                   {suggestedProducts.map((product) => (
//                     <div key={product.pkProdId} className="suggested-product">
//                       <img src={product.imagePath[0]} alt={product.prodName} />
//                       <div className="product-info">
//                         <h3>{product.prodName}</h3>
//                         <p>
//                           <FaIndianRupeeSign />
//                           {product.prodPrice}
//                         </p>
//                       </div>
//                     </div>
//                   ))}
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>
//     </>
//   );
// };

// export default SingleProduct;

// // // import React, { useContext, useEffect, useState } from "react";
// // // import { useNavigate, useParams } from "react-router-dom";
// // // import Breadcrumb from "../../components/Breadcrumb";
// // // import {
// // //   FaIndianRupeeSign,
// // //   FaShareFromSquare,
// // //   FaMinus,
// // //   FaPlus,
// // // } from "react-icons/fa6";
// // // import {
// // //   FaFacebookSquare,
// // //   FaInstagram,
// // //   FaWhatsappSquare,
// // //   FaCopy,
// // // } from "react-icons/fa";
// // // import Loading from "../../components/Loading";
// // // import { zodiacData } from "../../data";
// // // import { CartContext } from "../../contexts/CartContext";
// // // import { ProductContext } from "../../contexts/ProductContext";
// // // import { toast } from "react-toastify";

// // // const SingleProduct = () => {
// // //   const { getProductDetails, getSuggestedProducts } = useContext(ProductContext);
// // //   const { addToCart } = useContext(CartContext);
// // //   const { id } = useParams(); // Get the product ID from URL
// // //   const navigate = useNavigate();

// // //   const [productDetails, setProductDetails] = useState(null);
// // //   const [selectedColor, setSelectedColor] = useState(null);
// // //   const [mainImage, setMainImage] = useState(null);
// // //   const [showShareOptions, setShowShareOptions] = useState(false);
// // //   const [isZoomed, setIsZoomed] = useState(false);
// // //   const [zoomPosition, setZoomPosition] = useState({ x: 0, y: 0 });
// // //   const [suggestedProducts, setSuggestedProducts] = useState([]);
// // //   const [inputValue, setInputValue] = useState("");
// // //   const [selectedZodiacSign, setSelectedZodiacSign] = useState("");
// // //   const [customizeBtn, setCustomizeBtn] = useState(false);
// // //   const [currentCustomizingImage, setCurrentCustomizingImage] = useState(null);
// // //   const [quantity, setQuantity] = useState(1);
// // //   const [totalPrice, setTotalPrice] = useState(0);

// // //   useEffect(() => {
// // //     const fetchProductDetails = async () => {
// // //       const product = await getProductDetails(id);
// // //       setProductDetails(product);
// // //       if (product && product?.info?.length > 0) {
// // //         setSelectedColor(product?.info[0]);
// // //         setMainImage(product?.info[0]?.imagePath[0]);
// // //       }
// // //     };

// // //     const fetchSuggestedProducts = async () => {
// // //       const suggestions = await getSuggestedProducts(id);
// // //       setSuggestedProducts(suggestions);
// // //     };

// // //     fetchProductDetails();
// // //     fetchSuggestedProducts();
// // //   }, [id, getProductDetails, getSuggestedProducts]);

// // //   useEffect(() => {
// // //     if (productDetails) {
// // //       const basePrice = parseFloat(productDetails.prodPrice);

// // //       const suggestedTotalPrice = suggestedProducts.reduce(
// // //         (total, product) => total + parseFloat(product.price),
// // //         0
// // //       );

// // //       const calculatedTotalPrice = (basePrice + suggestedTotalPrice) * quantity;
// // //       setTotalPrice(calculatedTotalPrice);
// // //     }
// // //   }, [productDetails, suggestedProducts, quantity]);

// // //   if (!productDetails) return <Loading />;

// // //   const productUrl = window.location.href;

// // //   const handleColorChange = (color) => {
// // //     setSelectedColor(color);
// // //     setMainImage(color?.imagePath[0]);
// // //     setShowShareOptions(false);
// // //   };

// // //   const handleImageClick = (image) => {
// // //     setMainImage(image);
// // //     setInputValue("");
// // //     setSelectedZodiacSign("");
// // //     setCurrentCustomizingImage(image);
// // //     setCustomizeBtn(false);
// // //     setShowShareOptions(false);
// // //   };

// // //   const handleFacebookShare = () => {
// // //     const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(productUrl)}`;
// // //     window.open(facebookUrl, "_blank");
// // //   };

// // //   const handleInstagramShare = () => {
// // //     const instagramUrl = `https://www.instagram.com/share?url=${encodeURIComponent(productUrl)}`;
// // //     window.open(instagramUrl, "_blank");
// // //   };

// // //   const handleWhatsappShare = () => {
// // //     const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(productUrl)}`;
// // //     window.open(whatsappUrl, "_blank");
// // //   };

// // //   const handleCopyLink = () => {
// // //     navigator.clipboard.writeText(productUrl).then(
// // //       () => {
// // //         toast.success("Link copied to clipboard!");
// // //       },
// // //       (err) => {
// // //         toast.error(err);
// // //       }
// // //     );
// // //   };

// // //   const handleMouseMove = (e) => {
// // //     const { left, top, width, height } = e.target.getBoundingClientRect();
// // //     const x = (e.clientX - left) / width;
// // //     const y = (e.clientY - top) / height;
// // //     setZoomPosition({ x, y });
// // //   };

// // //   const zodiacArray = Object.entries(zodiacData).map(([sign, data]) => ({
// // //     sign,
// // //     ...data,
// // //   }));

// // //   const handleInputChange = (event) => {
// // //     setInputValue(event.target.value);
// // //   };

// // //   const handleSelectChange = (sign) => {
// // //     setSelectedZodiacSign(sign);
// // //   };

// // //   const handleCustomizeBtn = () => {
// // //     setCustomizeBtn(!customizeBtn);
// // //     if (selectedColor?.imagePath?.length > 0) {
// // //       const lastImage =
// // //         selectedColor.imagePath[selectedColor.imagePath.length - 1];
// // //       setMainImage(lastImage);
// // //       setCurrentCustomizingImage(lastImage);
// // //       setShowShareOptions(false);
// // //     }
// // //   };

// // //   const handleAddToCart = () => {
// // //     addToCart(productDetails, selectedColor, quantity, {
// // //       inputValue,
// // //       selectedZodiacSign,
// // //     });
// // //     toast.success("Added to Bag");
// // //   };

// // //   const handleBuyNow = () => {
// // //     const productData = {
// // //       prodName: productDetails.prodName,
// // //       prodBrand: productDetails.prodBrand,
// // //       prodPrice: productDetails.prodPrice,
// // //       prodDesc: productDetails.prodDesc,
// // //       isCustomized: productDetails.isCustomized,
// // //       isActive: productDetails.isActive,
// // //       pkProdId: productDetails.pkProdId,
// // //       selectedColor: {
// // //         imageColor: selectedColor.imageColor,
// // //         imageName: selectedColor.imageName,
// // //         productName: selectedColor.productName,
// // //         isActive: selectedColor.isActive,
// // //         imagePath: selectedColor.imagePath,
// // //         pkImgId: selectedColor.pkImgId,
// // //       },
// // //       quantity: quantity,
// // //       customization: {
// // //         inputValue: inputValue,
// // //         selectedZodiacSign: selectedZodiacSign,
// // //       },
// // //       id: `${productDetails.pkProdId}-${selectedColor.imageColor}-{"inputValue":"${inputValue}","selectedZodiacSign":"${selectedZodiacSign}"}`,
// // //     };

// // //     navigate("/checkout", {
// // //       state: {
// // //         productDetails: [productData],
// // //         amount: totalPrice,
// // //       },
// // //     });
// // //   };

// // //   return (
// // //     <>
// // //       <section className="product-single-page">
// // //         <Breadcrumb title="Product" productId={`/${id}`} />
// // //         <div className="py-5 product-single-page-block">
// // //           <div className="our-container">
// // //             <div className="inner-container px-5 mx-auto">
// // //               <div className="row product-single-page-block-row">
// // //                 <div className="col-md-6">
// // //                   <div className="row gap-3 px-3 main-row-for-product-single-page">
// // //                     <div className="col-md-2 d-flex align-items-start justify-content-center product-single-page-img-grp">
// // //                       <div className="row gap-3 product-single-page-img-group">
// // //                         {selectedColor?.imagePath?.map((image, index) => (
// // //                           <img
// // //                             key={index}
// // //                             src={image}
// // //                             alt={`Option ${index + 1}`}
// // //                             onClick={() => handleImageClick(image)}
// // //                             className={`thumbnail ${
// // //                               mainImage === image ? "active" : ""
// // //                             }`}
// // //                           />
// // //                         ))}
// // //                       </div>
// // //                     </div>
// // //                     <div className="col-md-9 d-flex align-items-center justify-content-center position-relative product-single-page-main-img">
// // //                       <img
// // //                         src={mainImage}
// // //                         onClick={() => setShowShareOptions(false)}
// // //                         alt="Main"
// // //                         onMouseMove={handleMouseMove}
// // //                         style={{ cursor: isZoomed ? "zoom-in" : "default" }}
// // //                       />
// // //                       {currentCustomizingImage === mainImage && (
// // //                         <>
// // //                           <div className="product-single-page-main-image-current-details">
// // //                             <span className="text-capitalize">
// // //                               {inputValue}
// // //                             </span>
// // //                           </div>
// // //                           <div className="zodiacs-flower-upper-image">
// // //                             {selectedZodiacSign && (
// // //                               <img
// // //                                 src={
// // //                                   zodiacArray.find(
// // //                                     (sign) => sign.sign === selectedZodiacSign
// // //                                   )?.imageUrl
// // //                                 }
// // //                                 alt={selectedZodiacSign}
// // //                               />
// // //                             )}
// // //                           </div>
// // //                         </>
// // //                       )}
// // //                       <div className="mt-2 position-absolute share-button-product">
// // //                         <p className="mb-0">
// // //                           <FaShareFromSquare
// // //                             className="fs-4 share-button-icon mb-2"
// // //                             onClick={() => setShowShareOptions(!showShareOptions)}
// // //                           />
// // //                         </p>
// // //                         {showShareOptions && (
// // //                           <div className="share-options">
// // //                             <FaFacebookSquare
// // //                               className="fs-4 mx-2"
// // //                               onClick={handleFacebookShare}
// // //                             />
// // //                             <FaInstagram
// // //                               className="fs-4 mx-2"
// // //                               onClick={handleInstagramShare}
// // //                             />
// // //                             <FaWhatsappSquare
// // //                               className="fs-4 mx-2"
// // //                               onClick={handleWhatsappShare}
// // //                             />
// // //                             <FaCopy
// // //                               className="fs-4 mx-2"
// // //                               onClick={handleCopyLink}
// // //                             />
// // //                           </div>
// // //                         )}
// // //                       </div>
// // //                     </div>
// // //                   </div>
// // //                 </div>
// // //                 <div className="col-md-6">
// // //                   <div className="product-single-page-info">
// // //                     <h2 className="product-name">{productDetails.prodName}</h2>
// // //                     <p className="product-brand">{productDetails.prodBrand}</p>
// // //                     <div className="d-flex gap-3 align-items-center">
// // //                       <FaIndianRupeeSign className="fs-4" />
// // //                       <span className="fs-5">
// // //                         {productDetails.prodPrice}
// // //                       </span>
// // //                     </div>
// // //                     <p className="product-description">
// // //                       {productDetails.prodDesc}
// // //                     </p>
// // //                     <div className="d-flex gap-3">
// // //                       <button
// // //                         className="btn btn-primary"
// // //                         onClick={handleAddToCart}
// // //                       >
// // //                         Add to Cart
// // //                       </button>
// // //                       <button
// // //                         className="btn btn-secondary"
// // //                         onClick={handleBuyNow}
// // //                       >
// // //                         Buy Now
// // //                       </button>
// // //                     </div>
// // //                     <div className="product-customize-section mt-3">
// // //                       <button
// // //                         className="btn btn-customize"
// // //                         onClick={handleCustomizeBtn}
// // //                       >
// // //                         {customizeBtn ? "Hide Customize" : "Customize Your Product"}
// // //                       </button>
// // //                       {customizeBtn && (
// // //                         <div className="customize-options">
// // //                           <div className="input-group mb-3">
// // //                             <input
// // //                               type="text"
// // //                               className="form-control"
// // //                               placeholder="Enter Custom Text"
// // //                               value={inputValue}
// // //                               onChange={handleInputChange}
// // //                             />
// // //                           </div>
// // //                           <div className="d-flex flex-wrap gap-2">
// // //                             {zodiacArray.map((zodiac) => (
// // //                               <img
// // //                                 key={zodiac.sign}
// // //                                 src={zodiac.imageUrl}
// // //                                 alt={zodiac.sign}
// // //                                 onClick={() => handleSelectChange(zodiac.sign)}
// // //                                 className={`zodiac-sign ${
// // //                                   selectedZodiacSign === zodiac.sign
// // //                                     ? "selected"
// // //                                     : ""
// // //                                 }`}
// // //                               />
// // //                             ))}
// // //                           </div>
// // //                         </div>
// // //                       )}
// // //                     </div>
// // //                   </div>
// // //                 </div>
// // //               </div>
// // //             </div>
// // //           </div>
// // //           </div>
// // //         </section>
// // //         <section className="related-products py-5">
// // //           <div className="container">
// // //             <h2 className="mb-4">You Might Also Like</h2>
// // //             <div className="row">
// // //               {suggestedProducts.map((product) => (
// // //                 <div key={product.pkProdId} className="col-md-4 mb-4">
// // //                   <div className="card">
// // //                     <img
// // //                       src={product.imageUrl}
// // //                       className="card-img-top"
// // //                       alt={product.prodName}
// // //                     />
// // //                     <div className="card-body">
// // //                       <h5 className="card-title">{product.prodName}</h5>
// // //                       <p className="card-text">
// // //                         <FaIndianRupeeSign className="fs-4" />
// // //                         {product.price}
// // //                       </p>
// // //                       <a href={`/product/${product.pkProdId}`} className="btn btn-primary">
// // //                         View Details
// // //                       </a>
// // //                     </div>
// // //                   </div>
// // //                 </div>
// // //               ))}
// // //             </div>
// // //           </div>
// // //         </section>
// // //       </>
// // //   );
// // // };

// // // export default SingleProduct;
