import React from "react";
import { Link, NavLink } from "react-router-dom";
import { FaFacebook, FaSquareInstagram, FaYoutube } from "react-icons/fa6";
import { footerNavLinks } from "../../data";

const Footer = () => {
  return (
    <>
      <footer className="py-3">
        <div className="our-container">
          <div className="inner-container mx-auto px-5">
            <div className="pb-1 footer-nav-links">
              <div className="mb-3 d-flex align-items-center justify-content-center gap-4 fn-link">
                {footerNavLinks.map((item, index) => {
                  return (
                    <div key={index}>
                      <NavLink to={item.webUrl} className="fs-6 fn-item" key={index}>
                        {item.title}
                      </NavLink>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="pt-2 main-footer">
              <div className="d-flex align-items-center justify-content-center my-4 footer-logo">
                <Link to={"/"} className="d-block">
                  <img src="/images/karv-logo.png" alt="" />
                </Link>
              </div>
              <div className="footer-links">
                <p className="mb-0">
                  ©{new Date().getFullYear()} karvaccessories. All Rights Reserved
                </p>
                <div className="term-condition">
                  <Link to={"/terms-and-conditions"} className="tc-link">
                    Terms & Conditions
                  </Link>
                  <Link
                    to={"/privacy-policy"}
                    className="tc-link tc-link-border"
                  >
                    Privacy Policy
                  </Link>
                  <Link
                    to={"/faq"}
                    className="faq-link faq-link-border"
                  >
                    FAQ's
                  </Link>
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-center gap-3 footer-social-links">
                <Link
                  to={"https://www.facebook.com/people/Karv-Accessories/61556072433937/"}
                  target="_blank"
                  className="fs-link"
                >
                  <FaFacebook className="fs-6 d-flex align-items-center justify-content-center fs-link-item" />
                </Link>
                <Link
                  to={"https://www.instagram.com/karvaccessories.official/"}
                  target="_blank"
                  className="fs-link"
                >
                  <FaSquareInstagram className="fs-6 d-flex align-items-center justify-content-center fs-link-item" />
                </Link>
                <Link
                  to={"https://www.youtube.com/@karvaccessories.official"}
                  target="_blank"
                  className="fs-link"
                >
                  <FaYoutube className="fs-6 d-flex align-items-center justify-content-center fs-link-item" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
