import React, { useContext, useEffect, useState } from "react";
import Breadcrumb from "../../components/Breadcrumb";
import Product from "../../components/Product";
import Loading from "../../components/Loading";
import { PersonalizedContext } from "../../contexts/PersonalizedContext";
import axios from "axios";
import MetaDescTag from "../../components/MetaDescTag";

const Personalized = () => {
  const {
    allProducts,
    loading,
    currentPage,
    setCurrentPage,
    postsPerPage,
    totalPages,
    selectedFilters,
    setSelectedFilters,
    selectedType, // Use this
    setSelectedType, // Use this
  } = useContext(PersonalizedContext);

  const [filterType, setFilterType] = useState([]);
  const [loadingFilters, setLoadingFilters] = useState(true);

  // Fetch filter categories from the API
  const fetchFilterType = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/product/productCategoryList`,
        { prodType: selectedType } // Use selectedType
      );
      if (response.data && response.data.option) {
        setFilterType(response.data.option);
      }
      setLoadingFilters(false);
    } catch (error) {
      console.error("Error fetching filter categories:", error);
      setLoadingFilters(false);
    }
  };

  // Reset the current page and fetch filter types when the component mounts or selectedType changes
  useEffect(() => {
    setCurrentPage(1);
    fetchFilterType();
  }, [setCurrentPage, selectedType]);

  // Handle filter change
  const handleFilterChange = (e) => {
    const { value, checked } = e.target;
    setSelectedFilters((prev) =>
      checked ? [...prev, value] : prev.filter((filter) => filter !== value)
    );
  };

  // Handle page change
  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  return (
    <>
      <MetaDescTag
        title={"Products | Jewelry Boxes | KARV Accessories"}
        description={
          "Welcome to Karv Accessories, where passion, craftsmanship, and style unite to create exquisite leather products. Founded by the dynamic duo, Karishma and Vikram, our journey began with a shared vision to redefine leather goods and accessories."
        }
      />
      <section className="product-page">
        {/* <Breadcrumb title="Product" /> */}
        <div className="py-5 product-page-block">
          <div className="our-container">
            <div className="inner-container px-5 mx-auto">
              <div className="pb-5 product-page-header">
                <h1 className="fs-1 fw-medium text-capitalize mb-2">
                  Products
                </h1>
              </div>
              <div className="product-page-body">
                {loading ? (
                  <Loading />
                ) : (
                  <>
                    <div className="filter-section">
                      <p className="mb-2">Filters</p>
                      {loadingFilters ? (
                        <Loading />
                      ) : (
                        <div className="filter-checkboxes">
                          {filterType.map((item, index) => (
                            <div className="form-check" key={index}>
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value={item}
                                id={`flexCheckDefault-${index}`}
                                checked={selectedFilters.includes(item)}
                                onChange={handleFilterChange}
                              />
                              <label
                                className="form-check-label"
                                htmlFor={`flexCheckDefault-${index}`}
                              >
                                {item}
                              </label>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                    <div>
                      <div className="d-grid gap-3 product-page-row">
                        {allProducts?.map((product) => (
                          <Product key={product?.pkProdId} product={product} />
                        ))}
                      </div>
                      <div className="mt-5 product-page-pagination">
                        <button
                          onClick={() => handlePageChange(currentPage - 1)}
                          disabled={currentPage === 1}
                          className={`pagination-disable-btn ${
                            currentPage === 1 ? "prev-button-disabled" : ""
                          }`}
                        >
                          Previous
                        </button>
                        <button
                          onClick={() => handlePageChange(currentPage + 1)}
                          disabled={currentPage === totalPages}
                          className={`pagination-next-btn ${
                            currentPage === totalPages
                              ? "next-button-disabled"
                              : ""
                          }`}
                        >
                          Next
                        </button>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Personalized;
