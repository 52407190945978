import React, { useState } from "react";
import { faqData } from "../../data";
import Breadcrumb from "../../components/Breadcrumb";

const FAQs = () => {
  // State to manage the currently open FAQ item
  const [openIndex, setOpenIndex] = useState(null);

  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <section className="faq-page">
      {/* <Breadcrumb title="Faq" /> */}
      <div className="our-container py-5">
        <div className="inner-container px-5 mx-auto">
          <div className="faq-page-heading">
            <h1 className="fs-2 fw-medium text-capitalize">FAQ's</h1>
          </div>
          <div className="faq-page-faq">
            <div
              className="accordion accordion-flush faq-page-faq-ul"
              id="accordionFlushExample"
            >
              {faqData?.map((faq, index) => (
                <div className="accordion-item" key={faq.id}>
                  <h2 className="accordion-header">
                    <button
                      className={`accordion-button px-1 ${
                        openIndex === index ? "" : "collapsed"
                      }`}
                      type="button"
                      onClick={() => toggleFAQ(index)}
                    >
                      {faq.question}
                    </button>
                  </h2>
                  <div
                    className={`accordion-collapse collapse ${
                      openIndex === index ? "show" : ""
                    }`}
                  >
                    <div className="accordion-body px-1">{faq.answer}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FAQs;
