import React, { createContext, useEffect, useState } from "react";

export const PersonalizedContext = createContext();

const PersonalizedProvider = ({ children }) => {
  const [allProducts, setAllProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(
    Number(localStorage.getItem("currentPage")) || 1
  );
  const [postsPerPage, setPostsPerPage] = useState(12);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(true);
  const [selectedFilters, setSelectedFilters] = useState(
    JSON.parse(localStorage.getItem("selectedFilters")) || []
  );
  const [selectedType, setSelectedType] = useState("Personalized");

  const fetchProducts = async (pageNo, pageSize, filters, type) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/product/search`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            pkProdId: "",
            prodName: "",
            prodCategory: filters.join(",") || "",
            prodType: type || "Personalized",
            price: "",
            pageNo: pageNo,
            pageSize: pageSize,
          }),
        }
      );
      if (!response.ok) {
        throw new Error("Failed to fetch products");
      }
      const data = await response.json();
      const totalResults = data.totalResult;
      const totalPages = Math.ceil(totalResults / pageSize);

      const productsWithSizes = data?.masterData?.map((product) => ({
        ...product,
        info: product?.info?.map((item) => ({
          ...item,
        })),
      }));
      setTotalPages(totalPages);
      return productsWithSizes;
    } catch (error) {
      console.error("Error fetching products:", error);
      return [];
    }
  };

  useEffect(() => {
    const fetchAllProducts = async () => {
      setLoading(true);
      try {
        const products = await fetchProducts(currentPage, postsPerPage, selectedFilters, selectedType);
        if (products.length === 0 && currentPage > 1) {
          setCurrentPage(1);
        } else {
          setAllProducts(products);
        }
      } catch (error) {
        console.error("Error fetching products:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchAllProducts();
  }, [currentPage, postsPerPage, selectedFilters, selectedType]);

  useEffect(() => {
    localStorage.setItem("currentPage", currentPage);
  }, [currentPage]);

  useEffect(() => {
    localStorage.setItem("selectedFilters", JSON.stringify(selectedFilters));
  }, [selectedFilters]);

  const handleFilterChange = (filters) => {
    setSelectedFilters(filters);
  };

  return (
    <PersonalizedContext.Provider
      value={{
        allProducts,
        getProductDetails: (productId) => allProducts?.find((product) => product?.pkProdId === productId),
        getSuggestedProducts: (productId) => allProducts?.find((product) => product?.pkProdId === productId)?.suggestedProducts || [],
        currentPage,
        setCurrentPage,
        postsPerPage,
        totalPages,
        loading,
        setLoading,
        setPostsPerPage,
        selectedFilters,
        setSelectedFilters: handleFilterChange,
        selectedType,
        setSelectedType,
      }}
    >
      {children}
    </PersonalizedContext.Provider>
  );
};

export default PersonalizedProvider;







// import React, { createContext, useEffect, useState } from "react";

// export const PersonalizedContext = createContext();

// const PersonalizedProvider = ({ children }) => {
//   const [allProducts, setAllProducts] = useState([]);
//   const [currentPage, setCurrentPage] = useState(
//     Number(localStorage.getItem("currentPage")) || 1
//   );
//   const [postsPerPage, setPostsPerPage] = useState(12);
//   const [totalPages, setTotalPages] = useState(0);
//   const [loading, setLoading] = useState(true);
//   const [selectedFilters, setSelectedFilters] = useState(
//     JSON.parse(localStorage.getItem("selectedFilters")) || []
//   );
//   const [selectedType, setSelectedType] = useState("Personalized");

//   const fetchProducts = async (pageNo, pageSize, filters, type) => {
//     try {
//       const response = await fetch(
//         `${process.env.REACT_APP_API_URL}/product/search`,
//         {
//           method: "POST",
//           headers: {
//             "Content-Type": "application/json",
//           },
//           body: JSON.stringify({
//             pkProdId: "",
//             prodName: "",
//             prodCategory: filters.join(",") || "",
//             prodType: type || "Personalized",
//             price: "",
//             pageNo: pageNo,
//             pageSize: pageSize,
//           }),
//         }
//       );
//       if (!response.ok) {
//         throw new Error("Failed to fetch products");
//       }
//       const data = await response.json();
//       const totalResults = data.totalResult;
//       const totalPages = Math.ceil(totalResults / pageSize);

//       const productsWithSizes = data?.masterData?.map((product) => ({
//         ...product,
//         info: product?.info?.map((item) => ({
//           ...item,
//         })),
//       }));
//       setTotalPages(totalPages);
//       return productsWithSizes;
//     } catch (error) {
//       console.error("Error fetching products:", error);
//       return [];
//     }
//   };

//   useEffect(() => {
//     const fetchAllProducts = async () => {
//       setLoading(true);
//       try {
//         const products = await fetchProducts(
//           currentPage,
//           postsPerPage,
//           selectedFilters,
//           selectedType
//         );
//         if (products.length === 0 && currentPage > 1) {
//           setCurrentPage(1);
//         } else {
//           setAllProducts(products);
//         }
//       } catch (error) {
//         console.error("Error fetching products:", error);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchAllProducts();
//   }, [currentPage, postsPerPage, selectedFilters, selectedType]);

//   useEffect(() => {
//     localStorage.setItem("currentPage", currentPage);
//   }, [currentPage]);

//   useEffect(() => {
//     localStorage.setItem("selectedFilters", JSON.stringify(selectedFilters));
//   }, [selectedFilters]);

//   const handleFilterChange = (filters) => {
//     setSelectedFilters(filters);
//   };

//   // New API Call to Fetch Product Details
//   const getProductDetails = async (productId) => {
//     try {
//       const response = await fetch(
//         `${process.env.REACT_APP_API_URL}/product/${productId}`
//       );
//       if (!response.ok) {
//         throw new Error("Failed to fetch product details");
//       }
//       const productDetails = await response.json();
//       return productDetails;
//     } catch (error) {
//       console.error("Error fetching product details:", error);
//       return null;
//     }
//   };

//   // New API Call to Fetch Suggested Products
//   const getSuggestedProducts = async (productId) => {
//     try {
//       const response = await fetch(
//         `${process.env.REACT_APP_API_URL}/product/${productId}/suggestions`
//       );
//       if (!response.ok) {
//         throw new Error("Failed to fetch suggested products");
//       }
//       const suggestedProducts = await response.json();
//       return suggestedProducts;
//     } catch (error) {
//       console.error("Error fetching suggested products:", error);
//       return [];
//     }
//   };

//   return (
//     <PersonalizedContext.Provider
//       value={{
//         allProducts,
//         getProductDetails, // Updated to call API
//         getSuggestedProducts, // Updated to call API
//         currentPage,
//         setCurrentPage,
//         postsPerPage,
//         totalPages,
//         loading,
//         setLoading,
//         setPostsPerPage,
//         selectedFilters,
//         setSelectedFilters: handleFilterChange,
//         selectedType,
//         setSelectedType,
//       }}
//     >
//       {children}
//     </PersonalizedContext.Provider>
//   );
// };

// export default PersonalizedProvider;



// // import React, { createContext, useEffect, useState } from "react";

// // export const PersonalizedContext = createContext();

// // const PersonalizedProvider = ({ children }) => {
// //   const [allProducts, setAllProducts] = useState([]);
// //   const [currentPage, setCurrentPage] = useState(
// //     Number(localStorage.getItem("currentPage")) || 1
// //   );
// //   const [postsPerPage, setPostsPerPage] = useState(12);
// //   const [totalPages, setTotalPages] = useState(0);
// //   const [loading, setLoading] = useState(true);
// //   const [selectedFilters, setSelectedFilters] = useState(
// //     JSON.parse(localStorage.getItem("selectedFilters")) || []
// //   );
// //   const [selectedType, setSelectedType] = useState("Personalized"); // Add this line

// //   // Fetch products based on page and filters
// //   const fetchProducts = async (pageNo, pageSize, filters, type) => {
// //     try {
// //       const response = await fetch(
// //         `${process.env.REACT_APP_API_URL}/product/search`,
// //         {
// //           method: "POST",
// //           headers: {
// //             "Content-Type": "application/json",
// //           },
// //           body: JSON.stringify({
// //             pkProdId: "",
// //             prodName: "",
// //             prodCategory: filters.join(",") || "",
// //             prodType: type || "Personalized", // Use type from parameters
// //             price: "",
// //             pageNo: pageNo,
// //             pageSize: pageSize,
// //           }),
// //         }
// //       );
// //       if (!response.ok) {
// //         throw new Error("Failed to fetch products");
// //       }
// //       const data = await response.json();
// //       const totalResults = data.totalResult;
// //       const totalPages = Math.ceil(totalResults / pageSize);

// //       const productsWithSizes = data?.masterData?.map((product) => ({
// //         ...product,
// //         info: product?.info?.map((item) => ({
// //           ...item,
// //         })),
// //       }));
// //       setTotalPages(totalPages);

// //       return productsWithSizes;
// //     } catch (error) {
// //       console.error("Error fetching products:", error);
// //       return [];
// //     }
// //   };

// //   // Fetch all products when page, filters, or type change
// //   useEffect(() => {
// //     const fetchAllProducts = async () => {
// //       setLoading(true);
// //       try {
// //         const products = await fetchProducts(currentPage, postsPerPage, selectedFilters, selectedType);

// //         if (products.length === 0 && currentPage > 1) {
// //           setCurrentPage(1); // Reset to the first page if no products found
// //         } else {
// //           setAllProducts(products);
// //         }
// //       } catch (error) {
// //         console.error("Error fetching products:", error);
// //       } finally {
// //         setLoading(false);
// //       }
// //     };

// //     fetchAllProducts();
// //   }, [currentPage, postsPerPage, selectedFilters, selectedType]);

// //   // Persist state changes to localStorage
// //   useEffect(() => {
// //     localStorage.setItem("currentPage", currentPage);
// //   }, [currentPage]);

// //   useEffect(() => {
// //     localStorage.setItem("selectedFilters", JSON.stringify(selectedFilters));
// //   }, [selectedFilters]);

// //   const handleFilterChange = (filters) => {
// //     setSelectedFilters(filters);
// //   };

// //   const getProductDetails = (productId) => {
// //     return allProducts?.find((product) => product?.pkProdId === productId);
// //   };

// //   const getSuggestedProducts = (productId) => {
// //     const product = getProductDetails(productId);
// //     return product ? product.suggestedProducts || [] : [];
// //   };

// //   return (
// //     <PersonalizedContext.Provider
// //       value={{
// //         allProducts,
// //         getProductDetails,
// //         getSuggestedProducts,
// //         currentPage,
// //         setCurrentPage,
// //         postsPerPage,
// //         totalPages,
// //         loading,
// //         setLoading,
// //         setPostsPerPage,
// //         selectedFilters,
// //         setSelectedFilters: handleFilterChange,
// //         selectedType, // Add this line
// //         setSelectedType, // Add this line
// //       }}
// //     >
// //       {children}
// //     </PersonalizedContext.Provider>
// //   );
// // };

// // export default PersonalizedProvider;