import React, { createContext, useState, useEffect } from "react";
import { toast } from "react-toastify";

export const CartContext = createContext();

const CartProvider = ({ children }) => {
  const [cartItems, setCartItems] = useState(() => {
    const storedCartItems = localStorage.getItem("cartItems");
    return storedCartItems ? JSON.parse(storedCartItems) : [];
  });

  useEffect(() => {
    localStorage.setItem("cartItems", JSON.stringify(cartItems));
  }, [cartItems]);

  const addToCart = (
    product,
    selectedColor,
    quantity = 1,
    customization = {}
  ) => {
    // Check if the product with the same customization already exists in the cart
    // Create a unique identifier for the item based on product ID, color, and customization

    const itemIdentifier = `${product.pkProdId}-${
      selectedColor.imageColor
    }-${JSON.stringify(customization)}`;

    const existingItemIndex = cartItems.findIndex(
      (item) => item.id === itemIdentifier
    );

    if (existingItemIndex !== -1) {
      // If the product is already in the cart with the same customization, update the quantity
      const updatedCartItems = [...cartItems];
      updatedCartItems[existingItemIndex] = {
        ...updatedCartItems[existingItemIndex],
        quantity: updatedCartItems[existingItemIndex].quantity + quantity,
      };
      setCartItems(updatedCartItems);
    } else {
      // If the product is not in the cart, add it as a new item
      const newItem = {
        ...product,
        selectedColor,
        quantity,
        customization,
        id: itemIdentifier,
      };
      setCartItems((prevItems) => [...prevItems, newItem]);
    }
  };

  const removeFromCart = (productId, selectedColor, customization) => {
    setCartItems((prevItems) => {
      const updatedCart = prevItems.filter(
        (item) =>
          !(
            item.pkProdId === productId &&
            item.selectedColor.imageColor === selectedColor.imageColor &&
            JSON.stringify(item.customization) === JSON.stringify(customization)
          )
      );
      localStorage.setItem("cartItems", JSON.stringify(updatedCart));
      return updatedCart;
    });
    toast.success("Delete Cart Successfully!",{
      autoClose: 1000,
      position: 'bottom-right'
    });
  };

  const clearCart = () => {
    setCartItems([]);
    localStorage.removeItem("cartItems");
    toast.success("Clear Cart Successfully!",{
      autoClose: 1000,
      position: 'bottom-right'
    });
  };

  const getTotalPrice = () => {
    let totalPrice = 0;
    cartItems.forEach((item) => {
      totalPrice += item.prodPrice * item.quantity;
    });
    return totalPrice;
  };

  return (
    <CartContext.Provider
      value={{
        cartItems,
        setCartItems,
        addToCart,
        removeFromCart,
        clearCart,
        getTotalPrice,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

export default CartProvider;
